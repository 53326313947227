import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LandingPage from "../../pages/landingPage/landingPage";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import QuestionResponse from "../../redux/models/questionResponse/questionResponse";
import ListingView from "../common/listingsView";
import ResponseCard from "./responseCard";

function ResponseGrid({
  questionId,
  isLandingPage = false,
  listingViewProps = {},
  responseVideoIds,
  filterFlag,
  searchLoading,
  wordSearchValue = "",
  sentimentSearchValue = "",
}) {
  const dispatch = useDispatch();
  const intervalRef = useRef(null);
  const isResponsePresent = (videoUrl) => {
    let videoName = videoUrl?.split(".")[0];

    // return responseVideoIds?.length ? responseVideoIds.includes(videoName) : true;
    return filterFlag ? responseVideoIds?.includes(videoName) : true;
  };

  var questionResponses = useSelector((state) => {
    return getSelector(QuestionResponse)(state);
  });

  var responsesList = questionResponses
    .filter((el) => el.questionId == questionId && isResponsePresent(el.response.videoUrl))
    .map((el) => el.id);
  // if (sentimentSearchValue2) {
  //   responsesList = questionResponses
  //     .filter(
  //       (el) =>
  //         el.questionId == questionId &&
  //         el.response.sentimentAnalysis === sentimentSearchValue2 &&
  //         isResponsePresent(el.response.videoUrl)
  //     )
  //     .map((el) => el.id);
  // } else {
  //   responsesList = questionResponses
  //     .filter((el) => el.questionId == questionId && isResponsePresent(el.response.videoUrl))
  //     .map((el) => el.id);
  // }
  const [responseState, setResponseState] = useState({});
  const [listLoading, setListLoading] = useState(false);
  const questionsResponsesListingState = useSelector((state) =>
    Object.keys(state.listings[QuestionResponse.listingSliceName])
  );

  const responsesSlice = useSelector((state) => state.listings[QuestionResponse.listingSliceName][questionId] || {});

  async function fetchData() {
    if (questionId /*&& !questionsResponsesListingState.includes(questionId)*/) {
      setListLoading(true);
      await dispatch(QuestionResponse.createSliceState(questionId));
      await dispatch(QuestionResponse.loadMore(questionId, isLandingPage, wordSearchValue, sentimentSearchValue));
      setListLoading(false);
      if (isLandingPage) {
        // polling the server after 1 minute to get the newly added videos
        intervalRef.current = setInterval(async () => {
          setListLoading(true);
          await dispatch(QuestionResponse.createSliceState(questionId));
          await dispatch(QuestionResponse.loadMore(questionId, isLandingPage, wordSearchValue, sentimentSearchValue));
          setListLoading(false);
        }, 1 * 60 * 1000);
      }
    }
  }
  useEffect(() => {
    fetchData();
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [questionId, wordSearchValue, sentimentSearchValue]);

  useEffect(() => {
    if (responsesList.length !== responseState.length) {
      setResponseState(responsesList);
    }
  }, [responsesList?.length]);

  const loadMore = () => dispatch(QuestionResponse.loadMore(questionId, isLandingPage));

  return (
    <div>
      <ListingView
        dataSource={responseState}
        GridCard={({ record }) => <ResponseCard showActions id={record} isLandingPage={isLandingPage} />}
        isGrid={true}
        loadMore={loadMore}
        // hasMore={responsesSlice.hasMore}
        initialLoading={listLoading || searchLoading}
        loadingMore={responsesSlice.loadingMore}
        noDataProps={{
          description: "No responses yet",
        }}
        colSpan={{
          xs: 24,
          md: 12,
          xl: 8,
        }}
        {...listingViewProps}
      />
    </div>
  );
}

export default ResponseGrid;
