import { Radio } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import QuestionResponse from "../../redux/models/questionResponse/questionResponse";
import { setQuestionResponseListingState } from "../../redux/slice/listings";
import RoomDownloadSelected from "./roomDownloadSelected";

function QuestionResponseFilters({ questionId }) {
  const dispatch = useDispatch();
  const publishedValue = useSelector(
    (state) => state.listings[QuestionResponse.listingSliceName][questionId]?.isAccepted
  );
  const reloadListing = ({ target }) => {
    dispatch(QuestionResponse.clearData(questionId));
    dispatch(
      setQuestionResponseListingState({
        listing: questionId,
        value: {
          isAccepted: target.value,
        },
      })
    );
    dispatch(QuestionResponse.loadMore(questionId));
  };

  return (
    <>
      <div className="question-videos-filter">
        <div className="qvf-fields">
          <Radio.Group onChange={reloadListing} value={publishedValue}>
            <Radio.Button value={undefined}>All</Radio.Button>
            <Radio.Button value={true}>Accepted</Radio.Button>
            <Radio.Button value={false}>Rejected</Radio.Button>
          </Radio.Group>
        </div>

        {/* <Switch onChange={roomStatus} defaultChecked={false} /> */}

        <div className="qvf-download-btn">
          <RoomDownloadSelected questionId={questionId} />
        </div>
      </div>
    </>
  );
}

export default QuestionResponseFilters;
