import { message } from "antd";
import _ from "lodash";
import NetworkCall from "../../../network/networkCall";
import Request from "../../../network/request";
import {
  createNewQuestionResponseSlice,
  resetPaginationStateQuestionResponse,
  setQuestionResponseListingState,
} from "../../slice/listings";
import { deleteAllAndUpsertModels, deleteModels, upsertModel, upsertModels } from "../baseModel/baseActions";
import BaseModel, { getSelector } from "../baseModel/baseModel";
import baseReducer from "../baseModel/baseReducer";

export default class QuestionResponse extends BaseModel {
  static clearFilters = (questionId) => {
    return this.createSliceState(questionId);
  };

  static createSliceState = (questionId) => {
    return (dispatch) => {
      dispatch(
        createNewQuestionResponseSlice({
          questionId: questionId,
        })
      );
    };
  };

  static clearData = (id) => {
    return (dispatch, getState) => {
      dispatch(resetPaginationStateQuestionResponse(id));
      dispatch(
        deleteModels(
          QuestionResponse,
          getSelector(QuestionResponse)(getState())
            .filter((el) => el.questionId == id)
            .map((el) => el.id)
        )
      );
    };
  };

  static loadMore = (questionId, isLandingPage = false, wordSearchValue = "", sentimentSearchValue = "") => {
    return async (dispatch, getState) => {
      try {
        let sliceState = getState().listings[this.listingSliceName][questionId];
        let newPageNumber = sliceState.pageNumber + 1;
        dispatch(
          setQuestionResponseListingState({
            listing: questionId,
            value: {
              loadingMore: true,
              pageNumber: newPageNumber,
            },
          })
        );
        const questionsResponse = await NetworkCall.fetch(
          Request.getQuestionsResponse(
            {
              questionId: questionId,
              wordSearchValue: wordSearchValue,
              sentimentSearchValue: sentimentSearchValue,
              // pageNumber: newPageNumber,
              // recordsPerPage: sliceState.pageSize,
              ...(sliceState.isAccepted != undefined && { isAccepted: sliceState.isAccepted }),
            },
            isLandingPage
          )
        );

        dispatch(deleteAllAndUpsertModels(QuestionResponse, questionsResponse));
        dispatch(
          setQuestionResponseListingState({
            listing: questionId,
            value: {
              initialLoading: false,
              loadingMore: false,
              hasMore: questionsResponse.length == sliceState.pageSize,
            },
          })
        );
      } catch (e) {
        console.error(e);
      }
    };
  };

  static acceptResponse = (id) => {
    return async (dispatch, getState) => {
      try {
        let res = await NetworkCall.fetch(Request.changeStatus(id, true));
        let model = _.cloneDeep(getSelector(QuestionResponse)(getState()).filter((el) => el.responseId == id)[0]);
        model.response.isAccepted = true;
        dispatch(upsertModel(QuestionResponse, model));
        message.success("Changed status successfully.");
      } catch (e) {
        console.error(e);
        message.error("Failed to change status");
      }
      return;
    };
  };

  static rejectResponse = (id) => {
    return async (dispatch, getState) => {
      try {
        let res = await NetworkCall.fetch(Request.changeStatus(id, false));
        let model = _.cloneDeep(getSelector(QuestionResponse)(getState()).filter((el) => el.responseId == id)[0]);
        model.response.isAccepted = false;
        dispatch(upsertModel(QuestionResponse, model));
        message.success("Changed status successfully.");
      } catch (e) {
        console.error(e);
        message.error("Failed to change status");
      }
      return;
    };
  };

  // Reducer
  static reducer(action, QuestionResponse, session) {
    baseReducer(action, QuestionResponse, session);
  }
}

QuestionResponse.modelName = "questionResponse";

QuestionResponse.listingSliceName = "questionResponse";
