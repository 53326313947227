import { UserOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import footerImage from "../../assets/images/m-footer-logo.png";
import User from "../../redux/models/user/user";
import K from "../../utilities/constants";
import { isRolePresent } from "../../utilities/generalUtility";
//import styles from "./layout.module.scss";
import navigations from "./navigations";

export default function Sider({ collapsed }) {
  // get current user's role

  const orgLogo = User.getOrganizationLogoUrl();

  const { Sider } = Layout;
  const { SubMenu } = Menu;
  const location = useLocation();

  return (
    <Sider trigger={null} collapsible collapsed={collapsed} className="sidebar-main">
      <div className="ant-layout-sider-logo">
        <img
          src={orgLogo ? `${K.Network.URL.BaseAPI}/organization/uploads/logo/${orgLogo}` : "/images/logo.svg"}
          alt="/images/logo.svg"
          style={{
            width: "100%",
            maxHeight: 150,
            objectFit: "contain",
          }}
        />
      </div>
      <Menu
        defaultSelectedKeys={[
          navigations.map((item) => item.path).find((item) => matchPath(`${location.pathname}`, item)?.isExact),
        ]}
        mode="inline"
      >
        {navigations.map((navigation, i) => {
          const hasRole = isRolePresent(navigation.roles, User.roles());
          if (!hasRole) {
            return null;
          }

          if ((navigation.children?.length ?? 0) > 0) {
            return (
              <SubMenu key={i} icon={<UserOutlined />} title={navigation.name}>
                {navigation.children.map((subNavigation, j) => {
                  const navHasRole = isRolePresent(subNavigation.roles, User.roles());
                  return navHasRole ? (
                    <Menu.Item key={subNavigation.path}>
                      <Link to={subNavigation.path}>{subNavigation.name}</Link>
                    </Menu.Item>
                  ) : null;
                })}
              </SubMenu>
            );
          } else {
            return (
              // TODO: change this to onClick
              <Menu.Item key={navigation.path} icon={navigation.icon}>
                <Link to={navigation.path}>{navigation.name}</Link>
              </Menu.Item>
            );
          }
        })}
      </Menu>
      {!collapsed && (
        <div className="sider-footer-logo">
          <img src={footerImage} alt="Powered by Megilla" width={120} />
        </div>
      )}
    </Sider>
  );
}
