import { CalendarOutlined, DownloadOutlined, PlayCircleOutlined, BellOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAttribute } from "../../redux/models/baseModel/baseActions";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import VideoArchive from "../../redux/models/videoArchive/videoArchive";
import { setVideoDetails } from "../../redux/slice/videoPopup";
import { parseSecond } from "../../utilities/dateUtility";
import { getActualPath } from "../common/formItems/CustomImageUpload";

const VideoCard = ({ record: id }) => {
  const record = useSelector((state) => getSelector(VideoArchive)(state).find((el) => el.id === id) || {});
  const dispatch = useDispatch();

  const videoThumbnail = getActualPath("/responses/get-video-metadata/" + record.response.videoUrl);
  const videoSubtitle = getActualPath(
    "/responses/get-video-metadata/" + record?.response.videoUrl.split(".")[0] + ".vtt?isSubtitle=true"
  );
  const videoDownloadLink = getActualPath("/responses/single-video?fileName=" + record?.response.videoUrl);
  const respondantName = record.respondents.name;
  const openDetails = () => {
    dispatch(setVideoDetails({ ...record, videoSubtitle }));
  };

  let createdAt = record?.response?.createdAt;
  createdAt = createdAt ? moment(createdAt).format("MM-DD-YYYY") : "Null";

  let videoLength = record?.response?.videoLength;
  videoLength = videoLength ? parseSecond(+videoLength, true) : "Null";

  const onCheck = (e) => {
    dispatch(setAttribute(VideoArchive, id, "isSelected", e.target.checked));
  };
  function getColorBasedOnSentiment(sentiment) {
    switch (sentiment) {
      case "POSITIVE":
        return "green";
      case "NEGATIVE":
        return "red";
      case "NEUTRAL":
        return "default";
      // Add more cases as needed
      default:
        return "orange"; // Default color or handle unknown sentiment
    }
  }

  return (
    <Card
      className="video-card"
      cover={
        <>
          <div className="thumbnail-image">
            <img alt="" src={videoThumbnail} />
          </div>
          <Checkbox className="checkbox-icon" checked={record.isSelected} onChange={onCheck} />
          <PlayCircleOutlined className="play-icon" onClick={openDetails} />
          <div className="video-time-length">{videoLength}</div>
        </>
      }
    >
      <div className="video-downoled-btn">
        <Button
          icon={<DownloadOutlined />}
          type="link"
          // onClick={() =>
          //   VideoArchive.downloadVideo(videoDownloadLink)
          //     .then((res) => {
          //       programaticDownload(res);
          //     })
          //     .catch((err) => {
          //       console.error(err);
          //       message.error("Failed to download video.");
          //     })
          // }
          href={videoDownloadLink}
        />
      </div>
      <div className="card-heading-wrap">
        <h3>{respondantName}</h3>
        {record?.response?.sentimentAnalysis && (
          <Tag color={getColorBasedOnSentiment(record?.response?.sentimentAnalysis)}>
            {record?.response?.sentimentAnalysis}
          </Tag>
        )}
      </div>
      <h5>
        <CalendarOutlined className="mr-2 clander-icon" /> {createdAt}
      </h5>
    </Card>
  );
};

export default VideoCard;
