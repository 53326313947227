import { attr } from "redux-orm";
import NetworkCall from "../../../network/networkCall";
import Request from "../../../network/request";
import { upsertModels } from "../baseModel/baseActions";
import BaseModel from "../baseModel/baseModel";
import baseReducer from "../baseModel/baseReducer";

export default class QuestionsAuthor extends BaseModel {
  static getQuestionsAuthor() {
    return async (dispatch) => {
      try {
        const questionsAuthor = await NetworkCall.fetch(Request.questionsAuthor());
        dispatch(upsertModels(QuestionsAuthor, questionsAuthor));
        return questionsAuthor;
      } catch (e) {
        console.error(e);
        return false;
      }
    };
  }
  // Reducer
  static reducer(action, QuestionsAuthor, session) {
    baseReducer(action, QuestionsAuthor, session);
  }
}

QuestionsAuthor.modelName = "QuestionsAuthor";

QuestionsAuthor.fields = {
  // Attributes
  id: attr(),
  name: attr(),
};
