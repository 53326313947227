import { Badge, Button, message } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import QuestionResponse from "../../redux/models/questionResponse/questionResponse";
import VideoArchive from "../../redux/models/videoArchive/videoArchive";
import { programaticDownload, programaticDownloadFile } from "../../utilities/generalUtility";
import { getActualPath } from "../common/formItems/CustomImageUpload";

const RoomDownloadSelected = ({ questionId }) => {
  const urlForDownload = useSelector((state) =>
    getSelector(QuestionResponse)(state)
      .filter((el) => el.isSelected && questionId == el.questionId)
      .map((el) => el.response.videoUrl)
  );
  const videoDownloadLink = getActualPath("/responses/get-archive?fileNames=" + JSON.stringify(urlForDownload));

  const onDownload = () => {
    if (urlForDownload.length < 1) {
      message.error("Select videos before downloading!");
    } else {
      VideoArchive.downloadVideo(videoDownloadLink)
        .then((res) => {
          programaticDownloadFile(res);
        })
        .catch((err) => {
          console.error(err);
          message.error("Failed to download video.");
        });
    }
  };
  const handleClick = () => {
    if (urlForDownload.length < 1) {
      message.error("Select videos before downloading!");
    } else {
      window.location.href = videoDownloadLink;
    }
  };

  return (
    <Badge count={urlForDownload.length}>
      <Button
        type="primary"
        ghost
        onClick={handleClick}
        // href={urlForDownload.length > 0 ? videoDownloadLink : undefined}
      >
        Download Selected
      </Button>
    </Badge>
  );
};

export default RoomDownloadSelected;
