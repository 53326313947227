import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import QuestionResponse from "../../redux/models/questionResponse/questionResponse";
import { Button, Carousel } from "antd";
import { getActualPathPublic } from "../common/formItems/CustomImageUpload";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import NoDataFound from "../common/noDataFound";
import { usePromiseTracker } from "react-promise-tracker";
import ReactVideoPlayer from "./reactVideoPlayer";
import LoadingSpinner from "./loadingSpinner";

function SlidesView({questionId}) {
  const playerRef = useRef();
  const { promiseInProgress } = usePromiseTracker();

  const responses = useSelector((state) =>
    getSelector(QuestionResponse)(state)
      .filter((el) => el.questionId == questionId)
  );

  if (promiseInProgress) {
    return <LoadingSpinner />
  }

  return (
    <div className="carousal-wrapper">
      {responses.length ?
        <>
          <Carousel dots={true} dotPosition="bottom" ref={playerRef}>
            {responses.map((record) => {
              const videoSrc = getActualPathPublic("/responses/get-video/" + record?.response.videoUrl);
              const videoSubtitle = getActualPathPublic(
                "/responses/get-video-metadata/" + record?.response.videoUrl.split(".")[0] + ".vtt?isSubtitle=true"
              );
              return (
                <>
                  <ReactVideoPlayer record={record} videoSrc={videoSrc} videoSubtitle={videoSubtitle}/>
                </>
              );
            })}
          </Carousel>
          <Button
            onClick={() => {
              playerRef.current.prev();
            }}
            className="previous-btn"
            icon={<LeftOutlined />}
          ></Button>
          <Button
            onClick={() => {
              playerRef.current.next();
            }}
            className="forward-btn"
            icon={<RightOutlined />}
          ></Button>
        </>
        : <NoDataFound description={"No responses yet."}/>}
    </div>
  );
}

export default SlidesView;
