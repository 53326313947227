// import { ExclamationCircleOutlined, PlaySquareOutlined, PauseOutlined, NotificationFilled, DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
// import { Button, Modal } from "antd";
// import React, { useRef, useState } from "react";
// import RecordRTC, { getSeekableBlob } from "recordrtc";
// import VolumeIcon from '../../assets/images/volume.png';

// const { confirm } = Modal;
import React, { memo, useEffect } from "react";
import VideojsRecordPlayer from "./recorder/videoJsRecordPlayer";

const RecordView = ({ recordingDuration = 6000, onChange = () => {}, portrait }) => {
  // useEffect(() => {
  //   console.log(data);
  // }, [data]);
// const RecordView = ({ recordingDuration = 6000, onChange = () => {} }) => {
  return (
    <VideojsRecordPlayer
      recordingDuration={recordingDuration}
      onFinish={(data) => {
        onChange(data);
      }}
      portrait={portrait}
    />
  );
};

export default memo(RecordView, (prevProps, nextProps) => prevProps.recordingDuration === nextProps.recordingDuration);

/* import {
  ExclamationCircleOutlined,
  PlaySquareOutlined,
  PauseOutlined,
  NotificationFilled,
  DownloadOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useRef, useState } from "react";
import RecordRTC, { getSeekableBlob } from "recordrtc";
import VolumeIcon from "../../assets/images/volume.png";

const { confirm } = Modal;

const RecordView = ({ recordingDuration = 6000, onChange = () => {}, value }) => {
  const videoRef = useRef();
  const recorder = useRef();
  const [isRecording, setIsRecording] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const captureCamera = (callback) => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (camera) {
        callback(camera);
      })
      .catch(function (error) {
        alert("Unable to capture your camera. Please check console logs.");
        console.error(error);
      });
  };

  const showStream = () => {
    captureCamera((stream) => {
      if (videoRef.current?.srcObject !== stream) {
        videoRef.current.src = null;
        videoRef.current.srcObject = stream;
        videoRef.current.muted = false;
        videoRef.current.volume = 0;
        videoRef.current.autoplay = true;
        videoRef.current.controls = false;
      }
    });
  };

  const showRecordedVideo = (url) => {
    videoRef.current.src = videoRef.current.srcObject = null;
    videoRef.current.muted = false;
    videoRef.current.volume = 1;
    videoRef.current.src = url;
    videoRef.current.controls = true;
    videoRef.current.autoplay = false;
  };

  const stopRecordingCallback = () => {
    getSeekableBlob(recorder.current.getBlob(), function (seekableBlob) {
      showRecordedVideo(URL.createObjectURL(seekableBlob));
      let fileObject = new File([seekableBlob], "file.webm", {
        type: "video/webM",
      });
      onChange(fileObject);
      recorder.current.camera.stop();
      recorder.current.destroy();
      recorder.current = null;
      setIsRecording(false);
      setIsPaused(false);
    });
  };

  const startRecording = () => {
    setIsRecording(true);
    showStream();
    captureCamera(function (stream) {
      recorder.current = RecordRTC(stream, {
        type: "video/webM",
        timeSlice: 1000,
        onTimeStamp: (timestamp, timestamps) => {
          let duration = (new Date().getTime() - timestamps[0]) / 1000;
          setTimer(duration.toFixed(0));
        },
        mandatory: {
          maxHeight: 720,
          maxWidth: 1280,
        },
        optional: [{ minWidth: 320 }, { minWidth: 640 }, { minWidth: 960 }, { minWidth: 1024 }, { minWidth: 1280 }],
      });
      recorder.current.camera = stream;
      recorder.current.setRecordingDuration(recordingDuration).onRecordingStopped(stopRecordingCallback);
      recorder.current.startRecording();
    });
  };

  const stopRecording = () => {
    recorder.current.stopRecording(stopRecordingCallback);
  };

  const pauseRecording = () => {
    setIsPaused(true);
    recorder.current.pauseRecording();
  };

  const resumeRecording = () => {
    setIsPaused(false);
    recorder.current.resumeRecording();
  };

  function showDeleteConfirm() {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "Previous recording will be deleted.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      centered: true,
      onOk() {
        startRecording();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  return (
    <>
      {isRecording ? (
        <>
          <div className="video-controls">
            {isPaused ? (
              <Button onClick={resumeRecording}>Resume</Button>
            ) : (
              <Button onClick={pauseRecording}>Pause</Button>
            )}
            <Button onClick={stopRecording}>Stop</Button>
          </div>
        </>
      ) : videoRef.current?.src ? (
        <Button onClick={showDeleteConfirm} className="video-rerecord-btn">
          Rerecord
        </Button>
      ) : (
        <div className="start-recording" onClick={startRecording}>
          {/* <Button onClick={startRecording}>Start</Button> * /}
          {/* <CaretRightOutlined className="video-start-icon"/> * /}
          <PlaySquareOutlined className="video-start-icon" />
          <p>Record Video</p>
          <div className="recording-icon"></div>

          <div className="video-controls-wrap">
            <div className="video-progress-bar-and-icon">
              <div className="pause-and-play-icon">
                <PauseOutlined />
              </div>
              <div className="video-progress-bar">
                <span style={{ width: "10%" }}></span>
              </div>
              <div className="volume-icon">
                <img src={VolumeIcon} alt="" />
              </div>
            </div>
            <div className="vc-btn-wrap">
              <Button className="vc-btn" shape="round" icon={<ReloadOutlined />}>
                Start Over
              </Button>
              <Button className="vc-btn" shape="round" icon={<DownloadOutlined />}>
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
      <video className={isRecording || videoRef.current?.src ? "d-block video-frame" : " d-none"} ref={videoRef} />
    </>
  );
};

export default RecordView;
 */
