const K = {
  Google: {
    GA_TRACKING_ID: process.env.GA_TRACKING_ID,
  },
  Network: {
    URL: {
      Base: process.env.REACT_APP_BASE_URL,
      BaseAPI: process.env.REACT_APP_BASE_API_URL,
      DomainName: process.env.REACT_APP_CLIENT_DOMAIN_NAME,
      Timeout: process.env.REACT_APP_TIMEOUT,
      BaseUrl: process.env.REACT_APP_BASE_URL.slice(0, -1),
      CloudSearchUrl: process.env.REACT_APP_AWS_CLOUD_SEARCH_API_URL,
      TenantURL: (domainPrefix = "") => {
        return "https://" + domainPrefix + process.env.REACT_APP_TENANT_PARTIAL_URL;
      },
      Client: {
        BaseHost: process.env.REACT_APP_CLIENT_BASE_HOST,
        BasePort: process.env.REACT_APP_CLIENT_BASE_PORT,
      },

      // MOVE this to env
      Protocol: "https",

      // Tenant
      GetTenant: "/tenant/get",
      getProfileData: "/users/get-profile",
      updateProfile: "/users/update-profile",

      // Assignment
      LoginUser: "/auth/signin",
      LoginAsOrgUser: "/auth/login-as",

      // Forget password
      ForgotPassword: "/auth/forget-password",

      //Reset password
      ResetPassword: "/auth/reset-password",

      //Organizations
      organization: "/organization",
      UploadOrganizationImage: "/organization/uploads/logo",
      ResizeOrganizationImage: "/organization/uploads/logo/resize-image",
      DeleteResizeOrganizationLogo: "/organization/logo/delete-resize-image",
      UploadTaCFile: "/upload-images/pdf",
      OrganizationSettings: "/organization/settings",
      OrganizationTheme: "/room/theme",
      OrganizationThankyouPageSettings: "/room/get-thankyou-page-messages",

      //Room
      room: "/room",

      roomStatus: "/room/room-status",
      CreateRoom: "/room/create",
      uploadRoomImage: "/room/upload/logo",
      UploadRoomImage: "/upload-images",
      GetRoomImage: "/upload-images",
      GetRoomTags: "/room/tags",
      GetRoomName: "/room/get-room-names",
      GetQuestionTags: "/questions/tags",
      GetQuestionTimeLength: "/options",
      //Question
      CreateQuestion: "/questions",
      EditQuestion: "/questions",
      question: "/questions",
      publicQuestions: "/questions/question-public/all",
      QuestionsAuthor: "/questions/author-names",
      QuestionResponses: "/responses",
      PubicQuestionResponses: "/responses/response-public",
      QuestionById: "/questions",
      DeleteQuestion: "/questions",
      AddExistingQuestion: "/questions/add-existing",
      getPublicQuestion: "/questions/question-public/",
      addResponse: "/responses/",
      //Resposnes

      GetResponse: "/responses/all",
      ChangeStatus: "/responses/change-status/",
      publicRooms: "/room/room-public",
      cloneRoom: "/room/clone-room/",

      deleteVideos: "/responses/delete-video",
      downloadCSV: "/responses/export-csv/",
    },
    Method: {
      GET: "GET",
      POST: "POST",
      PUT: "PUT",
      DELETE: "DELETE",
    },
    Header: {
      ContentType: "Content-Type",
      ApplicationJson: "application/json",
      Default: (token = "") => ({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      }),
      Authorization: (token = "") => ({
        Authorization: "Bearer " + token,
      }),
      Type: {
        Json: "json",
        File: "file",
      },
    },
    Default: {
      AssignmentStatusID: 1,
      ResourceAllocationPercentage: 100,
      ResourceAllocationType: "percentage",
      WorkItem: "",
      Error: "Opps, an error occurred!",
    },
    StatusCode: {
      Unauthorized: 401,
    },
  },
  Actions: {
    // General part of action
    CREATE: "CREATE",
    UPSERT: "UPSERT",
    DELETE: "DELETE",
    DELETE_ALL: "DELETE_ALL",
    SET: "SET",
    UPDATE: "UPDATE",
    UPDATE_ALL: "UPDATE_ALL",
    UPDATE_IF_EXISTS: "UPDATE_IF_EXISTS",

    // Settings
    UPSERT_SETTING: "UPSERT_SETTING",
  },

  Cookie: {
    Key: {
      User: "user",
      EncryptionKey: "megilla_user",
      Tenant: "tenant",
      Token: "token",
    },
  },

  Roles: {
    Admin: "Admin",
    User: "User",
  },
  FormAcceptTypes: {
    termsAndConditions:
      // .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
      "application/pdf",
    images: "image/*",
  },
  imageAspectRatios: {
    organizationImage: {
      value: 1 / 1,
      stringValue: "1 : 1",
    },
    roomBannerImage: {
      value: 3 / 1,
      stringValue: "3 : 1",
    },
    profileImage: {
      value: 1 / 1,
      stringValue: "1 : 1",
    },
  },
  fileSizeLimits: {
    termsAndConditions: 10,
  },

  GOOGLE_FONTS_API_KEY: "AIzaSyC0B-6RiWQpEAGs_Q702xP_YVjwvUKjw48",

  // time is in milli seconds
  KIOSK_MODE_RELOAD_TIMEOUT: 2 * 1000,
  SentryDsnKey: "https://8d6084c289fb4417ae345334eb8bded3@o1112694.ingest.sentry.io/6149391",

  TEXT_MAX_LENGTH: 500,
};

export default K;
