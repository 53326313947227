import { CaretRightOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React from "react";
import ResponseGrid from "../room/responsesGrid";

const CollapsableQuestionResponses = ({ record }) => (
  <div className="mb-2 collapse-landing-page">
    <Collapse
      expandIconPosition="right"
      expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
    >
      <Collapse.Panel
        className="mb-4"
        header={
          <div className="collapseList">
            <h4>{record.statement}</h4>
            <div className="listIcons">
              {/* <h5>
                {" "}
                <EyeOutlined className="eyeIcon" /> PUBLISHED{" "}
              </h5> */}
              <h5>
                {" "}
                <CaretRightOutlined className="pauseIcon" /> {record.sum} videos{" "}
              </h5>
            </div>
          </div>
        }
      >
        <ResponseGrid
          questionId={record.id}
          isLandingPage
          listingViewProps={{
            fixedHeight: 600,
          }}
        />
      </Collapse.Panel>
    </Collapse>
  </div>
);

export default CollapsableQuestionResponses;
