import React from "react";
import { useDispatch } from "react-redux";
import NoDataImage from "../../assets/images/rooms-empty.png";
import Question from "../../redux/models/questions/questions";
import ListingView from "../common/listingsView";
import SlideQuestionStatement from "./slideQuestionStatement";

function SlideQuestionList({ roomId, questions, roomListing, isLandingPage = false }) {
  const dispatch = useDispatch();
  const loadMore = () => {
    dispatch(
      Question.getAll({
        isLandingPage,
      })
    );
  };
  return (
    <>
      <div className="question-list-wrap question-list-font-family">
        {!questions.length && !roomListing?.initialLoading ? (
          <div className="rooms-empty-card">
            <img src={NoDataImage} className="empty-rooms-img" />
            {!isLandingPage ? <p>You have no question, please press Add Question to add a new question </p> : ""}
          </div>
        ) : (
          <>
            <h3>Questions</h3>
            <ListingView
              dataSource={questions}
              RowCard={({ record }) => (
                <SlideQuestionStatement record={record} roomId={roomId}/>
              )}
              loadMore={loadMore}
              initialLoading={roomListing?.initialLoading}
              loadingMore={roomListing?.loadingMore}
              hasMore={roomListing?.hasMore}
              fixedHeight={600}
            />
          </>
        )}
      </div>
    </>
  );
}

export default SlideQuestionList;
