import { Button, Col, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../components/common/loadingSpinner";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import QuestionTags from "../../redux/models/questionTags/questionTags";
import RoomName from "../../redux/models/roomName/roomName";
import RoomTags from "../../redux/models/roomTags/roomTags";
import VideoArchive from "../../redux/models/videoArchive/videoArchive";
import { resetVideoFilters } from "../../redux/slice/listings";
import ListingInput from "../common/listingInput";
import ListingSelect from "../common/listingSelect";
import RangePickerSelect from "../common/rangePickerSelect";

const fieldSpans = {
  span: 24,
  md: 12,
  lg: 6,
};

const publishedStatuses = [
  { display: "All", value: undefined },
  { display: "Published", value: true },
  { display: "Unpublished", value: false },
];

const sentimentList = [
  { id: 1, display: "Positive", value: "POSITIVE" },
  { id: 2, display: "Negative", value: "NEGATIVE" },
  { id: 3, display: "Neutral", value: "NEUTRAL" },
  { id: 4, display: "Mixed", value: "MIXED" },
];

function VideoArchiveFilters({ model }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const roomName = useSelector((state) => getSelector(RoomName)(state));
  const roomTags = useSelector((state) => getSelector(RoomTags)(state));
  const questionTags = useSelector((state) => getSelector(QuestionTags)(state));

  useEffect(() => {
    fetchData();
  }, []);

  function disabledDate(current) {
    // Can not select days before today and today
    return current > moment().endOf("day");
  }

  async function fetchData() {
    try {
      // setIsLoading(true);
      await dispatch(RoomName.getRoomName());
      await dispatch(RoomTags.getAll());
      await dispatch(QuestionTags.getAll());
      // setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Row gutter={16} className="va-filter">
        <Col {...fieldSpans} className="mb-3">
          <ListingInput placeholder="Respondent Name" data={roomName} model={model} fieldName="respondentName" />
        </Col>
        <Col {...fieldSpans} className="mb-3">
          <ListingSelect
            placeholder="Project Name"
            data={roomName}
            model={model}
            fieldName="roomId"
            selectValue="id"
            selectDisplay="name"
          />
        </Col>
        <Col {...fieldSpans} className="mb-3">
          <ListingSelect
            placeholder="Published Status"
            data={publishedStatuses}
            model={model}
            fieldName="isAccepted"
            selectValue="value"
            selectDisplay="display"
          />
        </Col>
        <Col {...fieldSpans} className="mb-3">
          <RangePickerSelect
            model={VideoArchive}
            fieldName="date"
            pickerProps={{
              disabledDate,
            }}
          />
        </Col>
        <Col {...fieldSpans} className="mb-3">
          <ListingSelect
            placeholder="Project Tags"
            data={roomTags}
            model={model}
            fieldName="roomTags"
            selectValue="id"
            selectDisplay="name"
            mode="multiple"
          />
        </Col>
        <Col {...fieldSpans} className="mb-3">
          <ListingSelect
            placeholder="Question Tags"
            data={questionTags}
            model={model}
            fieldName="questionTags"
            selectValue="id"
            selectDisplay="name"
            mode="multiple"
          />
        </Col>
        <Col {...fieldSpans} className="mb-3">
          <ListingInput placeholder="Search on Words" model={model} fieldName="wordsSearch" />
        </Col>
        <Col {...fieldSpans} className="mb-3">
          <ListingSelect
            placeholder="Sentiment Analysis"
            data={sentimentList}
            model={model}
            fieldName="sentimentAnalysis"
            selectValue="value"
            selectDisplay="display"
            mode="multiple"
          />
        </Col>
        <Col {...fieldSpans} className="mb-3">
          <Button
            className="w-100"
            onClick={() =>
              dispatch(
                model.getAll({
                  clear: true,
                })
              )
            }
            type="primary"
          >
            Apply
          </Button>
        </Col>
        <Col {...fieldSpans} className="mb-3">
          <Button
            className="w-100"
            onClick={() => {
              dispatch(resetVideoFilters());
              dispatch(
                model.getAll({
                  clear: true,
                })
              );
            }}
            type="primary"
            ghost
          >
            Clear
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default VideoArchiveFilters;
