import { Layout, Result } from "antd";
import React from "react";

export default function NotFound() {
  return (
    <Layout.Content
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        // extra={<Button type="primary">Back Home</Button>}
      />
    </Layout.Content>
  );
}
