import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, message, Row, Select, Switch, Tooltip, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import Rooms from "../../redux/models/rooms/rooms";
import RoomTags from "../../redux/models/roomTags/roomTags";
import Organization from "../../redux/models/organization/organization";

import K from "../../utilities/constants";
import CustomImageUpload from "../common/formItems/CustomImageUpload";
import SelectTemplate from "../common/formItems/selectTemplate";
import LoadingSpinner from "../common/loadingSpinner";
import CustomPageHeader from "../common/pageHeader";
import RoomActions from "../common/roomActions";
import RoomPageHeaderTitle from "../common/roomPageHeaderTitle";
import CustomColorPicker from "../common/formItems/customColorPicker";
import CustomTermsAndConditionsUpload from "../common/formItems/customTermsAndCondition";
import FontPicker from "font-picker-react";
import CustomPrivactPolicyUpload from "../common/formItems/customPrivacyPolicy";
let thankyouPageValues = {
  thankyouHeading: "Thank you for your video!",
  thankyouDescription: "",
  buttonText: "Thanks!",
};
let colorSelected = {
  headerColor: "#40A9FF",
  headerFontColor: "#141f30",
  buttonColor: "#9c2890",
  buttonFontColor: "#781335",
  primaryFontColor: "#781335",
  fontFamily: "sans-serif",
};
export default function CreateNewRoom() {
  const { Title } = Typography;

  const { id } = useParams();
  const isEdit = id ? true : false;

  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);

  const [selectedColor, setSelectedColor] = useState(colorSelected);
  const [thankuPageObj, setThankuPageObj] = useState(thankyouPageValues);
  const [activeFontFamily, setActiveFontFamily] = useState("sans-serif");

  const history = useHistory();

  const { t } = useTranslation();

  const { Option } = Select;

  const dispatch = useDispatch();
  const [initialRoomData, setInitialRoomData] = useState(null);
  const roomTags = useSelector((state) => getSelector(RoomTags)(state));

  const initialize = () => {
    if (isEdit) {
      fetchData();
    }
    getRoomTags();
  };

  useEffect(initialize, []);

  async function getRoomTags() {
    setIsLoadingTags(true);
    await dispatch(RoomTags.getAll());
    setIsLoadingTags(false);
  }
  async function fetchData() {
    try {
      setIsLoading(true);
      let singleRoom = await dispatch(Rooms.getRoomWithId(id));
      const { headerColor, headerFontColor, buttonColor, buttonFontColor, primaryFontColor, fontFamily } = singleRoom;
      let colorSelected = {
        headerColor: headerColor || "#FFFFFF",
        headerFontColor: headerFontColor || "#000000",
        buttonColor: buttonColor || "#2CABE1",
        buttonFontColor: buttonFontColor || "#FFFFFF",
        primaryFontColor: primaryFontColor || "2CABE1",
        fontFamily: fontFamily || "sans-serif",
      };
      let thankyouPageValues = {
        thankyouHeading: singleRoom?.thankyouHeading || "Thank you for your video!",
        thankyouDescription: singleRoom?.thankyouDescription || "",
        buttonText: singleRoom?.buttonText || "Thanks!",
      };
      setSelectedColor(colorSelected);
      setThankuPageObj(thankyouPageValues);
      setActiveFontFamily(fontFamily);

      setInitialRoomData({
        ...singleRoom,
        ...colorSelected,
        ...thankyouPageValues,
        tags: singleRoom.tags.map((el) => el.name),
        pdfUrlDummy: singleRoom.pdfUrl,
        pdfUrlDummy2: singleRoom.privacyPolicyPdfUrl,
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }
  const onFinish = async (values) => {
    const updatedValues = {
      ...values,
      ...(isEdit ? { selectedColor } : {}),
      ...(isEdit ? { thankuPageObj } : {}),
      fontFamily: values.fontFamily?.family || activeFontFamily || "sans-serif",
      pdfUrl: values.customUpload == null ? null : values.pdfUrl,
      privacyPolicyPdfUrl: values.customUpload2 == null ? null : values.privacyPolicyPdfUrl,
    };
    setSaveButtonLoading(true);
    updatedValues.tags = updatedValues.tags?.map((el) => ({
      id: roomTags.find((tag) => tag.name.toLowerCase().trim() == el.toLowerCase().trim())?.id ?? 0,
      name: el.trim(),
    }));
    try {
      let room = !isEdit
        ? await dispatch(Rooms.createRoom(updatedValues))
        : await dispatch(
            Rooms.editRoom({
              ...updatedValues,
              id: id,
            })
          );
      setSaveButtonLoading(false);
      if (!isEdit) {
        message.success("Congratulations! You created a project. Now add questions to the project.", 10);
      } else {
        message.success("Successfully updated project details");
      }
      history.push("/rooms/dashboard/" + room.id);
    } catch (error) {
      console.error(error);
    }
    setSaveButtonLoading(false);
  };

  return (
    <>
      <CustomPageHeader
        title={isEdit ? <RoomPageHeaderTitle room={initialRoomData} title="Edit Project" /> : "Create New Project"}
        breakToNewLine={isEdit}
        actions={[<RoomActions room={initialRoomData} showEdit={false} showDownloadCSV={false} />]}
      />
      <div className="form-container content-area">
        <div className="container-card">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <Form
              hideRequiredMark={true}
              layout="vertical"
              className="user-settings"
              initialValues={
                isEdit
                  ? {
                      isPublic: false,
                      ...initialRoomData,
                    }
                  : { isPublic: false, ...colorSelected, ...thankyouPageValues }
              }
              onFinish={onFinish}
            >
              <h3 className="pb-3"> {isEdit ? t("Edit Project") : t("Create Project")}</h3>
              <h5>Please fill in the details to create project</h5>
              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <Form.Item
                    name="pictureUrl"
                    label="Upload File"
                    className="cr-image-banner"
                    // tooltip={{ title: "Tooltip with customize icon", icon: <InfoCircleOutlined /> }}
                  >
                    <CustomImageUpload
                      onUpload={(image) => dispatch(Rooms.uploadImageLogo(image))}
                      path="/upload-images"
                      aspectRatio={K.imageAspectRatios.roomBannerImage}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row gutter={[16, 0]}>
                    <Col span={24} md={12}>
                      <Form.Item
                        name="name"
                        label="Project Name"
                        rules={[
                          {
                            required: true,
                            message: "Please add project name!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter project name" className="megilla-input" />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={12}>
                      <Form.Item
                        name="authorName"
                        label="Owner/Author"
                        rules={[
                          {
                            required: true,
                            message: "Please add author name!",
                          },
                        ]}
                      >
                        <Input placeholder="Add owner/author" className="megilla-input" />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={12}>
                      <Form.Item
                        name="tags"
                        label="Tags"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please add tags associated !",
                        //   },
                        // ]}
                      >
                        <Select
                          mode="tags"
                          placeholder="Select Tags"
                          className="megilla-select-selector"
                          style={{ width: "100%" }}
                          loading={isLoadingTags}
                          onInputKeyDown={(e) => {
                            let keynum;
                            if (window.event) {
                              // IE
                              keynum = e.keyCode;
                            } else if (e.which) {
                              // Netscape/Firefox/Opera
                              keynum = e.which;
                            }
                            if (keynum == 32) {
                              e.preventDefault();
                              e.keyCode = 13;
                              e.which = 13;
                            }
                          }}
                          tokenSeparators={[" ", ","]}
                        >
                          {roomTags?.map((tag) => {
                            return (
                              <Option key={tag.id} value={tag.name}>
                                {tag.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={24} md={12}>
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                      {
                        required: true,
                        message: "Please add description !",
                      },
                    ]}
                  >
                    <TextArea placeholder="Enter description" showCount maxLength={500} className="megilla-textarea" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item name="projectNotes" label="Project Notes">
                    <TextArea
                      placeholder="Enter project notes"
                      showCount
                      maxLength={500}
                      className="megilla-textarea"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    name="templateId"
                    label="Template"
                    className="select-templete-container"
                    rules={[
                      {
                        required: true,
                        message: "Please select template",
                      },
                    ]}
                  >
                    <SelectTemplate />
                  </Form.Item>
                </Col>
                <Col span={24} md={12} className="rooms-public-switch">
                  <Form.Item name="isPublic" valuePropName="checked" className="public-switch" label="Published">
                    <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}></Switch>
                  </Form.Item>
                </Col>
                <Col span={16} md={12}>
                  <h5 style={{ fontWeight: "bold", color: "black" }}>Custom Organization Logo</h5>
                </Col>
                <Col span={16} md={12}>
                  <div className="accounts-image-upload">
                    <Form.Item name="customOrgLogoUrl" className="cr-image-banner">
                      <CustomImageUpload
                        onUpload={(image) => dispatch(Rooms.uploadCustomOrganizationLogo(image))}
                        path={"/organization/uploads/logo"}
                        aspectRatio={K.imageAspectRatios.organizationImage}
                        crop={false}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={16} md={12}>
                  <h5 style={{ fontWeight: "bold", color: "black" }}>Custom Organization Tag Line: </h5>
                </Col>
                <Col span={16} md={12}>
                  <Tooltip placement="top" title={"Tagline for your organization"}>
                    <Form.Item
                      name="customOrgTagLine"
                      rules={[
                        {
                          max: 80,
                          message: "Tagline should be less than or equal to 80 characters.",
                        },
                      ]}
                    >
                      <Input placeholder="Enter custom tag line for your organization" className="megilla-input" />
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col span={16} md={12}>
                  <h5 style={{ fontWeight: "bold", color: "black" }}>Custom Organization Name: </h5>
                </Col>
                <Col span={16} md={12}>
                  <Tooltip placement="top" title={"Tagline for your organization"}>
                    <Form.Item
                      name="customOrgName"
                      rules={[
                        {
                          max: 80,
                          message: "Custom Name should be less than or equal to 80 characters.",
                        },
                      ]}
                    >
                      <Input placeholder="Enter custom name for your organization" className="megilla-input" />
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col span={16} md={12}>
                  <h5 style={{ fontWeight: "bold", color: "black" }}>Custom Organization URL: </h5>
                </Col>
                <Col span={16} md={12}>
                  <Tooltip placement="top" title={"Tagline for your organization"}>
                    <Form.Item
                      name="homepageUrl"
                      rules={[
                        {
                          // max: 80,
                          message: "Please add the url!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter URL" className="megilla-input" />
                    </Form.Item>
                  </Tooltip>
                </Col>

                <Col span={16} md={12}>
                  <h5 style={{ fontWeight: "bold", color: "black" }}>Custom Organization Description: </h5>
                </Col>
                <Col span={16} md={12}>
                  <Tooltip placement="top" title={"Please Add a descriptive description about your organization "}>
                    <Form.Item
                      className="mb-0"
                      name="customOrgLongDescription"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please add description !",
                      //   },
                      // ]}
                    >
                      <TextArea
                        placeholder="Please add a descriptive description about your organization"
                        showCount
                        maxLength={500}
                        className="megilla-textarea"
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Divider />
              <Row gutter={[16, 16]} className="us-color color-picker-popover">
                <Col span={24} md={9}>
                  <h5>Header color: </h5>
                </Col>
                <Col span={24} md={8}>
                  <Form.Item name="headerColor">
                    <CustomColorPicker />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="us-color">
                <Col span={24} md={9}>
                  <h5>Header Font color: </h5>
                </Col>
                <Col span={24} md={8}>
                  <Form.Item name="headerFontColor">
                    <CustomColorPicker />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="us-color">
                <Col span={24} md={9}>
                  <h5>Button color: </h5>
                </Col>
                <Col span={24} md={8}>
                  <Form.Item name="buttonColor">
                    <CustomColorPicker />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="us-color">
                <Col span={24} md={9}>
                  <h5>Button Font color : </h5>
                </Col>
                <Col span={24} md={8}>
                  <Form.Item name="buttonFontColor">
                    <CustomColorPicker />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="us-color">
                <Col span={24} md={9}>
                  <h5>Primary Font Color : </h5>
                </Col>
                <Col span={24} md={8}>
                  <Form.Item name="primaryFontColor">
                    <CustomColorPicker />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="us-color">
                <Col span={24} md={9}>
                  <h5>Font Family : </h5>
                </Col>
                <Col span={24} md={8}>
                  <Form.Item name="fontFamily">
                    <FontPicker
                      apiKey={K.GOOGLE_FONTS_API_KEY}
                      activeFontFamily={activeFontFamily || "sans-serif"}
                      onChange={(nextFont) => {
                        setActiveFontFamily(nextFont.family);
                      }}
                    />
                    {/* <p className="apply-font">{activeFontFamily} font looks like this.</p> */}
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row gutter={[16, 16]} className="us-logo">
                <Col span={24} md={9}>
                  <h5>Upload Terms and Conditions Document </h5>
                </Col>
                <Col span={24} md={8}>
                  <Form.Item name="pdfUrlDummy">
                    <CustomTermsAndConditionsUpload
                      onUpload={(file) => Organization.uploadOrganizationTermNConditions(file)}
                      path={"/upload-images"}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="us-logo">
                <Col span={24} md={9}>
                  <h5>Upload Privacy Policy Document </h5>
                </Col>
                <Col span={24} md={8}>
                  <Form.Item name="pdfUrlDummy2">
                    <CustomPrivactPolicyUpload
                      onUpload={(file) => Organization.uploadOrganizationTermNConditions(file)}
                      path={"/upload-images"}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row align="middle">
                <Col span={24}>
                  <Title level={4} className="text-center mb-5">
                    Thankyou Page Settings
                  </Title>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="us-url-filed">
                <Col span={24} md={9}>
                  <h5> Thankyou Message: </h5>
                </Col>
                <Col span={24} md={8}>
                  <Tooltip placement="top" title={"Please add thankyou message for submit response"}>
                    <Form.Item
                      name="thankyouHeading"
                      rules={[
                        {
                          message: "Please add the thankyou message!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter thankyou message for submit-response" className="megilla-input" />
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="us-url-filed">
                <Col span={24} md={9}>
                  <h5> Thankyou Description: </h5>
                </Col>
                <Col span={24} md={8}>
                  <Tooltip placement="top" title={"Please add thankyou description for submit response"}>
                    <Form.Item
                      name="thankyouDescription"
                      rules={[
                        {
                          message: "Please add the thankyou description message!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter thankyou description message for submit-response"
                        className="megilla-input"
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="us-url-filed">
                <Col span={24} md={9}>
                  <h5> Button Text : </h5>
                </Col>
                <Col span={24} md={8}>
                  <Tooltip placement="top" title={"Please add button text for submit response"}>
                    <Form.Item
                      name="buttonText"
                      rules={[
                        {
                          message: "Please add the button text!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter button text for submit-response" className="megilla-input" />
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Col span={24} md={{ span: 12, offset: 12 }}>
                <Form.Item>
                  <Row justify="center">
                    <Button
                      block
                      type="primary"
                      className="form-submit-btn"
                      htmlType="submit"
                      size="large"
                      loading={saveButtonLoading}
                    >
                      Next
                    </Button>
                  </Row>
                </Form.Item>
              </Col>
              {/* </Row> */}
            </Form>
          )}
        </div>
      </div>
    </>
  );
}
