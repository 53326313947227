import { Layout } from "antd";
import React from "react";

export default function PublicPageLayout({ children }) {
  const { Content } = Layout;
  return (
    <React.Fragment>
      <Layout>
        <Content>{children}</Content>
      </Layout>
    </React.Fragment>
  );
}
