import { DatePicker } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setListingState } from "../../redux/slice/listings";
import { defaultFormat } from "../../utilities/dateUtility";

const { RangePicker } = DatePicker;

const RangePickerSelect = ({ model, fieldName, pickerProps = {} }) => {
  const dispatch = useDispatch();
  const dateFormat = defaultFormat;
  const selectedDate = useSelector((state) =>
    state.listings[model.listingSliceName][fieldName]
      ? [
          state.listings[model.listingSliceName][fieldName].startDate,
          state.listings[model.listingSliceName][fieldName].endDate,
        ]
      : undefined
  );

  const onChange = (value) => {
    dispatch(
      setListingState({
        listing: model.listingSliceName,
        value: {
          [fieldName]: value
            ? {
                startDate: value[0],
                endDate: value[1],
              }
            : undefined,
        },
      })
    );
  };

  return (
    <RangePicker
      // defaultValue={[moment("2015/01/01", dateFormat), moment("2015/01/01", dateFormat)]}
      value={selectedDate}
      format={dateFormat}
      className="w-100"
      onChange={onChange}
      {...pickerProps}
    />
  );
};

export default RangePickerSelect;
