import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Modal, Row } from "antd";
import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuestionResponse from "../../redux/models/questionResponse/questionResponse";
import { closeResponseDetails } from "../../redux/slice/responseDetailPopup";
import { getActualPathPublic } from "../common/formItems/CustomImageUpload";
import ReactPlayer from "react-player";

const ResponseDetailPopup = () => {
  const { record, visible } = useSelector((state) => state.responseDetailPopup);
  const dispatch = useDispatch();

  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  let videoSrc = getActualPathPublic("/responses/get-video/" + record?.response.videoUrl);

  const isAccepted = record?.response.isAccepted;

  const onClose = () => {
    dispatch(closeResponseDetails());
  };

  const onAccept = async () => {
    setAcceptLoading(true);
    await dispatch(QuestionResponse.acceptResponse(record.responseId));
    setAcceptLoading(false);
    onClose();
  };

  const onReject = async () => {
    setRejectLoading(true);
    await dispatch(QuestionResponse.rejectResponse(record.responseId));
    setRejectLoading(false);
    onClose();
  };

  return (
    <Modal
      visible={visible}
      // title={record?.isLandingPage ? undefined : "  " + record?.id}
      footer={null}
      onCancel={onClose}
      destroyOnClose
      centered
      // width={record?.isLandingPage ? "100%" : undefined}
      className={record?.isLandingPage && "video-modal-landing-page"}
    >
      <>
        <div
          className={!record?.isLandingPage && "submit-response-next-level"}
          style={{
            marginTop: 20,
          }}
        >
          <div className="sr-video">
            <div className="embed-responsive embed-responsive-16by9 mb-4">
              {record?.isLandingPage ?
                <ReactPlayer
                  url={videoSrc}
                  controls
                  width="100%"
                  playing
                  height="100%"
                  config={{
                    file: {
                      attributes: {
                        crossOrigin: "true",
                        controlsList: 'nodownload',
                      },
                      tracks: [
                        {
                          kind: "subtitles",
                          src: record?.videoSubtitle,
                          srcLang: "en",
                          default: true,
                        },
                      ],
                    },
                  }}
                /> :
                <ReactPlayer
                  url={videoSrc}
                  controls
                  width="100%"
                  playing
                  height="100%"
                  config={{
                    file: {
                      attributes: {
                        crossOrigin: "true",
                      },
                      tracks: [
                        {
                          kind: "subtitles",
                          src: record?.videoSubtitle,
                          srcLang: "en",
                          default: true,
                        },
                      ],
                    },
                  }}
                />
              }
            </div>
          </div>
          {!record?.isLandingPage && (
            <>
              <h2>User Information</h2>
              <Divider />
              <h3>Respondent Name:</h3>
              <p>{record?.respondents?.name}</p>
              <h3>Respondent Email:</h3>
              <p>{record?.respondents?.email}</p>
            </>
          )}

          {record?.question?.customFields?.length && !record?.isLandingPage ? (
            <>
              <h2>Custom Questions</h2>
              <Divider />
            </>
          ) : undefined}
          {!record?.isLandingPage &&
            record?.question?.customFields?.map((el) => (
              <>
                <h3>{el.statement}</h3>
                <p>{el.responseCustomFields[0].answer || "No answer"}</p>
              </>
            ))}
          {record?.question?.customFields.length ? <Divider /> : ""}
          {!record?.isLandingPage && (
            <Row justify="center" gutter={16}>
              <Col>
                <Button
                  type="primary"
                  ghost={isAccepted === false}
                  icon={<CheckCircleOutlined />}
                  onClick={onAccept}
                  loading={acceptLoading}
                >
                  {isAccepted === true ? "Accepted" : "Accept"}
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  ghost={isAccepted === true}
                  danger
                  icon={<CloseCircleOutlined />}
                  onClick={onReject}
                  loading={rejectLoading}
                >
                  {isAccepted === false ? "Rejected" : "Reject"}
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </>
    </Modal>
  );
};

export default ResponseDetailPopup;
