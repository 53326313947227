import { Typography } from "antd";
import React from "react";

const { Title, Text } = Typography;

const RoomPageHeaderTitle = ({ room, title }) => (
  <>
    <Text underline>{title}</Text>
    {room && <h2 className="mb-1">{room.name}</h2>}
  </>
);

export default RoomPageHeaderTitle;
