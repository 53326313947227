import NetworkCall from "../../../network/networkCall";
import Request from "../../../network/request";
import { parseSecond } from "../../../utilities/dateUtility";
import { upsertModels } from "../baseModel/baseActions";
import BaseModel from "../baseModel/baseModel";
import baseReducer from "../baseModel/baseReducer";

export default class QuestionTimeLength extends BaseModel {
  static getAll() {
    return async (dispatch) => {
      try {
        const questionTimeLength = await NetworkCall.fetch(Request.getQuestionTimeLength());
        console.log(questionTimeLength);
        dispatch(
          upsertModels(
            QuestionTimeLength,
            questionTimeLength
              .map((el) => ({
                ...el,
                name: parseSecond(+el.value),
                value: +el.value,
              }))
              .sort((a, b) => a.value - b.value)
              .map((el) => ({
                ...el,
                value: "" + el.value,
              }))
          )
        );
        return questionTimeLength;
      } catch (e) {
        console.error(e);
        return false;
      }
    };
  }
  // Reducer
  static reducer(action, QuestionTimeLength, session) {
    baseReducer(action, QuestionTimeLength, session);
  }
}

QuestionTimeLength.modelName = "QuestionTimeLength";
