import { Form, Radio, Typography } from "antd";
import React from "react";
import { getPublicFileFromServerUrl } from "../../../utilities/generalUtility";
import CustomFileUpload from "./customFileUpload";

const CustomPrivactPolicyUpload = ({ onChange, value, path, onUpload }) => {
  let newValue = value == null ? null : !!value;
  return (
    <>
      <Form.Item initialValue={newValue} name="customUpload2">
        <Radio.Group name="radiogroup">
          <Radio value={null}>Use megilla provided <Typography.Link target="_blank" href={getPublicFileFromServerUrl("Megilla - Privacy Policy.pdf")}> Privacy Policy. </Typography.Link></Radio>
          <Radio value={true}>Use custom privacy policy document</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          return getFieldValue("customUpload2") ? (
            <Form.Item
              name="privacyPolicyPdfUrl"
              rules={[
                {
                  required: getFieldValue("customUpload2"),
                  message: "Please upload privacy policy document.",
                },
              ]}
            >
              <CustomFileUpload value={value} onUpload={onUpload} path={path} uploadClipText={"Privacy Policy"} />
            </Form.Item>
          ) : undefined;
        }}
      </Form.Item>
    </>
  );
};

export default CustomPrivactPolicyUpload;
