import { CheckOutlined, CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, message, Modal, Row, Select, Switch } from "antd";
import React, { useEffect, useState, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { upsertModels } from "../../redux/models/baseModel/baseActions";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import QuestionArchive from "../../redux/models/questionArchive/questionArchive";
import Question from "../../redux/models/questions/questions";
import QuestionTags from "../../redux/models/questionTags/questionTags";
import QuestionTimeLength from "../../redux/models/questionTimeLength/questionTimeLength";
import LoadingSpinner from "../common/loadingSpinner";
import { Editor } from "react-draft-wysiwyg";
import EditorConvertToHTML from "../../components/EditorConvertToHTML";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import K from "../../utilities/constants";

const { TextArea } = Input;

/**
 *
 * @param {*} shouldNavigate pass a callback function like (questionIdToNavigate) => { your navigation }
 * @returns
 */
export default function CreateQuestion({
  roomId,
  questionId,
  buttonProps = {
    type: "primary",
    size: "large",
  },
  buttonText = "Create Question",
  shouldNavigate = false,
}) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const questionTimeLength = useSelector((state) => getSelector(QuestionTimeLength)(state));
  const [initialQuestionData, setInitialQuestionData] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const tags = useSelector((state) => getSelector(QuestionTags)(state));
  const dispatch = useDispatch();

  const initialize = () => {
    if (questionId && visible) {
      fetchData();
    }
    dispatch(QuestionTimeLength.getAll());
  };

  useEffect(initialize, [visible]);

  async function fetchData() {
    setLoading(true);
    try {
      var question = await dispatch(Question.getQuestionByID(questionId));
      dispatch(upsertModels(QuestionTags, question.tags));
      question.tags = question.tags.map((el) => el.name);
      form.setFieldsValue({
        ...question,
        isPublic: question.isPublic ? true : false,
        isShowedOnLandingPage: question.isShowedOnLandingPage ? true : false,
      });
    } catch (e) {
      message.error("Failed to load question.");
      setVisible(false);
    }
    setLoading(false);
    setInitialQuestionData({
      ...question,
      tags: question?.tags?.map((el) => el.name),
    });
  }

  const onFinish = async (values) => {
    console.log("values?>>>>>>>>>>>>>>>>>>>>>>>>>>.", values);
    values.customFields = values.customFields?.map((el) => ({
      ...el,
      isRequired: el.isRequired ?? false,
    }));
    // values.orientationPortrait = false; // undo of portrait mode
    setActionLoading(true);
    try {
      if (questionId) {
        await dispatch(
          Question.editQuestion(
            {
              ...values,
              tags: values.tags.map((el) => ({
                id: tags.find((tag) => tag.name.toLowerCase().trim() == el.toLowerCase().trim())?.id ?? 0,
                name: el,
              })),
              roomId: roomId,
            },
            questionId
          )
        );
        if (shouldNavigate) {
          shouldNavigate(questionId);
        }
      } else {
        let question = await dispatch(
          Question.createQuestion({
            ...values,
            tags: values.tags.map((el) => ({
              id: tags.find((tag) => tag.name.toLowerCase().trim() == el.toLowerCase().trim())?.id ?? 0,
              name: el.trim(),
            })),
            roomId: roomId,
          })
        );
        if (shouldNavigate) {
          shouldNavigate(question.id);
        }
      }

      dispatch(
        QuestionArchive.getAll({
          clear: true,
        })
      );
      dispatch(QuestionTags.getAll());
      setVisible(false);
    } catch (e) {
      console.error(e);
      message.error("Failed to create question");
    }
    setActionLoading(false);
  };

  return (
    <>
      <Button onClick={() => setVisible(true)} {...buttonProps}>
        {buttonText}
      </Button>
      <Modal
        visible={visible}
        width={1000}
        className="create-question-modal"
        footer={
          <>
            <Button onClick={() => setVisible(false)} size={"large"}>
              Close
            </Button>
            <Button type="primary" onClick={() => form.submit()} size={"large"} loading={actionLoading}>
              {questionId ? "Save" : "Add"}
            </Button>
          </>
        }
        title={buttonText}
        centered
        closable={false}
        destroyOnClose
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Form
            hideRequiredMark={true}
            initialValues={initialQuestionData}
            onFinish={onFinish}
            form={form}
            layout="vertical"
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  name="statement"
                  label="Question/Prompt"
                  rules={[
                    {
                      required: true,
                      message: "Please add question statement!",
                    },
                  ]}
                >
                  <TextArea
                    maxLength={255}
                    className="megilla-textarea"
                    showCount
                    autoSize
                    placeholder="Prompt question"
                    // className="megilla-textarea"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={12}>
                <Form.Item
                  name="authorName"
                  label="Owner/Author"
                  rules={[
                    {
                      required: true,
                      message: "Please add author name!",
                    },
                  ]}
                >
                  <Input placeholder="Enter author name" className="megilla-input" />
                </Form.Item>
              </Col>

              <Col span={24} sm={12}>
                <Form.Item
                  name="time"
                  label="Duration"
                  rules={[
                    {
                      required: true,
                      message: "Duration is required.",
                    },
                  ]}
                >
                  <Select style={{ width: "100%" }} className="megilla-select-selector" placeholder="Select Duration">
                    {questionTimeLength.map((el) => (
                      <Select.Option value={el.value}>{el.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="tags"
                  label="Tags"
                  rules={[
                    {
                      required: true,
                      message: "Please add tags associated !",
                    },
                  ]}
                >
                  <Select
                    mode="tags"
                    placeholder="Select Tags"
                    className="megilla-select-selector"
                    style={{ width: "100%" }}
                    maxTagCount={4}
                    maxTagTextLength={20}
                    onInputKeyDown={(e) => {
                      let keynum;
                      if (window.event) {
                        // IE
                        keynum = e.keyCode;
                      } else if (e.which) {
                        // Netscape/Firefox/Opera
                        keynum = e.which;
                      }
                      if (keynum == 32) {
                        e.preventDefault();
                        e.keyCode = 13;
                        e.which = 13;
                      }
                    }}
                    tokenSeparators={[" ", ","]}
                  >
                    {tags?.map((tag) => {
                      return (
                        <Select.Option key={tag.id} value={tag.name}>
                          {tag.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24} sm={12}>
                <Form.Item
                  name="description"
                  label="Message to Respondents"
                  rules={[
                    {
                      required: true,
                      message: "Please add description !",
                    },
                  ]}
                >
                  <EditorConvertToHTML desc={form.getFieldValue("description")} form={form} />

                  {/* <TextArea
                    placeholder="Message to Respondents"
                    className="megilla-textarea"
                    autoSize
                    showCount
                    maxLength={500}
                  /> */}
                  {/* {console.log("ali==",initialQuestionData)} */}
                </Form.Item>
              </Col>
              <Col span={24} sm={12}>
                <Form.Item name="internalDescription" label="Internal description">
                  <TextArea
                    placeholder="Internal Description"
                    className="megilla-textarea"
                    autoSize
                    showCount
                    maxLength={500}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="isPublic"
                  valuePropName="checked"
                  className="public-switch"
                  label="Accepting Submissions"
                >
                  <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="isShowedOnLandingPage"
                  valuePropName="checked"
                  className="public-switch"
                  label="Show On Landing Page"
                >
                  <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="orientationPortrait"
                  valuePropName="checked"
                  className="public-switch"
                  label="Enable Portrait Mode For Video Recording"
                >
                  <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Form.Item>
              </Col>
              <Col span={24} md={24}>
                <Form.Item
                  name="isCOPPACompliance"
                  valuePropName="checked"
                  className="public-switch"
                  label="Please switch this ON if requesting submissions from anyone under age 12"
                >
                  <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.List name="customFields">
                  {(fields, { add, remove }) => (
                    <Row gutter={(16, 16)}>
                      <Col span={24}>
                        <Form.Item className="add-form-custom-field">
                          <Button
                            className="custom-fields-btn"
                            size={"large"}
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          >
                            Add field
                          </Button>
                        </Form.Item>
                      </Col>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Col span={24} sm={12} key={key}>
                          <div className="fields-delete-icon">
                            <DeleteOutlined onClick={() => remove(name)} />
                          </div>

                          {/* <CloseOutlined onClick={() => remove(name)} /> */}

                          <Form.Item
                            {...restField}
                            name={[name, "statement"]}
                            fieldKey={[fieldKey, "statement"]}
                            rules={[{ required: true, message: "Question is required." }]}
                            className="form-custom-field"
                          >
                            {/* <TextArea placeholder="Internal Description" className="megilla-textarea" showCount maxLength={500} /> */}
                            <TextArea
                              placeholder="Enter Question"
                              maxLength={255}
                              className="megilla-textarea"
                              showCount
                            />
                          </Form.Item>
                          <Form.Item
                            className="form-custom-field-checkbox"
                            {...restField}
                            name={[name, "isRequired"]}
                            fieldKey={[fieldKey, "isRequired"]}
                            valuePropName="checked"
                          >
                            <Checkbox>Required</Checkbox>
                          </Form.Item>
                        </Col>
                      ))}
                    </Row>
                  )}
                </Form.List>
              </Col>
            </Row>

            {/* <Divider /> */}
          </Form>
        )}
      </Modal>
    </>
  );
}
