import React from "react";
import { getActualPath } from "../common/formItems/CustomImageUpload";
const RoomHeader = ({ name, description, pictureUrl }) => (
  <>
    <div
      className="banner-bg"
      style={{ backgroundImage: "url(" + (pictureUrl ? getActualPath("/upload-images/") + pictureUrl : "") + ")" }}
    >
      <div className="bunner-text">
        <h2>{name}</h2>
        <p>{description}</p>
      </div>
    </div>
  </>
);

export default RoomHeader;
