import { DownOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Layout, Menu } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import User from "../../redux/models/user/user";
import K from "../../utilities/constants";

export default function Header({ collapsed, toggle }) {
  const userUrl = User.getProfilePicture();
  const history = useHistory();
  const { Header } = Layout;
  const type = User.getType();
  const menu =
    type === "SuperAdmin" ? (
      <Menu>
        <Menu.Item key="3" onClick={() => User.logoutCall()}>
          Logout
        </Menu.Item>
      </Menu>
    ) : (
      <Menu>
        {/* <Menu.Item key="0" onClick={() => history.push("/account")}>
          Profile
        </Menu.Item>
        <Menu.Item key="1">
          <a href="">Change Password</a>
        </Menu.Item>
        <Menu.Divider /> */}
        <Menu.Item key="3" onClick={() => User.logoutCall()}>
          Logout
        </Menu.Item>
      </Menu>
    );
  return (
    <Header>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: "trigger",
        onClick: toggle,
      })}
      <div className="app-header-right">
        <div className="loggedin-user-dd">
          <Dropdown overlay={menu} trigger={["click"]}>
            <a href="#menu" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              <Avatar
                icon={userUrl ? <img src={`${K.Network.URL.BaseAPI}/upload-images/${userUrl}`} /> : <UserOutlined />}
              />{" "}
              {User.getName()} <DownOutlined />
            </a>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
}
