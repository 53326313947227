import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { List, Row } from "antd";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import EllipsisWrapper from "../common/ellipsesWrapper";

function QuestionStatement({ record, roomId, isLandingPage = false }) {
  const history = useHistory();
  const { questionId, hideQuestions } = useParams();
  const onClick = (questionId) => {
    if (!isLandingPage) {
      history.push("/rooms/" + hideQuestions + "/" + roomId + "/" + questionId);
    } else {
      history.push("/landing-page/" + roomId + "/" + questionId);
    }
  };
  return (
    <div className="rooms-questions-sidebar">
      {/* TODO: using list for every item */}
      <List itemLayout="horizontal">
        <List.Item onClick={() => onClick(record.id)} className={questionId == record.id ? "active mb-3" : "mb-3"}>
          <div className="sidebar-question-list-ellipsis">
            <EllipsisWrapper ellipsisProps={{ rows: 2, expandable: false }}>{record.statement}</EllipsisWrapper>
          </div>

          {/* <div className="rq-icons">{questionId == record.id ? <EyeInvisibleOutlined /> : <EyeOutlined />}</div> */}
        </List.Item>
      </List>
      {/* <p onClick={() => onClick(record.id)}>{record.statement}</p>
      <div className="rq-icons">
        <EyeOutlined />
        <EyeInvisibleOutlined />
      </div> */}
    </div>
  );
}

export default QuestionStatement;
