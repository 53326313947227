import { Divider, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeVideoDetails } from "../../redux/slice/videoPopup";
import { getActualPath, getActualPathPublic } from "../common/formItems/CustomImageUpload";
import ReactPlayer from "react-player";

const VideoPopup = () => {
  const { record, visible } = useSelector((state) => state.videoPopup);
  const dispatch = useDispatch();

  let videoSrc = getActualPath("/responses/get-video/" + record?.response.videoUrl);
  let videoSubtitle = getActualPath(
    "/responses/get-video-metadata/" + record?.response.videoUrl.split(".")[0] + ".vtt?isSubtitle=true"
  );

  const onClose = () => {
    dispatch(closeVideoDetails());
  };

  return (
    <Modal visible={visible} footer={null} onCancel={onClose} destroyOnClose centered>
      <>
        <div className="submit-response-next-level">
          <div className="sr-video">
            <div className="embed-responsive embed-responsive-16by9 mb-4">
              <ReactPlayer
                url={videoSrc}
                controls
                width="100%"
                config={{
                  file: {
                    attributes: {
                      crossOrigin: "true",
                    },
                    tracks: [
                      {
                        kind: "subtitles",
                        src: videoSubtitle,
                        srcLang: "en",
                        default: true,
                      },
                    ],
                  },
                }}
              />
            </div>
          </div>
          <h2>User Information</h2>
          <Divider />
          <h3>Respondent Name:</h3>
          <p>{record?.respondents?.name}</p>
          <h3>Respondent Email:</h3>
          <p>{record?.respondents?.email}</p>
        </div>
      </>
    </Modal>
  );
};

export default VideoPopup;
