import { createSlice } from "@reduxjs/toolkit";

export const recordingCountDown = createSlice({
  name: "recordingCountDown",
  initialState: {
    countDownBegin: false,
  },
  reducers: {
    setCountDown: (state, action) => {
      state.countDownBegin = action.payload;
    },
  },
});

export const { setCountDown } = recordingCountDown.actions;

export default recordingCountDown.reducer;
