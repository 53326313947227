import {
  CodeOutlined,
  DownloadOutlined,
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  LinkOutlined,
  QrcodeOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Col, Input, message, Row, Popconfirm } from "antd";
import Modal from "antd/lib/modal/Modal";
import QRCode from "qrcode.react";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import ConfirmationComponent from "../confirmationComponent";
import InstagramShareComponent from "./instagramSharePage";

const LINK = "Question Link";
const VIDEO_LINK = "Video Link";
const EMBED = "Embed Question";
const SLIDESHOWLINK = "Slideshow Link";
const EMBEDSLIDESHOW = "Embed Slideshow";
const QRCODE = "QR Code";
const FACEBOOK = "Facebook";
const LINKEDIN = "LinkedIn";
const TWITTER = "Twitter";
const INSTAGRAM = "Instagram";

export const SocialShareButton = ({ icon, text, wrapper: Wrapper, link }) => (
  <Wrapper url={link}>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: 5,
      }}
    >
      <Button icon={icon} type={"dashed"} size="large" shape="circle" />
      <div
        style={{
          fontSize: 12,
        }}
      >
        {text}
      </div>
    </div>
  </Wrapper>
);

export const ShareButton = ({ icon, text, onClick, isActive }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: 5,
    }}
    onClick={() => {
      onClick(text);
    }}
  >
    <Button icon={icon} type={isActive ? "primary" : "dashed"} ghost={isActive} size="large" shape="circle" />
    <div
      style={{
        fontSize: 12,
        color: isActive ? "#2CABE1" : undefined,
      }}
    >
      {text}
    </div>
  </div>
);

// THIS IS USED FOR EVERYTHING EXCEPT QUESTION
const ShareModal = ({
  pictureUrl = "",
  link = "",
  shareModalName = "",
  slideShowLink = "",
  record = "",
  isDisplaySlideShowLinks = true,
  text,
  buttonProps = {
    type: "primary",
  },
  buttonText = "Share",
  display = false,
  onClose = null,
  isPublic = true,
}) => {
  const [visible, setVisible] = useState(display);
  const [activeShare, setActiveShare] = useState(LINK);
  const [activeShareValue, setActiveShareValue] = useState();
  // const [showConfirmation, setShowConfirmation] = useState(false);
  let qrcode = link.split("/").at(-1);
  const [loop, setLoop] = useState(false);

  const isRoomLandingPage = link.includes("landing-page");
  const isQuestionPage = link.includes("submit-response");

  // const inputRef = useRef();

  useEffect(() => {
    onButtonSwitchOnCode(LINK);
  }, []);

  useEffect(() => {
    onButtonSwitchOnCode(activeShare);
  }, [loop]);

  const downloadQRCode = () => {
    const pngUrl = document.getElementById(qrcode).toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QR Code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const onButtonSwitchOnCode = (text) => {
    let searchParams = {};
    switch (text) {
      case LINK:
        setActiveShare(text);
        if (isQuestionPage && loop) {
          searchParams.loop = loop;
        }
        setActiveShareValue(qs.stringifyUrl({ url: link, query: searchParams }));
        break;
      case VIDEO_LINK:
        setActiveShare(text);
        if (isQuestionPage && loop) {
          searchParams.loop = loop;
        }
        setActiveShareValue(qs.stringifyUrl({ url: link, query: searchParams }));
        break;
      case EMBED:
        setActiveShare(text);

        if (isQuestionPage && loop) {
          searchParams.loop = loop;
        }
        searchParams.hideHeader = true;
        setActiveShareValue(
          `
        <iframe src="${qs.stringifyUrl({ url: link, query: searchParams })}" 
        style="flex-grow: 1;"
        name="megillaFrame" 
        scrolling="yes" 
        frameborder="0" 
        marginheight="0px" 
        marginwidth="0px" 
        height="100%" 
        width="100%" 
        allowfullscreen>
        </iframe>
        `
            .replace(/\s+/g, " ")
            .trim()
        );
        break;
      case SLIDESHOWLINK:
        setActiveShare(text);
        if (isQuestionPage && loop) {
          searchParams.loop = loop;
        }
        setActiveShareValue(qs.stringifyUrl({ url: slideShowLink, query: searchParams }));
        break;
      case EMBEDSLIDESHOW:
        setActiveShare(text);

        if (isQuestionPage && loop) {
          searchParams.loop = loop;
        }
        searchParams.hideHeader = true;
        setActiveShareValue(
          `
        <iframe src="${qs.stringifyUrl({ url: slideShowLink, query: searchParams })}" 
        style="flex-grow: 1;"
        name="megillaFrame" 
        scrolling="yes" 
        frameborder="0" 
        marginheight="0px" 
        marginwidth="0px" 
        height="100%" 
        width="100%" 
        allowfullscreen>
        </iframe>
        `
            .replace(/\s+/g, " ")
            .trim()
        );
        break;
      case QRCODE:
        setActiveShare(text);
        if (isQuestionPage && loop) {
          searchParams.loop = loop;
        }
        setActiveShareValue(qs.stringifyUrl({ url: link, query: searchParams }));
        break;

      default:
        console.log(text);
    }
  };

  const buttons = [
    {
      icon: <LinkOutlined />,
      text: LINK,
      onClick: onButtonSwitchOnCode,
    },
    {
      icon: <CodeOutlined />,
      text: EMBED,
      onClick: onButtonSwitchOnCode,
    },
    {
      icon: <LinkOutlined />,
      text: SLIDESHOWLINK,
      onClick: onButtonSwitchOnCode,
    },
    {
      icon: <CodeOutlined />,
      text: EMBEDSLIDESHOW,
      onClick: onButtonSwitchOnCode,
    },
    {
      icon: <QrcodeOutlined />,
      text: QRCODE,
      onClick: onButtonSwitchOnCode,
    },
  ];
  const invidualVideobuttons = [
    {
      icon: <LinkOutlined />,
      text: shareModalName === "videoModal" ? VIDEO_LINK : LINK,
      onClick: onButtonSwitchOnCode,
    },
    {
      icon: <CodeOutlined />,
      text: EMBED,
      onClick: onButtonSwitchOnCode,
    },
    {
      icon: <QrcodeOutlined />,
      text: QRCODE,
      onClick: onButtonSwitchOnCode,
    },
  ];

  const socialButtons = [
    {
      icon: <FacebookOutlined />,
      text: FACEBOOK,
      wrapper: FacebookShareButton,
      link: link,
    },
    {
      icon: <LinkedinOutlined />,
      text: LINKEDIN,
      wrapper: LinkedinShareButton,
      link: link,
    },
    {
      icon: <TwitterOutlined />,
      text: TWITTER,
      wrapper: TwitterShareButton,
      link: link,
    },
    // {
    //   icon: <InstagramOutlined />,
    //   text: INSTAGRAM,
    //   link: link,
    //   isQuestionPage: isQuestionPage,
    //   pictureUrl: pictureUrl,
    //   record: record,
    // },
  ];

  const copyToClipboard = () => {
    navigator.clipboard.writeText(activeShareValue);
    message.success("Copied to clipboard!");
  };

  return (
    <>
      {isPublic ? (
        <Button
          {...buttonProps}
          onClick={() => {
            setVisible(true);
          }}
        >
          {" "}
          {buttonText}
        </Button>
      ) : (
        <Popconfirm
          title={<div style={{ maxWidth: 200 }}>Accepting Submissions is not checked. Response will not be shared</div>}
          onCancel={() => {}}
          okText="Continue"
          onConfirm={() => {
            setVisible(true);
          }}
          cancelText="No"
        >
          <Button {...buttonProps}>{buttonText}</Button>
        </Popconfirm>
      )}
      <Modal
        className="copy-link-modal"
        visible={display || visible}
        centered
        footer={[
          <Button type="primary" size={"large"} onClick={() => (onClose ? onClose() : setVisible(false))}>
            Close
          </Button>,
        ]}
        title={<h3>{text}</h3>}
        onCancel={() => {
          onClose ? onClose() : setVisible(false);
        }}
      >
        {/* <Button type="link" size={"large"} className="p-0">
          Shared link
        </Button> */}
        <div
          className={shareModalName === "videoModal" ? "video-share-modal-icons" : ""}
          style={{
            display: "flex",
          }}
        >
          {isDisplaySlideShowLinks
            ? buttons.map((el) => (
                <ShareButton icon={el.icon} text={el.text} onClick={el.onClick} isActive={el.text === activeShare} />
              ))
            : invidualVideobuttons.map((el) => (
                <ShareButton icon={el.icon} text={el.text} onClick={el.onClick} isActive={el.text === activeShare} />
              ))}
        </div>
        <div
          className={shareModalName === "videoModal" ? "video-share-modal-icons" : ""}
          style={{
            display: "flex",
          }}
        >
          {socialButtons.map((el) => {
            // return el.text == INSTAGRAM ? <InstagramShareComponent {...el} /> :
            return <SocialShareButton {...el} />;
          })}
        </div>
        {activeShare !== QRCODE ? (
          <div
            style={{
              textAlign: "right",
            }}
          >
            {isPublic ? (
              <Button
                type="link"
                style={{
                  padding: 0,
                }}
                onClick={copyToClipboard}
              >
                Copy To Clipboard
              </Button>
            ) : (
              <Popconfirm
                title="Accepting Submissions is not checked. Response will not be shared"
                onConfirm={copyToClipboard}
                onCancel={() => {}}
                okText="Continue"
                cancelText="No"
              >
                <Button
                  type="link"
                  style={{
                    padding: 0,
                  }}
                >
                  Copy To Clipboard
                </Button>
              </Popconfirm>
            )}
          </div>
        ) : (
          <div
            style={{
              margin: 10,
            }}
          ></div>
        )}
        {activeShare === QRCODE ? (
          <Row gutter={10}>
            <Col
              span={12}
              style={{
                textAlign: "center",
              }}
            >
              <QRCode value={activeShareValue} size={200} id={qrcode} includeMargin={true} />
            </Col>
            <Col span={12}>
              This QR code is linked to your question. Download it and anyone can scan it to answer your question
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                size={"medium"}
                onClick={downloadQRCode}
                block
                style={{
                  marginTop: 20,
                }}
              >
                Download QR
              </Button>
            </Col>
          </Row>
        ) : (
          <Input.TextArea
            // className="modal-link-input "
            // ref={inputRef}
            // suffix={<CopyOutlined onClick={copyToClipboard} className="link-copy-btn" />}
            value={activeShareValue}
            autoSize
          />
        )}

        {isQuestionPage && (
          <Checkbox
            checked={loop}
            onChange={() => {
              setLoop((state) => !state);
            }}
            className="mt-2"
          >
            Repeat question on submit (KIOSK Mode)
          </Checkbox>
        )}
      </Modal>
    </>
  );
};

export default ShareModal;
