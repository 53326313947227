import { Button, Card, Col, Divider, Form, Input, Row, Tooltip, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import CryptoJS from "crypto-js";
import FontPicker from "font-picker-react";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomColorPicker from "../../components/common/formItems/customColorPicker";
import CustomImageUpload from "../../components/common/formItems/CustomImageUpload";
import CustomPrivactPolicyUpload from "../../components/common/formItems/customPrivacyPolicy";
import CustomTermsAndConditionsUpload from "../../components/common/formItems/customTermsAndCondition";
import LoadingSpinner from "../../components/common/loadingSpinner";
import CustomPageHeader from "../../components/common/pageHeader";
import Organization from "../../redux/models/organization/organization";
import User from "../../redux/models/user/user";
import K from "../../utilities/constants";

export default function Settings() {
  const {Title} = Typography;
  const {GOOGLE_FONTS_API_KEY} = K;

  let colorSelected = {
    headerColor: "#40A9FF",
    headerFontColor: "#141f30",
    buttonColor: "#9c2890",
    buttonFontColor: "#781335",
    primaryFontColor: "#781335",
    fontFamily: "sans-serif",
  };
  let thankyouPageValues = {
    thankyouHeading: "Thank you for your video!",
    thankyouDescription: "",
    buttonText: "Thanks!",
  }

  const [selectedColor, setSelectedColor] = useState(colorSelected);
  const [thankuPageObj, setThankuPageObj] = useState(thankyouPageValues);
  const formRef = React.createRef();
  const dispatch = useDispatch();
  const [initialSettings, setInitialSettings] = useState({});
  const [activeFontFamily, setActiveFontFamily] = useState("sans-serif");
  const [isLoading, setIsLoading] = useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      setIsLoading(true);
      const organizationSettings = await dispatch(Organization.getOrganizationSettings());
      const { headerColor, headerFontColor, buttonColor, buttonFontColor, primaryFontColor, fontFamily, organizationThankyouPage } =
        organizationSettings;
      let colorSelected = {
        headerColor: headerColor || "#FFFFFF",
        headerFontColor: headerFontColor || "#000000",
        buttonColor: buttonColor || "#2CABE1",
        buttonFontColor: buttonFontColor || "#FFFFFF",
        primaryFontColor: primaryFontColor || "2CABE1",
        fontFamily: fontFamily || "sans-serif",
      };
      let thankyouPageValues = {
        thankyouHeading: organizationThankyouPage?.thankyouHeading || "Thank you for your video!",
        thankyouDescription: organizationThankyouPage?.thankyouDescription || "",
        buttonText: organizationThankyouPage?.buttonText || "Thanks!",
      }

      setSelectedColor(colorSelected);
      setThankuPageObj(thankyouPageValues);
      setActiveFontFamily(fontFamily);
      setInitialSettings({ ...colorSelected, ...organizationSettings, ...thankyouPageValues });
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  }

  const onFinish = async (values) => {
    try {
      const settingValues = {
        ...selectedColor,
        ...thankuPageObj,
        ...values,
        fontFamily: values.fontFamily?.family || activeFontFamily || "sans-serif",
        pdfUrl: values.customUpload == null ? null : values.pdfUrl,
        privacyPolicyPdfUrl: values.customUpload2 == null ? null : values.privacyPolicyPdfUrl,
      };
      setSaveButtonLoading(true);
      await dispatch(Organization.organizationSettings(settingValues));
      let cookiesData = User.getUserObjectFromCookies();
      cookiesData.organization.pictureUrl = values.pictureUrl;

      let encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(cookiesData), K.Cookie.Key.EncryptionKey);
      Cookies.set(K.Cookie.Key.User, encryptedUser, {
        path: "/",
        domain: "." + K.Network.URL.Client.BaseHost,
        expires: 365,
      });
      window.location.reload();

      setSaveButtonLoading(false);
    } catch (e) {
      console.error(e);
    }
    setSaveButtonLoading(false);
  };

  const saveSettings = () => {
    formRef.current.submit();
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <CustomPageHeader
        title="Settings"
        // ctaText="Save Changes"
        // buttonLoading={saveButtonLoading}
        // ctaMethod={() => saveSettings()}
        actions={[
          <Button type="primary" size={"large"} onClick={saveSettings} loading={saveButtonLoading}>
            Save Changes
          </Button>,
        ]}
      />
      <Card className="user-settings content-area">
        <h3>Settings</h3>
        <Form
          layout="vertical"
          ref={formRef}
          name="settings"
          initialValues={{
            ...initialSettings,
            pdfUrlDummy: initialSettings.pdfUrl,
            pdfUrlDummy2: initialSettings.privacyPolicyPdfUrl,
          }}
          onFinish={onFinish}
        >
          <Row gutter={[16, 16]} className="us-logo">
            <Col span={24} md={9}>
              <h5>Upload Logo </h5>
            </Col>
            <Col span={24} md={8}>
              <div className="accounts-image-upload">
                <Form.Item name="pictureUrl" rules={[{ required: true }]} className="cr-image-banner">
                  <CustomImageUpload
                    onUpload={(image) => dispatch(Organization.uploadOrganizationLogo(image))}
                    path={"/organization/uploads/logo"}
                    aspectRatio={K.imageAspectRatios.organizationImage}
                    crop={false}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[16, 16]} className="us-url-filed">
            <Col span={24} md={9}>
              <h5> URL field (home page for the organization): </h5>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                name="homepageUrl"
                rules={[
                  {
                    message: "Please add the url!",
                  },
                ]}
              >
                <Input placeholder="Enter URL" className="megilla-input" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="us-url-filed">
            <Col span={24} md={9}>
              <h5> Tag Line: </h5>
            </Col>
            <Col span={24} md={8}>
              <Tooltip placement="top" title={"Tagline for your organization"}>
                <Form.Item
                  name="tagLine"
                  rules={[
                    {
                      message: "Please add the tagLine!",
                    },
                    {
                      max: 80,
                      message: "Tagline should be less than or equal to 80 characters.",
                    },
                  ]}
                >
                  <Input placeholder="Enter tag line for your organization" className="megilla-input" />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>
          <Row className="us-description d-none">
            <Col span={24} md={9}>
              <h5>Description: </h5>
            </Col>
            <Col span={24} md={8}>
              <Tooltip placement="top" title={"Please add description about your organization"}>
                <Form.Item
                  name="internalDescription"
                  rules={[
                    {
                      required: true,
                      message: "Please add description !",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Please give a description about your organization"
                    showCount
                    maxLength={500}
                    className="megilla-textarea"
                  />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>
          <Row className="us-description us-long-description">
            <Col span={24} md={9}>
              <h5>Description: </h5>
            </Col>
            <Col span={24} md={8}>
              <Tooltip placement="top" title={"Please Add a descriptive description about your organization "}>
                <Form.Item
                  className="mb-0"
                  name="longDescription"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please add description !",
                  //   },
                  // ]}
                >
                  <TextArea
                    placeholder="Please add a descriptive description about your organization"
                    showCount
                    maxLength={500}
                    className="megilla-textarea"
                  />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[16, 16]} className="us-color color-picker-popover">
            <Col span={24} md={9}>
              <h5>Header color: </h5>
            </Col>
            <Col span={24} md={8}>
              <Form.Item name="headerColor">
                <CustomColorPicker />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="us-color">
            <Col span={24} md={9}>
              <h5>Header Font color: </h5>
            </Col>
            <Col span={24} md={8}>
              <Form.Item name="headerFontColor">
                <CustomColorPicker />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="us-color">
            <Col span={24} md={9}>
              <h5>Button color: </h5>
            </Col>
            <Col span={24} md={8}>
              <Form.Item name="buttonColor">
                <CustomColorPicker />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="us-color">
            <Col span={24} md={9}>
              <h5>Button Font color : </h5>
            </Col>
            <Col span={24} md={8}>
              <Form.Item name="buttonFontColor">
                <CustomColorPicker />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="us-color">
            <Col span={24} md={9}>
              <h5>Primary Font Color : </h5>
            </Col>
            <Col span={24} md={8}>
              <Form.Item name="primaryFontColor">
                <CustomColorPicker />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="us-color">
            <Col span={24} md={9}>
              <h5>Font Family : </h5>
            </Col>
            <Col span={24} md={8}>
              <Form.Item name="fontFamily">
                <FontPicker
                  apiKey={GOOGLE_FONTS_API_KEY}
                  activeFontFamily={activeFontFamily || "sans-serif"}
                  onChange={(nextFont) => {
                    setActiveFontFamily(nextFont.family);
                  }}
                />
                {/* <p className="apply-font">{activeFontFamily} font looks like this.</p> */}
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[16, 16]} className="us-logo">
            <Col span={24} md={9}>
              <h5>Upload Terms and Conditions Document </h5>
            </Col>
            <Col span={24} md={8}>
              <Form.Item name="pdfUrlDummy">
                <CustomTermsAndConditionsUpload
                  onUpload={(file) => Organization.uploadOrganizationTermNConditions(file)}
                  path={"/upload-images"}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="us-logo">
            <Col span={24} md={9}>
              <h5>Upload Privacy Policy Document </h5>
            </Col>
            <Col span={24} md={8}>
              <Form.Item name="pdfUrlDummy2">
                <CustomPrivactPolicyUpload
                  onUpload={(file) => Organization.uploadOrganizationTermNConditions(file)}
                  path={"/upload-images"}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row align="middle">
          <Col span={24}>
              <Title level={4} className="text-center mb-5">Thankyou Page Settings</Title>
          </Col>
          </Row>
          <Row gutter={[16, 16]} className="us-url-filed">
            <Col span={24} md={9}>
              <h5> Thankyou Message: </h5>
            </Col>
            <Col span={24} md={8}>
              <Tooltip placement="top" title={"Please add thankyou message for submit response"}>
                <Form.Item
                  name="thankyouHeading"
                  rules={[
                    {
                      message: "Please add the thankyou message!",
                    },
                  ]}
                >
                  <Input placeholder="Enter thankyou message for submit-response" className="megilla-input" />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="us-url-filed">
            <Col span={24} md={9}>
              <h5> Thankyou Description: </h5>
            </Col>
            <Col span={24} md={8}>
              <Tooltip placement="top" title={"Please add thankyou description for submit response"}>
                <Form.Item
                  name="thankyouDescription"
                  rules={[
                    {
                      message: "Please add the thankyou description message!",
                    },
                  ]}
                >
                  <Input placeholder="Enter thankyou description message for submit-response" className="megilla-input" />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="us-url-filed">
            <Col span={24} md={9}>
              <h5> Button Text : </h5>
            </Col>
            <Col span={24} md={8}>
              <Tooltip placement="top" title={"Please add button text for submit response"}>
                <Form.Item
                  name="buttonText"
                  rules={[
                    {
                      message: "Please add the button text!",
                    },
                  ]}
                >
                  <Input placeholder="Enter button text for submit-response" className="megilla-input" />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>
        </Form>
        <Button type="primary" size={"large"} onClick={saveSettings} loading={saveButtonLoading}>
          Save Changes
        </Button>
      </Card>
    </>
  );
}
