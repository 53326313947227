import { Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setListingState } from "../../redux/slice/listings";

const ListingSelect = ({ model, fieldName, data, selectValue = "id", selectDisplay = "name", ...props }) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => state.listings[model.listingSliceName]?.[fieldName]);

  const { Option } = Select;
  const onChange = (value) => {
    dispatch(
      setListingState({
        listing: model.listingSliceName,
        value: {
          [fieldName]: value,
        },
      })
    );
  };

  return (
    <Select className="vaf-input-field" allowClear={true} {...props} onChange={onChange} value={value}>
      {data.map((el) => (
        <Option key={el.id} value={el[selectValue]}>
          {el[selectDisplay]}
        </Option>
      ))}
    </Select>
  );
};

export default ListingSelect;
