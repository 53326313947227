import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, message, Modal, Popconfirm, Row, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import ListingSearch from "../../components/common/listingSearch";
import ListingView from "../../components/common/listingsView";
import CustomPageHeader from "../../components/common/pageHeader";
import { pullToTopSelector } from "../../redux/models/baseModel/baseModel";
import Organization from "../../redux/models/organization/organization";
import User from "../../redux/models/user/user";
import { redirectToUrl } from "../../utilities/generalUtility";
import OrganizationListItem from "./organizationListItem";

// import 'react-perfect-scrollbar/dist/css/styles.css';
// import PerfectScrollbar from 'react-perfect-scrollbar'

const { confirm } = Modal;
export default function OrganizationList(props) {
  const history = useHistory();
  const location = useLocation();
  // const [seletedOrganizations, setSelectedOrganizations] = useState([]);
  const dispatch = useDispatch();

  /* Get organization data from selector */
  const organizationsData = useSelector((state) => pullToTopSelector(Organization, state));
  /* Slice values for virtual list */
  const organizationListing = useSelector((state) => state.listings.organizations);

  const [deleteLoading, setDeleteLoading] = useState(false);

  const [loginAsLoading, setLoginAsLoading] = useState(false);

  useEffect(() => {
    if (organizationListing.initialLoading) {
      dispatch(Organization.getAll());
    }
  }, []);
  async function fetchData() {}
  /* set organizations data */
  const loadMoreData = async () => {
    dispatch(Organization.getAll());
  };
  async function deleteOrganization(orgId) {
    setDeleteLoading(true);
    try {
      await dispatch(Organization.deleteOrganization(orgId));
      message.success("Successfully deleted organization");
    } catch (e) {
      message.error("Failed to delete organization");
    }
    setDeleteLoading(false);
  }

  async function loginAsOrganizationUser(orgId) {
    setLoginAsLoading(true);
    try {
      const user = await dispatch(User.loginAsCall(orgId));
      const { from } = location.state || { from: { path: "/" } };
      redirectToUrl(user?.organization?.domainPrefix, "/");
    } catch (e) {
      message.error("Failed to login as organization user");
    }
    setLoginAsLoading(false);
  }

  function deleteConfirmOrganization(organizationId, organizationName) {
    confirm({
      title: `Do you want to delete ${organizationName} organization?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      onOk: async () => {
        await deleteOrganization(organizationId);
      },
      onCancel() {},
      centered: true,
      okButtonProps: {
        loading: deleteLoading,
      },
    });
  }

  const actions = (id, name) => (
    <>
      <div className="d-flex flex-wrap">
        <Button type="link" className="px-0 mr-3" onClick={() => history.push(`organizations/edit/${id}`)}>
          Edit
        </Button>
        <Button type="link" className="px-0 mr-3" onClick={() => deleteConfirmOrganization(id, name)}>
          Delete
        </Button>
        <Popconfirm
          placement="topRight"
          title="Are you sure to Login As Organization User"
          onConfirm={() => loginAsOrganizationUser(id, name)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" className="px-0">
            Login as
          </Button>
        </Popconfirm>
      </div>
    </>
  );

  return (
    <>
      <CustomPageHeader
        title={props?.title ? props?.title : "Organizations"}
        actions={[
          <Button onClick={() => history.push("organizations/create")} type="primary">
            Create New Organization
          </Button>,
        ]}
      />

      <div div className="virtual-list content-area">
        <Row>
          <Col span={24} sm={12} md={8} lg={5}>
            <ListingSearch model={Organization} />
          </Col>
        </Row>
        <OrganizationListItem isHeader />
        {/* <PerfectScrollbar style={{height: 400}}> */}
        <div className="oraganizations-list">
          <ListingView
            dataSource={organizationsData}
            RowCard={({ record }) => <OrganizationListItem {...record} actions={actions} />}
            loadMore={loadMoreData}
            initialLoading={organizationListing.initialLoading}
            loadingMore={organizationListing.loadingMore}
            hasMore={organizationListing.hasMore}
          />
        </div>
        {/* </PerfectScrollbar> */}
      </div>
    </>
  );
}
