import { Avatar, Col, Row } from "antd";
import React from "react";
import EllipsisWrapper from "../../components/common/ellipsesWrapper";
import K from "../../utilities/constants";
export default function OrganizationListItem({
  isHeader,
  customDomain,
  domainPrefix,
  roomCount,
  videoCount,
  questionCount,
  id,
  internalDescription,
  organizationName,
  pictureUrl,
  actions,
}) {
  return (
    <>
      <Row className={isHeader ? "vl-heading align-items-center mt-3" : "ol-items-row"}>
        <Col span={3}>
          {/* REWRITE THIS */}
          {!isHeader ? (
            pictureUrl && <Avatar src={`${K.Network.URL.BaseAPI}/organization/uploads/logo/${pictureUrl}`} />
          ) : (
            <h3>Picture</h3>
          )}
        </Col>
        <Col span={3}>{isHeader ? <h3>{"Name"}</h3> : <div className="ol-item">{organizationName}</div>}</Col>
        {/* <Col span={5}>{isHeader ? <h3>{"Website"}</h3> : <div className="ol-item">{customDomain}</div>}</Col> */}
        <Col span={7}>
          {isHeader ? (
            <h3>{"Internal Description"}</h3>
          ) : (
            <div className="ol-item">
              <EllipsisWrapper>{internalDescription}</EllipsisWrapper>
            </div>
          )}
        </Col>
        <Col span={2}>{isHeader ? <h3>{"Rooms"}</h3> : <div className="ol-item">{roomCount}</div>}</Col>
        <Col span={2}>{isHeader ? <h3>{"Questions"}</h3> : <div className="ol-item">{questionCount}</div>}</Col>
        <Col span={3}>{isHeader ? <h3>{"Videos Submitted"}</h3> : <div className="ol-item">{videoCount}</div>}</Col>
        <Col span={4}>{actions ? actions(id, organizationName) : <h3>Actions</h3>}</Col>
      </Row>
    </>
  );
}
