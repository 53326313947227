import {
  DeleteOutlined,
  EditOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  ShareAltOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Button, message, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import QuestionResponse from "../../redux/models/questionResponse/questionResponse";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import ShareModal from "../../components/common/shareModal";
import CreateQuestion from "../../components/questions/createQuestions";
import Question from "../../redux/models/questions/questions";
import Rooms from "../../redux/models/rooms/rooms";
import { setListingState } from "../../redux/slice/listings";
import { getBaseClientUrl } from "../../utilities/generalUtility";
import ConditionalRender from "./conditionalRender";

function RoomActions({
  room,
  showNavigateToRoom = true,
  showNavigateToQuestionList = true,
  showDelete = true,
  showDownloadCSV = true,
  showEdit = true,
  showShare = true,
  showCreate = true,
}) {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const path = window.location.pathname;
  const roomQuestionId = path.split("/")[4];

  const responsesList = useSelector((state) =>
    getSelector(QuestionResponse)(state)
      .filter((el) => el.questionId == roomQuestionId)
      .map((el) => el.id)
  );

  const { hideQuestions } = useParams();

  const dispatch = useDispatch();
  const { confirm } = Modal;
  const history = useHistory();

  const navigateToEditRoom = () => {
    dispatch(
      setListingState({
        listing: "roomQuestions",
        value: {
          selectedRoom: null,
        },
      })
    );
    history.push(`/rooms/edit/${room.id}`);
  };

  function deleteConfirmRoom(roomId, roomName) {
    confirm({
      title: `Do you want to delete ${roomName} project?`,
      icon: <ExclamationCircleOutlined />,
      content: "This action is permanent and you will not be able to undo this.",
      onOk: () => removeRoom(roomId),
      onCancel() {},
      centered: true,
      okButtonProps: {
        loading: deleteLoading,
      },
    });
  }
  const removeRoom = async (roomId) => {
    setDeleteLoading(true);
    try {
      await dispatch(Rooms.deleteRoom(roomId));
      setDeleteLoading(false);
      message.success("Project deleted successfully");
      history.push("/rooms");
    } catch (e) {
      setDeleteLoading(false);
      message.error("Something Went Wrong");
    }
  };

  const navigateToRoomQuestionsList = () => {
    dispatch(
      setListingState({
        listing: "roomQuestions",
        value: {
          selectedRoom: null,
        },
      })
    );
    history.push(`/rooms/dashboard/${room.id}`);
  };

  const navigateToRoom = () => {
    history.push(`/rooms/n/${room.id}`);
  };

  async function downloadCSV() {
    try {
      if (roomQuestionId) {
        const response = await Question.downloadCSV(roomQuestionId);
        const fileURL = window.URL.createObjectURL(new Blob([response]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "question-details.xlsx");
        fileLink.setAttribute("target", "_blank");
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove();
      } else {
        message.error("No question found.");
      }
    } catch (e) {
      console.log("error", e);
      message.error("No response found.");
    }
  }
  return room ? (
    <>
      <ConditionalRender condition={showNavigateToRoom}>
        <Button type="primary" size="large" ghost icon={<EyeOutlined />} onClick={navigateToRoom}>
          View Project
        </Button>
      </ConditionalRender>
      <ConditionalRender condition={showNavigateToQuestionList}>
        <Button
          type="primary"
          size="large"
          ghost
          icon={<UnorderedListOutlined />}
          onClick={navigateToRoomQuestionsList}
        >
          View Question List
        </Button>
      </ConditionalRender>
      <ConditionalRender condition={showEdit}>
        <Button type="primary" size="large" ghost icon={<EditOutlined />} onClick={navigateToEditRoom}>
          Edit
        </Button>
      </ConditionalRender>
      <ConditionalRender condition={showShare}>
        <ShareModal
          link={getBaseClientUrl() + "/landing-page/" + room.id}
          slideShowLink={getBaseClientUrl() + "/slide-page/" + room.id}
          buttonProps={{
            icon: <ShareAltOutlined className="ant-icon" />,
            type: "primary",
            ghost: true,
            size: "large",
          }}
          buttonText="Share"
          text={
            <>
              Generate link for <span className="text-primary">Projects</span>
            </>
          }
        />
      </ConditionalRender>
      <ConditionalRender condition={showDownloadCSV}>
        <Button
          type="primary"
          size="large"
          ghost
          icon={<DownloadOutlined />}
          disabled={!roomQuestionId || responsesList.length === 0}
          onClick={() => downloadCSV()}
        >
          Download CSV
        </Button>
      </ConditionalRender>
      <ConditionalRender condition={showDelete}>
        <Button
          size="large"
          type="primary"
          ghost
          danger
          icon={<DeleteOutlined />}
          onClick={() => deleteConfirmRoom(room?.id, room?.name)}
        >
          Delete
        </Button>
      </ConditionalRender>
      <ConditionalRender condition={showCreate}>
        <CreateQuestion
          roomId={room.id}
          shouldNavigate={(questionId) => {
            history.replace("/rooms/" + hideQuestions + "/" + room.id + "/" + questionId);
          }}
        />
      </ConditionalRender>
    </>
  ) : (
    <></>
  );
}

export default RoomActions;
