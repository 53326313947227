import React from "react";
import K from "../../utilities/constants";

const OrganizationHeader = ({ pictureUrl, organizationName, tagLine, longDescription }) => (
  <>
    <div className="mph-content vlp-header organization-header">
      <div className="mph-logo organization-logo ">
        <img src={`${K.Network.URL.BaseAPI}/organization/uploads/logo/${pictureUrl}`} />
      </div>
      <div>
        <h2>{organizationName}</h2>
        {/* TODO: data messing */}
        {tagLine !== "" ? <p>{tagLine}</p> : null}
        <p className="mb-0 bold">{longDescription}</p>
      </div>
    </div>
  </>
);

export default OrganizationHeader;
