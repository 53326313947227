import { message } from "antd";
import { attr } from "redux-orm";
import NetworkCall from "../../../network/networkCall";
import Request from "../../../network/request";
import { resetListingState, setListingState } from "../../slice/listings";
import { deleteAllAndUpsertModels, deleteModel, upsertModel, upsertModels } from "../baseModel/baseActions";
import BaseModel from "../baseModel/baseModel";
import baseReducer from "../baseModel/baseReducer";

export default class Question extends BaseModel {
  static getAll({ clear = false, isLandingPage = false } = {}) {
    return async (dispatch, getState) => {
      if (clear) {
        dispatch(
          resetListingState({
            listing: this.listingSliceName,
            ignore: ["searchValue", "tags", "owner", "roomId"],
          })
        );
      }
      let sliceState = getState().listings[this.listingSliceName];
      let newPageNumber = sliceState.pageNumber + 1;
      dispatch(
        setListingState({
          listing: this.listingSliceName,
          value: {
            loadingMore: true,
            pageNumber: newPageNumber,
          },
        })
      );
      try {
        const orgList = await NetworkCall.fetch(
          Request.getAllQuestions(
            {
              roomId: sliceState.selectedRoom,
              pageNumber: newPageNumber,
              recordsPerPage: sliceState.pageSize,
              ...(sliceState.searchValue && { statement: sliceState.searchValue }),
              ...(sliceState.authorName && { authorName: sliceState.authorName }),
              ...(sliceState["tags.id"] &&
                sliceState["tags.id"].length && { "tags.id": JSON.stringify(sliceState["tags.id"]) }),
            },
            isLandingPage
          )
        );

        let orgLists = orgList.map((el) => ({
          authorName: el.authorname,
          isPublic: el.ispublic,
          time: el.time,
          organizationId: el.organizationid,
          id: el.id,
          count: el.count,
          internalDescription: el.internaldescription,
          description: el.description,
          statement: el.statement,
          newResponse: el.newResponse,
          clickedCount: el.clickedcount,
        }));

        dispatch(clear ? deleteAllAndUpsertModels(Question, orgLists) : upsertModels(Question, orgLists));
        dispatch(
          setListingState({
            listing: this.listingSliceName,
            value: {
              initialLoading: false,
              loadingMore: false,
              hasMore: orgList.length == sliceState.pageSize,
            },
          })
        );
        return orgList;
      } catch (e) {
        console.error(e);
        dispatch(
          setListingState({
            listing: this.listingSliceName,
            value: {
              initialLoading: false,
              loadingMore: false,
              hasMore: false,
            },
          })
        );
        return false;
      }
    };
  }

  static async getAllQuestions(dispatch) {
    const question = await NetworkCall.fetch(Request.getAllQuestions());
    dispatch(upsertModels(Question, question));
    return question;
  }

  static getRoomQuestions(id) {
    return async (dispatch) => {
      const question = await NetworkCall.fetch(
        Request.getAllQuestions({
          roomId: id,
        })
      );
      dispatch(deleteAllAndUpsertModels(Question, question));
      return question;
    };
  }
  static addExistingQuestions(reqBody) {
    return async (dispatch) => {
      const question = await NetworkCall.fetch(Request.addExistingQuestions(reqBody));
      // dispatch(upsertModels(Question, question));
      dispatch(
        Question.getAll({
          clear: true,
        })
      );
      return question;
    };
  }
  static createQuestion(questionData) {
    return async (dispatch) => {
      const question = await NetworkCall.fetch(Request.createQuestion(questionData));
      dispatch(
        Question.getAll({
          clear: true,
        })
      );
      return question;
    };
  }

  static editQuestion(questionData, questionId) {
    return async (dispatch) => {
      const question = await NetworkCall.fetch(Request.editQuestion(questionData, questionId));
      dispatch(upsertModel(Question, question));
      return question;
    };
  }

  static getQuestionByID(questionId) {
    return async (dispatch) => {
      const question = await NetworkCall.fetch(Request.getQuestionById(questionId));
      /*  dispatch(upsertModel(question)); */

      return question;
    };
  }

  static deleteQuestion(questionId) {
    return async (dispatch) => {
      try {
        await NetworkCall.fetch(Request.deleteQuestion(questionId));
        dispatch(deleteModel(Question, questionId));
        message.success("Successfully deleted question.");
      } catch (e) {
        console.error(e);
        message.error("Failed to delete question");
      }
    };
  }

  static getPublicQuestion(id) {
    return NetworkCall.fetch(Request.getPublicQuestion(id));
  }

  static downloadCSV(id) {
    return NetworkCall.fetch(Request.downloadCSV(id));
  }

  // Reducer
  static reducer(action, User, session) {
    baseReducer(action, User, session);
  }
}

Question.modelName = "Question";
Question.listingSliceName = "roomQuestions";

Question.fields = {
  // Attributes
  id: attr(),
  name: attr(),
  picture_url: attr(),
  description: attr(),
  organization_id: attr(),
  project_notes: attr(),
  template_id: attr(),
  ispublic: attr(),
  isPublic: attr(),
  user_id: attr(),

  //relation

  // room_ids: fk({
  //   to: "RoomList",
  //   as: "roomlist",
  //   relatedName: "questions",
  // }),
};
