import NetworkCall from "../../../network/networkCall";
import Request from "../../../network/request";
import { resetListingState, setListingState } from "../../slice/listings";
import { deleteAllAndUpsertModels, upsertModels } from "../baseModel/baseActions";
import BaseModel from "../baseModel/baseModel";
import baseReducer from "../baseModel/baseReducer";

export default class VideoArchive extends BaseModel {
  // API call using thunk.

  static getAll({ clear = false } = {}) {
    return async (dispatch, getState) => {
      if (clear) {
        dispatch(
          resetListingState({
            listing: this.listingSliceName,
            ignore: ["searchValue", "roomId", "date"],
          })
        );
      }
      let sliceState = getState().listings[this.listingSliceName];
      let newPageNumber = sliceState.pageNumber + 1;

      dispatch(
        setListingState({
          listing: this.listingSliceName,
          value: {
            loadingMore: true,
            pageNumber: newPageNumber,
          },
        })
      );
      try {
        const orgList = await NetworkCall.fetch(
          Request.videoArchiveList({
            pageNumber: newPageNumber,
            recordsPerPage: sliceState.pageSize,
            ...(sliceState.searchValue && { searchValue: sliceState.searchValue }),
            ...(sliceState.respondentName && { respondentName: sliceState.respondentName }),
            ...(sliceState.roomId && { roomId: sliceState.roomId }),
            ...(sliceState.date && { date: JSON.stringify(sliceState.date) }),
            ...(sliceState.roomTags?.length && { roomTags: JSON.stringify(sliceState.roomTags) }),
            ...(sliceState.questionTags?.length && { questionTags: JSON.stringify(sliceState.questionTags) }),
            ...(sliceState.wordsSearch && { wordsSearch: sliceState.wordsSearch }),
            ...(sliceState.sentimentAnalysis?.length && {
              sentimentAnalysis: JSON.stringify(sliceState.sentimentAnalysis),
            }),
            ...(sliceState.isAccepted !== undefined &&
              sliceState.isAccepted !== null && { isAccepted: sliceState.isAccepted }),
          })
        );
        dispatch(clear ? deleteAllAndUpsertModels(VideoArchive, orgList) : upsertModels(VideoArchive, orgList));
        dispatch(
          setListingState({
            listing: this.listingSliceName,
            value: {
              initialLoading: false,
              loadingMore: false,
              hasMore: orgList.length == sliceState.pageSize,
            },
          })
        );
        return true;
      } catch (e) {
        dispatch(
          setListingState({
            listing: this.listingSliceName,
            value: {
              initialLoading: false,
              loadingMore: false,
              hasMore: false,
            },
          })
        );
        return false;
      }
    };
  }

  static async downloadVideo(url) {
    let axiosInstance = Request.axiosInstance();
    return axiosInstance({
      url: url,
      method: "GET",
      responseType: "blob",
    });
  }

  // Reducer
  static reducer(action, VideoArchive, session) {
    baseReducer(action, VideoArchive, session);
  }
}

VideoArchive.modelName = "VideoArchive";
VideoArchive.listingSliceName = "videoArchive";
