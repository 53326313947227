import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import LoadingSpinner from "../../components/common/loadingSpinner";
import NoDataFound from "../../components/common/noDataFound";
import OneColumnLayout from "../../components/landingPage/oneColumnLayout";
import OrganizationHeader from "../../components/landingPage/organizationHeader";
import RoomHeader from "../../components/landingPage/roomHeader";
import TwoColumnLayout from "../../components/landingPage/twoColumnLayout";
import Organization from "../../redux/models/organization/organization";
import { useGetRoomByIdQuery } from "../../redux/services/roomApi";
import { setSassFileColors } from "../../utilities/generalUtility";

export default function LandingPage() {
  const { roomId } = useParams();
  const {
    data: {
      organization,
      name,
      description,
      pictureUrl,
      templateId,
      customOrgLogoUrl,
      customOrgLongDescription,
      customOrgTagLine,
      customOrgName,
    } = {},
    error,
    isLoading,
  } = useGetRoomByIdQuery(roomId);
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();

  let hideHeader = false;

  hideHeader = qs.parse(search, { parseBooleans: true }).hideHeader;

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoading(true);
    try {
      let colorsRes = await Organization.getOrganizationColor();
      const { headerColor, headerFontColor, buttonColor, buttonFontColor, primaryFontColor, fontFamily } = colorsRes;
      setSassFileColors(headerColor, headerFontColor, buttonColor, buttonFontColor, primaryFontColor, fontFamily);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <div className="view-landing-page custom-fontFamily">
      {isLoading || loading ? (
        <LoadingSpinner />
      ) : !error ? (
        <>
          {!hideHeader && (
            <OrganizationHeader
              pictureUrl={customOrgLogoUrl ? customOrgLogoUrl : organization.pictureUrl}
              organizationName={customOrgName ? customOrgName : organization.organizationName}
              tagLine={customOrgTagLine ? customOrgTagLine : organization.tagLine}
              longDescription={customOrgLongDescription ? customOrgLongDescription : organization.longDescription}
            />
          )}
          <RoomHeader name={name} description={description} pictureUrl={pictureUrl} />
          <div className={templateId == 2 ? "content-area mt-0" : "content-area mt-lg-0"}>
            {templateId == 2 ? <OneColumnLayout /> : <TwoColumnLayout />}
          </div>
        </>
      ) : (
        <NoDataFound description={"Failed to load project."} />
      )}
    </div>
  );
}
