import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import ListingSearch from "../../components/common/listingSearch";
import ListingSelect from "../../components/common/listingSelect";
import ListingView from "../../components/common/listingsView";
import NoDataFound from "../../components/common/noDataFound";
import CustomPageHeader from "../../components/common/pageHeader";
import RoomActions from "../../components/common/roomActions";
import RoomPageHeaderTitle from "../../components/common/roomPageHeaderTitle";
import CreateQuestion from "../../components/questions/createQuestions";
import QuestionRowCard from "../../components/questions/questionRowCard";
import QuestionTagsSelector from "../../components/roomDashboard/questionTagsSelector";
import AddFromExistingQuestion from "../../components/rooms/addFromExistingQuestions";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import Question from "../../redux/models/questions/questions";
import QuestionsAuthor from "../../redux/models/questionsAuthor/questionsAuthor";
import QuestionTags from "../../redux/models/questionTags/questionTags";
import Rooms from "../../redux/models/rooms/rooms";
import { setListingState } from "../../redux/slice/listings";

const RoomDashboard = () => {
  const dispatch = useDispatch();

  const questions = useSelector((state) => getSelector(Question)(state));
  const questionsAuthor = useSelector((state) => getSelector(QuestionsAuthor)(state));
  const { id } = useParams();

  const roomId = parseInt(id);
  const roomListing = useSelector((state) => state.listings[Question.listingSliceName]);
  const history = useHistory();

  const [room, setRoom] = useState();
  const [showShareModal, setShowShareModal] = useState(false);

  const initialize = async () => {
    // if (roomListing.selectedRoom !== roomId) {

    // }
    let room = await dispatch(Rooms.getRoomWithId(roomId));
    setRoom(room);
    dispatch(
      setListingState({
        listing: "roomQuestions",
        value: {
          selectedRoom: roomId,
        },
      })
    );
    dispatch(
      Question.getAll({
        clear: true,
      })
    );
    dispatch(QuestionTags.getAll());
    dispatch(QuestionsAuthor.getQuestionsAuthor());
  };

  useEffect(() => {
    initialize();
  }, []);

  const loadMore = () => {
    dispatch(Question.getAll());
  };

  const navigateToRoom = () => {
    history.push(`/rooms/n/${roomId}`);
  };

  return (
    <>
      <CustomPageHeader
        title={<RoomPageHeaderTitle room={room} title="Projects Dashboard" />}
        actions={[<RoomActions room={room} showNavigateToQuestionList={false} showDownloadCSV={false}/>]}
        breakToNewLine={true}
      />
      <div className="content-area">
        <div className="question_list_filter">
          <Row gutter={(32, 16)}>
            <Col span={24} md={12} lg={5}>
              <ListingSearch model={Question} />
            </Col>
            <Col span={24} md={12} lg={5}>
              <ListingSelect
                className="lf-search-bar"
                placeholder="Owner/Author"
                data={questionsAuthor}
                model={Question}
                fieldName="authorName"
                selectValue="authorName"
                selectDisplay="authorName"
              />
            </Col>
            <Col span={24} md={18} lg={11}>
              <QuestionTagsSelector />
            </Col>
            <Col span={24} md={6} lg={3}>
              <Button
                className="apply-filter-btn"
                type="primary"
                onClick={() => {
                  dispatch(
                    Question.getAll({
                      clear: true,
                    })
                  );
                }}
              >
                Apply
              </Button>
            </Col>
          </Row>
          <h3 className="list-heading">Questions List</h3>
          <div className="list-actions">
            <Button type="primary" ghost onClick={navigateToRoom}>
              View All Responses
            </Button>
            <div>
              <AddFromExistingQuestion roomId={roomId} buttonProps={{ style: { marginRight: 10 } }} />
              <CreateQuestion buttonProps={{ type: "primary" }} roomId={id} />
            </div>
          </div>
          {!questions.length && !roomListing?.initialLoading ? (
            <NoDataFound
              description={"Project contains no questions. Please add new questions by clicking the button below."}
              actions={[<CreateQuestion roomId={roomId} />]}
            />
          ) : (
            <>
              <div className="virtualList-container">
                <ListingView
                  dataSource={questions}
                  RowCard={QuestionRowCard}
                  loadMore={loadMore}
                  initialLoading={roomListing?.initialLoading}
                  loadingMore={roomListing?.loadingMore}
                  hasMore={roomListing?.hasMore}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {/* <CreateQuestion isModalVisible={true} /> */}
    </>
  );
};
export default RoomDashboard;
