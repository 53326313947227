import { ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, message } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import User from "../../redux/models/user/user";
import { setFieldErrorsFromServer } from "../../utilities/generalUtility";

export default function ForgotPassword() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const onFinish = async (values) => {
    try {
      await User.forgotPassword(values.email);
      message.success(`An email has been sent to ${values.email}`);
    } catch (error) {
      setFieldErrorsFromServer(error, form, values);
    }
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <React.Fragment>
      <div className="guest-layout">
        <div className="gl-banner">
          <img src="images/logo-black.png" alt="" className="gl-logo" />
        </div>
        <div className="gl-form">
          <h2 className="mb-5">Forgot Password</h2>
          <Form
            requiredMark={false}
            form={form}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon text-primary" />}
                placeholder="Email"
                size="large"
                className="megilla-input"
              />
            </Form.Item>

            <Form.Item>
              <Button block size="large" type="primary" htmlType="submit">
                {" "}
                Reset{" "}
              </Button>
            </Form.Item>
            <Divider plain>OR</Divider>
            <Form.Item className="mb-0 text-center">
              <Link to="/login">
                <ArrowLeftOutlined /> Back to Login
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}
