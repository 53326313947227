import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import LoadingSpinner from "../../components/common/loadingSpinner";
import NoDataFound from "../../components/common/noDataFound";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import Question from "../../redux/models/questions/questions";
import { setListingState } from "../../redux/slice/listings";
import ListingView from "../common/listingsView";
import CollapsableQuestionResponses from "./collapsableQuestionResponses";

export default function OneColumnLayout(props) {
  const { roomId, questionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const roomListing = useSelector((state) => state.listings[Question.listingSliceName]);
  const questions = useSelector((state) => getSelector(Question)(state));
  const [isEmpty, setIsEmpty] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    dispatch(
      setListingState({
        listing: "roomQuestions",
        value: {
          selectedRoom: roomId,
        },
      })
    );
    setIsLoading(true);
    try {
      let questionsList = await dispatch(
        Question.getAll({
          clear: true,
          isLandingPage: true,
        })
      );
      if (questionsList.length == 0) {
        setIsEmpty(true);
      } else {
        if (!questionId) {
          history.push("/landing-page/" + roomId + "/" + questionsList[0].id);
        }
      }
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  }
  const loadMore = () => {
    dispatch(
      Question.getAll({
        isLandingPage: true,
      })
    );
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <>
        <div className="rooms-details">
          {isEmpty ? (
            <NoDataFound description={"Project contains no questions."} />
          ) : (
            <>
              {/* <h3>Questions</h3> */}
              {/* <Button type={"link"} size={'large'} className="download-selected-btn"> Download Selected </Button> */}

              <ListingView
                dataSource={questions}
                RowCard={CollapsableQuestionResponses}
                loadMore={loadMore}
                initialLoading={roomListing?.initialLoading}
                loadingMore={roomListing?.loadingMore}
                hasMore={roomListing?.hasMore}
              />
            </>
          )}
        </div>
      </>
    </>
  );
}
