import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import Header from "../components/layout/header";
import Sider from "../components/layout/sider";
import { useWindowSize } from "../utilities/customHook";
import styles from "./layout.module.scss";

export default function LoggedInPageLayout({ children }) {
  const { Content } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const width = useWindowSize();

  useEffect(() => {
    if (width < 768) {
      setCollapsed(true);
    }
  }, [width]);

  const routes = [
    {
      path: "index",
      breadcrumbName: "First-level Menu",
    },
    {
      path: "first",
      breadcrumbName: "Second-level Menu",
    },
    {
      path: "second",
      breadcrumbName: "Third-level Menu",
    },
  ];
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsed={collapsed} />
      <Layout className={styles["site-layout"]}>
        <Header collapsed={collapsed} toggle={toggle} />
        <Content>
          {/* {location?.pathname != "/" && <Breadcrumbs />} */}
          {/* <PageHeader
            className="megilla-page-header"
            onBack={() => null}
            title="Rooms"
            breadcrumb={{ routes }}
            ghost={false}
            extra={[
              <Button key="3" size={"large"} type="primary">Create New Room</Button>
            ]}
          /> */}
          {children}
        </Content>
        {/* <Footer /> */}
      </Layout>
    </Layout>
  );
}
