import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import K from "../utilities/constants";

const EditorConvertToHTML = ({ desc, form }) => {
  const MAX_LENGTH = K.TEXT_MAX_LENGTH;
  const html = desc;
  const contentBlock = html && htmlToDraft(html);
  const contentState = contentBlock && ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const [editorState, setEditorState] = useState(contentState ? EditorState.createWithContent(contentState) : "");
  const [errorMessage, setErrorMessage] = useState("");

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    form.setFieldsValue({ description: editorState && draftToHtml(convertToRaw(editorState?.getCurrentContent())) });
  };

  const _getLengthOfSelectedText = () => {
    const currentSelection = editorState?.getSelection();
    const isCollapsed = currentSelection && currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState && editorState?.getCurrentContent();
      const startKey = currentSelection && currentSelection.getStartKey();
      const endKey = currentSelection && currentSelection.getEndKey();
      const startBlock = currentContent?.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock?.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection?.getStartOffset();
      const endSelectedTextLength = currentSelection?.getEndOffset();
      const keyAfterEnd = currentContent?.getKeyAfter(endKey);
      console.log(currentSelection);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection?.getEndOffset() - currentSelection?.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent?.getBlockForKey(currentKey)?.getLength() + 1;
          }

          currentKey = currentContent?.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const _handleBeforeInput = () => {
    const currentContent = editorState?.getCurrentContent();
    const currentContentLength = currentContent?.getPlainText("")?.length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      setErrorMessage("You cannot type more  than " + MAX_LENGTH + " characters");
      return "handled";
    }
  };

  const _handlePastedText = (pastedText) => {
    const currentContent = editorState?.getCurrentContent();
    const currentContentLength = currentContent?.getPlainText("")?.length;
    const selectedTextLength = _getLengthOfSelectedText();
    if (currentContentLength + pastedText?.length - selectedTextLength > MAX_LENGTH) {
      setErrorMessage("You cannot type more than " + MAX_LENGTH + " characters");
      return "handled";
    }
  };

  const _handleChange = () => {
    const currentContent = editorState?.getCurrentContent();
    const currentContentLength = currentContent && currentContent?.getPlainText("")?.length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      setErrorMessage("You cannot type more than " + MAX_LENGTH + " characters");
    } else {
      setErrorMessage("");
    }
  };

  return (
    <>
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor megilla-textarea"
        className="megilla-textarea"
        onEditorStateChange={onEditorStateChange}
        handleBeforeInput={_handleBeforeInput}
        handlePastedText={_handlePastedText}
        onChange={_handleChange}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
          inline: {
            options: ['bold', 'italic', 'underline', 'monospace']
          },
        }}
      />
      <textarea
        style={{ display: "none" }}
        isDisabled
        value={editorState && draftToHtml(convertToRaw(editorState?.getCurrentContent()))}
      />
      <div style={{ color: "red" }}>{errorMessage}</div>
    </>
  );
};

export default EditorConvertToHTML;
