import { CheckOutlined } from "@ant-design/icons";
import { Button, Popover, Row, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import { SketchPicker } from "react-color";

const CustomColorPickerPopoverContent = ({ onChange, value, onClose }) => {
  const [color, setColor] = useState(value);
  return (
    <>
      <SketchPicker color={color} onChangeComplete={({ hex }) => setColor(hex)} />
      <Row justify="space-between" style={{ paddingTop: 5 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="primary"
          icon={<CheckOutlined />}
          onClick={() => {
            onChange(color);
            onClose();
          }}
        >
          Done
        </Button>
      </Row>
    </>
  );
};

function CustomColorPicker({ onChange, value }) {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };
  return (
    <Row justify="space-between">
      <h5 className="us-color-text">{value}</h5>
      <div className="color-picker-popover">
        <Tooltip
          placement="top"
          title={"Changes header's font color only for public pages like Submit Response and Landing Page"}
        >
          <Popover
            getPopupContainer={(trigger) => trigger.parentElement}
            className="color-picker-popover"
            content={() => (
              <CustomColorPickerPopoverContent onChange={onChange} value={value} onClose={() => setVisible(false)} />
            )}
            trigger="click"
            visible={visible}
            onVisibleChange={handleVisibleChange}
          >
            <Tag
              className="us-color-box"
              //   onClick={() => setVisible(true)
              //   }
              color={value}
            />
          </Popover>
        </Tooltip>
      </div>
    </Row>
  );
}

export default CustomColorPicker;
