import Request from "../../../network/request";

export default class Responses {
  static addResponse(id, body, eventCb) {
    return Request.addResponse(id, body, eventCb);
  }
  // Reducer
  // static reducer(action, Rooms, session) {
  //   baseReducer(action, Rooms, session);
  // }
}

// Rooms.modelName = "Rooms";
// Rooms.listingSliceName = "rooms";

// Rooms.fields = {
//   // Attributes
//   id: attr(),
//   name: attr(),
//   picture_url: attr(),
//   description: attr(),
//   organization_id: attr(),
//   project_notes: attr(),
//   template_id: attr(),
//   is_public: attr(),
//   user_id: attr(),
// };
