import { DeleteFilled, PictureOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, message, Typography } from "antd";
import ImgCrop from "antd-img-crop";
import Dragger from "antd/lib/upload/Dragger";
import React from "react";
import User from "../../../redux/models/user/user";
import K from "../../../utilities/constants";
import ConditionalWrapper from "../conditionalWrapper";

const { Text } = Typography;

export const getActualPath = (path) =>
  User.getTenant() ? K.Network.URL.TenantURL(User.getTenant()) + path : K.Network.URL.BaseAPI + path;

export const getActualPathPublic = (path) =>
  K.Network.URL.TenantURL(/:\/\/([^\/]+)/.exec(window.location.href)[1].split(".")[0]) + path;
const CustomImageUpload = ({
  onChange,
  value,
  path,
  onUpload,
  sizeLimit = 50,
  crop = true,
  aspectRatio = {
    value: 1 / 1,
    stringValue: "1 / 1",
  },
}) => {
  const actualPath = getActualPath(path);

  const uploadProps = {
    name: "file",
    multiple: false,
    customRequest: async ({ onSuccess, onError, file }) => {
      try {
        if (file.size > sizeLimit * 1024 * 1024) {
          message.error("File size limit is " + sizeLimit + "mb");
          return;
        }
        let { pictureUrl } = await onUpload(file);
        onChange(pictureUrl);
      } catch (e) {
        message.error("Failed to upload image");
      }
    },
    accept: K.FormAcceptTypes.images,
  };

  const imageRender = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={`${actualPath}/${value}`}
        style={{
          height: 200,
          objectFit: "contain",
        }}
      />
    </div>
  );

  return (
    <>
      <ConditionalWrapper
        wrapper={({ children }) => <ImgCrop aspect={aspectRatio.value}>{children}</ImgCrop>}
        condition={crop}
      >
        <Dragger itemRender={() => null} className="dragger-img-box" {...uploadProps}>
          {value ? (
            <>
              <Button
                className="remove-image-icon"
                icon={<DeleteFilled />}
                danger
                // shape="circle"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onChange("");
                }}
              ></Button>
              {imageRender}
            </>
          ) : (
            <div className="py-3">
              <div className="ant-upload-drag-icon">
                <div className="upload-logo-style">
                  <PictureOutlined className="picture-outlined-icon" />
                </div>
              </div>
              <p className="ant-upload-text">Drag and Drop files here</p>
              <Button icon={<UploadOutlined />} size={"large"} className="img-upload-btn">
                Upload
              </Button>
            </div>
          )}
        </Dragger>
      </ConditionalWrapper>
      <div>
        <Text type="secondary">File size limit is {sizeLimit} mb.</Text>
        <br />
        {crop && <Text type="secondary">Recommended aspect ratio is {aspectRatio.stringValue}.</Text>}
      </div>
    </>
  );
};

export default CustomImageUpload;
