import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import ReactPlayer from "react-player";

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady, sources } = props;

  React.useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(videoElement, options, () => {
        console.log("player is ready");
        onReady && onReady(player);
      }));
    } else {
      // you can update player here [update player through props]
      const player = playerRef.current;
      // player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <>
      {/* <video id="myVideo" ref={videoRef} className="video-js" controls playsInline preload="true" /> */}
      <div className="react-player-wrapper">
        <ReactPlayer
          className="react-player"
          url={options.sources[0].src}
          controls
          playing
          width="100%"
          height="100%"
        />
        {/* <ReactPlayer id="myVideo" className="video-js" controls playsinline ref={videoRef} /> */}
      </div>
    </>
  );
};

export default VideoJS;
