import { Badge, Button, message, Modal } from "antd";
import { WarningFilled } from "@ant-design/icons";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import VideoArchive from "../../redux/models/videoArchive/videoArchive";
import Request from "../../network/request";
import NetworkCall from "../../network/networkCall";

const VideoDeleteSelected = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const urlForDelete = useSelector((state) =>
    getSelector(VideoArchive)(state)
      .filter((el) => el.isSelected)
      .map((el) => el.response.videoUrl)
  );

  const showModal = () => {
    if (urlForDelete.length < 1) {
      message.error("Select videos before deleting!");
    } else {
      setIsVisible(true);
    }
  };
  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    try {
      const response = await NetworkCall.fetch(Request.deleteVideos(urlForDelete));
      setIsVisible(false);
      setConfirmLoading(false);
      message.success(response);
      props.refetch();
    } catch (error) {
      message.error("Failed to delete videos.");
    }
  };

  return (
    <>
      <Modal
        title={
          <div>
            <WarningFilled style={{ color: "#ea0000" }} /> <b> Delete Confirmation</b>
          </div>
        }
        visible={isVisible}
        okText={"Delete"}
        okType="danger"
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to delete the selected videos?</p>
      </Modal>
      <Badge count={urlForDelete.length}>
        <Button danger onClick={showModal}>
          Delete Selected
        </Button>
      </Badge>
    </>
  );
};

export default VideoDeleteSelected;
