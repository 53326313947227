import { CheckCircleOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Form,
  Image,
  Input,
  Modal,
  notification,
  PageHeader,
  Space,
  Typography,
} from "antd";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { getActualPath } from "../../components/common/formItems/CustomImageUpload";
import LoadingSpinner from "../../components/common/loadingSpinner";
import NoDataFound from "../../components/common/noDataFound";
import ResponseForm from "../../components/response/responseForm";
import Organization from "../../redux/models/organization/organization";
import Question from "../../redux/models/questions/questions";
import Responses from "../../redux/models/responses/responses";
import K from "../../utilities/constants";
import { getPublicFileFromServerUrl, setSassFileColors } from "../../utilities/generalUtility";
import ResLogo from "../../assets/images/Megilla_logo.png";
const { Link, Text } = Typography;

export default function SubmitResponse() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [roomData, setRoomData] = useState({});
  const [showBranding, setShowBranding] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { id } = useParams();
  const { search } = useLocation();
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const shouldLoop = qs.parse(search, { parseBooleans: true }).loop;
  const hideHeader = qs.parse(search, { parseBooleans: true }).hideHeader;
  const [coppaComplianceModalVisibility, setCoppaComplianceModalVisibility] = useState(false);
  const [coppaComplianceModalFormVisibility, setCoppaComplianceModalFormVisibility] = useState(false);
  const [coppaComplianceValues, setCoppaComplianceValues] = useState();
  const [progressBarColor, setProgressBarColor] = useState("#ffffff");
  const [progress, setProgress] = useState(0);
  const [thankuPageObj, setThankuPageObj] = useState({
    thankyouHeading: "Thank you for your video!",
    thankyouDescription: "",
    buttonText: "Thanks!",
    pictureUrl: "",
  });

  useEffect(() => {
    fetch();
  }, []);

  const reloadComponent = () => {
    window.location.reload();
  };

  const getBrandingSettings = (roomData, organizationData) => {
    const {
      headerColor: hc,
      headerFontColor: hfc,
      buttonColor: bc,
      buttonFontColor: bfc,
      primaryFontColor: pfc,
      fontFamily: ff,
      // pictureUrl: pu,
      customOrgLogoUrl: pu,
    } = roomData;

    const { headerColor, headerFontColor, buttonColor, buttonFontColor, primaryFontColor, fontFamily, pictureUrl } =
      organizationData;

    const returnObj = {
      headerColor: hc || headerColor,
      headerFontColor: hfc || headerFontColor,
      buttonColor: bc || buttonColor,
      buttonFontColor: bfc || buttonFontColor,
      primaryFontColor: pfc || primaryFontColor,
      fontFamily: ff || fontFamily,
      pictureUrl: pu || pictureUrl,
    };

    return returnObj;

    // return {headerColor : roomData['headerColor'] || organizationData['headerColor']}
  };
  const getThankYouData = (roomData, organizationData) => {
    const { thankyouHeading: tyh, thankyouDescription: tyd, buttonText: bt } = roomData;
    const { thankyouHeading, thankyouDescription, buttonText } = organizationData;

    return {
      thankyouHeading: tyh || thankyouHeading,
      thankyouDescription: tyd || thankyouDescription,
      buttonText: bt || buttonText,
    };
  };

  const fetch = async () => {
    setLoading(true);
    try {
      let res = await Question.getPublicQuestion(id);
      let colorsRes = await Organization.getOrganizationColor();
      let orgThankyouPageSettings = await Organization.getOrganizationThankyouPageSettings();
      let roomQuestion = res.roomQuestion?.find((el) => el?.id == id);
      const room = roomQuestion?.room;
      console.log("ROOM DATA", room, "getBrandingSettings(room, colorsRes);", getBrandingSettings(room, colorsRes));
      const { headerColor, headerFontColor, buttonColor, buttonFontColor, primaryFontColor, fontFamily, pictureUrl } =
        getBrandingSettings(room, colorsRes);
      setSassFileColors(headerColor, headerFontColor, buttonColor, buttonFontColor, primaryFontColor, fontFamily);
      setProgressBarColor(buttonColor);
      setThankuPageObj((prev) => ({
        ...prev,
        ...getThankYouData(room, orgThankyouPageSettings),
        pictureUrl,
      }));

      if (res.isCOPPACompliance) {
        setCoppaComplianceModalVisibility(true);
      }
      setData(res);
      setRoomData(room);
      setShowBranding(res.organization.isMegillaBrandingEnabled);
    } catch (e) {
      console.error(e);
      setIsError(true);
      history.push("/not-found");
    }
    setLoading(false);
  };

  const onFormSubmited = async (values) => {
    values = {
      ...values,
      file: values.file.file,
      duration: values.file.duration,
      COPPACompliance: coppaComplianceValues && JSON.stringify(coppaComplianceValues),
    };
    // values.questionId = id;

    delete values.termsandconditions;
    delete values.agreement;

    if (!values.customFields) {
      values.customFields = [];
    }
    values.customFields = JSON.stringify(values.customFields);

    let bodyFormData = new FormData();

    for (let index = 0; index < Object.keys(values).length; index++) {
      bodyFormData.append(Object.keys(values)[index], values[Object.keys(values)[index]]);
    }
    setSubmitLoading(true);
    notification.info({
      message: "Please wait while the video uploads.",
      description: "Uploads get cancelled once the tab or browser is closed.",
    });
    try {
      await Responses.addResponse(id, bodyFormData, onProgress);
      notification.success({
        message: "Video uploaded successfully.",
      });
      setIsSubmitted(true);
      if (shouldLoop) {
        setTimeout(() => {
          reloadComponent();
        }, K.KIOSK_MODE_RELOAD_TIMEOUT);
      }
    } catch (e) {
      console.error(e);
      notification.error({
        message: "Failed to upload video",
        description: "Try submitting again",
      });
    }
    setSubmitLoading(false);
  };

  const onFinish = () => {
    if (shouldLoop) {
      reloadComponent();
    } else if (roomData?.homepageUrl || data.organization.homepageUrl) {
      let url = roomData?.homepageUrl || data.organization.homepageUrl;
      window.open(url.substring(0, 4) == "http" ? url : "https://" + url, "_self");
    } else {
      window.open("https://megilla.com", "_self");
    }
  };

  const onProgress = ({ loaded, total }) => {
    setProgress(((loaded / total) * 100).toFixed(0));
  };

  const onCoppaComplianceSubmition = (values) => {
    setCoppaComplianceValues(values);
    setCoppaComplianceModalFormVisibility(false);
  };

  const returnDocumentLink = (roomLink, organizationLink) => {
    if (roomLink) {
      return `${getActualPath("/upload-images")}/${roomLink}`;
    } else if (organizationLink) {
      return getActualPath("/upload-images/pdf/") + organizationLink;
    } else {
      return getPublicFileFromServerUrl("Megilla - Terms of Service.pdf");
    }
  };

  return (
    <>
      <div className="submit-responsive-wrapper custom-fontFamily">
        {/* <CustomPageHeader title="Response" /> */}
        {loading ? (
          <LoadingSpinner />
        ) : isError ? (
          <NoDataFound description="No such question" />
        ) : isSubmitted ? (
          <Card className="sr-success-message text-center">
            {thankuPageObj?.pictureUrl ? (
              <Image
                preview={false}
                width={100}
                src={`${K.Network.URL.BaseAPI}/organization/uploads/logo/${thankuPageObj.pictureUrl}`}
              />
            ) : (
              <CheckCircleOutlined className="success-message-icon" />
            )}
            <h3>{thankuPageObj?.thankyouHeading || "Thank you for your video!"}</h3>
            {thankuPageObj.thankyouDescription && <p>{thankuPageObj.thankyouDescription}</p>}
            <Button type="primary" className="sr-success-message-btn" onClick={onFinish}>
              {thankuPageObj.buttonText || "Thanks!"}
            </Button>
          </Card>
        ) : (
          <>
            <Modal visible={coppaComplianceModalVisibility} footer={null} closable={false} centered>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <Typography.Title level={3}>Please verify that you are above the age of 12</Typography.Title>
                <Space>
                  <Button
                    type="primary"
                    className="btn-bg-theme-color"
                    // size="large"
                    style={{
                      width: 80,
                    }}
                    onClick={() => {
                      setCoppaComplianceModalVisibility(false);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    type="primary"
                    className="btn-theme-color"
                    ghost
                    // size="large"
                    style={{
                      width: 80,
                    }}
                    onClick={() => {
                      setCoppaComplianceModalVisibility(false);
                      setCoppaComplianceModalFormVisibility(true);
                    }}
                  >
                    No
                  </Button>
                </Space>
              </div>
            </Modal>
            <Modal visible={coppaComplianceModalFormVisibility} footer={null} closable={false} centered>
              <Typography.Paragraph italic>Dear Parent or Guardian:</Typography.Paragraph>
              <Typography.Paragraph>
                This video recording software is intended to increase engagement, education and community experiences.
                <Typography.Link target="_blank" href="https://www.megilla.com">
                  {" "}
                  Megilla{" "}
                </Typography.Link>{" "}
                is a software technology company providing this easy-to-use video software empowering organizations to
                share prompt-based video responses. This is a fun, modern, meaningful way for your child to engage with
                class, entertainment and community, we are asking your permission for your child to record video using
                this software.
              </Typography.Paragraph>
              <Typography.Paragraph>
                Please review Megilla’s{" "}
                <Typography.Link target="_blank" href={returnDocumentLink(roomData?.pdfUrl, data.organization?.pdfUrl)}>
                  Terms of Use{" "}
                </Typography.Link>
                and
                <Typography.Link
                  target="_blank"
                  href={returnDocumentLink(roomData?.privacyPolicyPdfUrl, data.organization?.privacyPolicyPdfUrl)}
                >
                  {" "}
                  Privacy Policy
                </Typography.Link>
                . Please read these policies and complete the signature below expressing permission. If you do not agree
                to provide consent for your child to participate, please do not let them use the platform.
              </Typography.Paragraph>
              <Typography.Paragraph>Thank you.</Typography.Paragraph>
              <Form layout="vertical" onFinish={onCoppaComplianceSubmition}>
                <Form.Item
                  name="parentName"
                  label="Parent's name"
                  rules={[
                    {
                      required: true,
                      message: "Parent's name is required.",
                    },
                  ]}
                >
                  <Input placeholder="Parent's Name" />
                </Form.Item>
                <Form.Item
                  name="childName"
                  rules={[
                    {
                      required: true,
                      message: "Child's name is required.",
                    },
                  ]}
                  label="Child's name"
                >
                  <Input placeholder="Child's Name" />
                </Form.Item>
                <Form.Item
                  name="isAcceptPrintedSignature"
                  rules={[
                    {
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error("Please accept terms and conditions.")),
                    },
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox>Accepted Printed Signature</Checkbox>
                </Form.Item>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Button type="primary" htmlType="submit" className="btn-bg-theme-color">
                    Continue
                  </Button>
                </div>
              </Form>
            </Modal>
            <Card
              className="submit-responsive-card"
              title={
                hideHeader ? (
                  ""
                ) : (
                  <div className="mph-content">
                    <div className="mph-logo">
                      <div className="img-wrap">
                        <img
                          src={
                            data?.roomQuestion[0]?.room?.customOrgLogoUrl
                              ? `${K.Network.URL.BaseAPI}/organization/uploads/logo/${data?.roomQuestion[0]?.room?.customOrgLogoUrl}`
                              : `${K.Network.URL.BaseAPI}/organization/uploads/logo/${data.organization.pictureUrl}`
                          }
                        />
                      </div>
                      <div className="mph-logo-header">
                        <h2 className=" mb-0">
                          {data?.roomQuestion[0]?.room?.customOrgName
                            ? data?.roomQuestion[0]?.room?.customOrgName
                            : data.organization.organizationName}
                        </h2>
                        {data?.roomQuestion[0]?.room?.customOrgTagLine ? (
                          data.organization.tagLine !== "" ? (
                            <p className="mb-0">{data?.roomQuestion[0]?.room?.customOrgTagLine}</p>
                          ) : null
                        ) : data.organization.tagLine !== "" ? (
                          <p className="mb-0">{data.organization.tagLine}</p>
                        ) : null}
                      </div>
                    </div>

                    <div>
                      <p className="bold">
                        {data?.roomQuestion[0]?.room?.customOrgLongDescription
                          ? data?.roomQuestion[0]?.room?.customOrgLongDescription
                          : data.organization.longDescription}
                      </p>
                    </div>
                  </div>
                )
              }
            >
              <Alert
                description={<div dangerouslySetInnerHTML={{ __html: data.description }} />}
                type="info"
                showIcon
              />
              {/* <p>
                <div dangerouslySetInnerHTML={{ __html: data.description }} />
              </p> */}
              <div className="sr-question-content">
                <h2>Question</h2>
                <h4>{data.statement}</h4>
              </div>
              <div className="">
                <div className="sr-form">
                  <ResponseForm
                    returnDocumentLink={returnDocumentLink}
                    data={data}
                    roomData={roomData}
                    onFinish={onFormSubmited}
                    progress={progress}
                    progressBarColor={progressBarColor}
                    loading={submitLoading}
                  />
                </div>
              </div>
            </Card>
            {showBranding && (
              <div className=" desktop-copyright res-ftr d-flex w-100 align-items-center justify-content-center">
                <Text className="custom-fontFamily">Powered by</Text>
                <Link href="https://www.megilla.com" target="_blank">
                  <Image src={ResLogo} preview={false} style={{ width: "70px" }} />
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
