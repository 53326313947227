import { Button, Checkbox, Form, Input, message } from "antd";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import User from "../../redux/models/user/user";
import { deleteQueryParam, redirectToUrl, setFieldErrorsFromServer } from "../../utilities/generalUtility";

var md5 = require("md5");

export default function Login() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const paramJson = qs.parse(location.search, { ignoreQueryPrefix: true });
  useEffect(() => {
    if (paramJson.err) {
      message.error(paramJson.err);
      deleteQueryParam("err");
    }
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    let encryptedPass = md5(values.password);
    try {
      let user = await dispatch(User.loginCall(values.email, encryptedPass, values.remember));
      const { from } = location.state || { from: { path: "/" } };

      user.type === "SuperAdmin"
        ? redirectToUrl(user?.organization?.domainPrefix, "/organizations")
        : redirectToUrl(user?.organization?.domainPrefix, "/");
    } catch (error) {
      console.log(error);
      if (error.message) message.error(error.message);
      setFieldErrorsFromServer(error, form, values);
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <React.Fragment>
      <div className="guest-layout">
        <div className="gl-banner">
          <img src={logo} alt="" className="gl-logo" />
        </div>
        <div className="gl-form">
          <h2>Login!</h2>
          {/* <p>To begin this journey, tell us what type of account you’d be opening.</p> */}

          <Form
            requiredMark={false}
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item
              name="email"
              label="User name or email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input type="email" placeholder="Email" size="large" className="login-form-email-input" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
                {
                  min: 4,
                  max: 100,
                },
              ]}
            >
              <Input.Password placeholder="Password" size="large" />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Link to="/forgot-password" className="float-right" href="">
                Forgot password
              </Link>
            </Form.Item>
            <Form.Item className="mb-0">
              <Button
                loading={loading}
                block
                size="large"
                type="primary"
                htmlType="submit"
                className="login-continue-btn"
              >
                Continue
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}
