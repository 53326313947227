import "antd/dist/antd.less";
import "antd/lib/style/themes/default.less";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./app/App";
import store from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import "./styles.scss";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import K from "./utilities/constants";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: K.SentryDsnKey,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,

    beforeSend(event, hint) {
      // filter out UnhandledRejection errors that have no information
      if (
        event !== undefined &&
        event.exception !== undefined &&
        event.exception.values !== undefined &&
        event.exception.values.length == 1
      ) {
        var e = event.exception.values[0];
        if (
          e.type === "UnhandledRejection" &&
          e.value === "Non-Error promise rejection captured with value: Object Not Found Matching Id:3"
        ) {
          return null;
        }
      }
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
