import { Typography } from "antd";
import React, { useState } from "react";

const { Paragraph, Link } = Typography;

const EllipsisWrapper = ({ children, ellipsisProps = {} }) => {
  const [expand, setExpand] = useState({
    key: 0,
    expanded: false,
  });

  return (
    <Paragraph
      ellipsis={
        !expand.expanded
          ? {
              rows: 1,
              expandable: true,
              symbol: "more",
              onExpand: () =>
                setExpand((state) => ({
                  key: state.key + 1,
                  expanded: true,
                })),
              ...ellipsisProps,
              onEllipsis: (...props) => {
                // console.log(props);
              },
            }
          : false
      }
      className="w-100 custom-fontFamily"
      key={expand.key}
    >
      {children}{" "}
      {expand.expanded && (
        <Link
          onClick={() =>
            setExpand((state) => ({
              key: state.key + 1,
              expanded: false,
            }))
          }
        >
          less
        </Link>
      )}
    </Paragraph>
  );
};

export default EllipsisWrapper;
