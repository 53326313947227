import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialListingState = {
  pageNumber: -1,
  pageSize: 20,
  hasMore: true,
  loadingMore: false,
  initialLoading: true,
  searchValue: "",
};

const initialQuestionResponseState = {
  ...initialListingState,
  isAccepted: undefined,
};

export const listingSlice = createSlice({
  name: "listings",
  initialState: {
    organizations: initialListingState,
    rooms: {
      ...initialListingState,
      isPublic: undefined,
    },
    roomQuestions: {
      ...initialListingState,
      "tags.id": [],
      authorName: undefined,
      selectedRoom: undefined,
    },
    questionArchive: {
      ...initialListingState,
      "tags.id": [],
      authorName: undefined,
      selectedRoom: undefined,
      timeDuration: undefined,
    },
    questionResponse: {
      selectedQuestionId: null,
    },
    videoArchive: {
      ...initialListingState,
      searchValue: "",
      isAccepted: undefined,
      roomId: undefined,
      date: undefined,
    },
  },
  reducers: {
    setListingState: (state, action) => {
      let { payload } = action;
      state[payload.listing] = {
        ...state[payload.listing],
        ...payload.value,
      };
    },
    resetListingState: (state, action) => {
      let { payload } = action;
      var newState = _.cloneDeep(initialListingState);
      if (payload.ignore) {
        for (let key of payload.ignore) {
          delete newState[key];
        }
      }
      state[payload.listing] = {
        ...state[payload.listing],
        ...newState,
      };
    },
    createNewQuestionResponseSlice: (state, action) => {
      state.questionResponse[action.payload.questionId] = initialQuestionResponseState;
    },
    setQuestionResponseListingState: (state, action) => {
      let { payload } = action;
      state.questionResponse[payload.listing] = {
        ...state.questionResponse[payload.listing],
        ...payload.value,
      };
    },
    resetPaginationStateQuestionResponse: (state, action) => {
      let { payload } = action;
      state.questionResponse[payload] = {
        ...state.questionResponse[payload],
        pageNumber: initialListingState.pageNumber,
        pageSize: initialListingState.pageSize,
        hasMore: initialListingState.hasMore,
        loadingMore: initialListingState.loadingMore,
        initialLoading: initialListingState.initialLoading,
      };
    },
    resetVideoFilters: (state) => {
      state.videoArchive = {
        ...initialListingState,
        searchValue: "",
        isAccepted: undefined,
        roomId: undefined,
        date: undefined,
      };
    },
  },
});

export const {
  setListingState,
  resetListingState,
  createNewQuestionResponseSlice,
  setQuestionResponseListingState,
  resetPaginationStateQuestionResponse,
  resetVideoFilters,
} = listingSlice.actions;

export default listingSlice.reducer;
