import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Input, message, Modal, Row, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import LoadingSpinner from "../../components/common/loadingSpinner";
import NoDataFound from "../../components/common/noDataFound";
import CustomPageHeader from "../../components/common/pageHeader";
import RoomActions from "../../components/common/roomActions";
import RoomPageHeaderTitle from "../../components/common/roomPageHeaderTitle";
import RoomHeader from "../../components/landingPage/roomHeader";
import CreateQuestion from "../../components/questions/createQuestions";
import QuestionList from "../../components/room/questionList";
import QuestionResponseFilters from "../../components/room/questionResponseFilters";
import ResponseGrid from "../../components/room/responsesGrid";
import { setAttribute } from "../../redux/models/baseModel/baseActions";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import QuestionResponse from "../../redux/models/questionResponse/questionResponse";
import Question from "../../redux/models/questions/questions";
import Rooms from "../../redux/models/rooms/rooms";
import { setListingState } from "../../redux/slice/listings";
import { getBaseClientUrl } from "../../utilities/generalUtility";
import Request from "../../network/request";

const { Option } = Select;

const { Meta } = Card;
//this is filter to filter data on the basis of we search on input words in video
var filterFlag = false;
export default function RoomPage(props) {
  const { roomId, questionId, hideQuestions } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const roomListing = useSelector((state) => state.listings[Question.listingSliceName]);
  const questions = useSelector((state) => getSelector(Question)(state));
  const [isEmpty, setIsEmpty] = useState(false);
  const [room, setRoom] = useState();
  const [roomExists, setRoomExists] = useState(true);
  const history = useHistory();
  const [switchLoading, setSwitchLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [videoResponseIds, setVideoResponseIds] = useState([]);
  const [wordSearchValue, setWordSearchValue] = useState("");
  const [sentimentSearchValue, setSentimentSearchValue] = useState([]);

  const onSentimentSearchSelect = (val) => {
    setSentimentSearchValue(val);
  };

  const onSearchChange = ({ target }) => {
    if (!target.value) {
      filterFlag = false;
    }
    setSearchValue(target.value);
  };

  const onSearch = async () => {
    if (!searchValue) return;
    setSearchLoading(true);

    const searchWords = searchValue.split(",");
    const simpleQuery = searchWords.reduce((prev, curr) => {
      prev = !prev
        ? `filecontent:'${curr}' (prefix  field=filecontent '${curr}')`
        : prev + `filecontent:'${curr}' (prefix  field=filecontent '${curr}')`;
      return prev;
    }, "");

    try {
      const response = await Request.getCloudSearchResponse({
        params: {
          q: `(and questionid:${questionId} (or ${simpleQuery}))`,
          "q.parser": "structured",

          return: "videourl",
          size: 100,
        },
      });

      setSearchLoading(false);
      filterFlag = true;
      const hits = response?.data?.hits?.hit?.map((el) => el?.fields?.videourl)?.filter(Boolean);
      setVideoResponseIds(hits);
      // setSearchResults(response.data);
    } catch (error) {
      message.error("Api faild to fetch results from cloud search");
      setSearchLoading(false);
    }
  };

  const { Search } = Input;

  const dispatch = useDispatch();
  const { confirm } = Modal;

  const responsesList = useSelector((state) =>
    getSelector(QuestionResponse)(state)
      .filter((el) => el.questionId == questionId)
      .map((el) => el.id)
  );

  useEffect(() => {
    fetchData();

    return () => {
      filterFlag = false;
    };
  }, []);

  useEffect(() => {
    if (!questions.length == 0 && !questionId) {
      setInitialSelection();
    }
  }, [questions]);

  const setInitialSelection = () => {
    if (questions.length == 0) {
      history.replace("/rooms/" + hideQuestions + "/" + roomId);
      setIsEmpty(true);
    } else {
      if (!questionId || !questions.find((el) => el.id == questionId)) {
        history.replace("/rooms/" + hideQuestions + "/" + roomId + "/" + questions[0].id);
      }
      setIsEmpty(false);
    }
  };

  async function fetchData() {
    dispatch(
      setListingState({
        listing: "roomQuestions",
        value: {
          selectedRoom: roomId,
        },
      })
    );
    setIsLoading(true);
    try {
      let room = await dispatch(Rooms.getRoomWithId(roomId));
      setRoom(room);
      await dispatch(
        Question.getAll({
          clear: true,
        })
      );
    } catch (e) {
      message.error("Failed to load data.");
      setRoomExists(false);
    }
    setIsLoading(false);
  }
  const selectedQuestion = () => {
    const question = questions?.filter((question) => question.id == questionId);
    return question[0]?.statement;
  };

  const viewLandingPage = () => {
    let win = window.open(getBaseClientUrl() + "/landing-page/" + roomId, "_blank");
    win.focus();
  };

  const viewSlidePage = () => {
    let win = window.open(getBaseClientUrl() + "/slide-page/" + roomId + "/" + questionId);
    win.focus();
  };

  const changePublishedState = async () => {
    setSwitchLoading(true);
    let resStatus = await dispatch(Rooms.changePublishedState(room.id, !room.isPublic, false));
    if (resStatus) {
      let newState = !room.isPublic;
      setRoom((state) => ({
        ...state,
        isPublic: newState,
      }));
      dispatch(setAttribute(Rooms, room.id, "isPublic", newState));
    }
    setSwitchLoading(false);
  };

  const sentimentList = [
    { id: 1, key: "Positive", value: "POSITIVE" },
    { id: 2, key: "Negative", value: "NEGATIVE" },
    { id: 3, key: "Neutral", value: "NEUTRAL" },
    { id: 4, key: "Mixed", value: "MIXED" },
  ];

  const onWordSearch = (val) => {
    setWordSearchValue(val);
  };

  const handleSentimentSelect = (selectedValues) => {
    setSentimentSearchValue(selectedValues);
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : !roomExists ? (
    <>
      <CustomPageHeader title="No project found" />
      <NoDataFound description={"Project does not exist."} />
    </>
  ) : (
    <>
      <CustomPageHeader
        title={<RoomPageHeaderTitle room={room} title="Project" />}
        actions={[<RoomActions room={room} showNavigateToRoom={false} />]}
        breakToNewLine={true}
      />
      <div className="mt-4">
        {/* <img src={headerImage} alt="meggila-b" className="w-100" /> */}
        <RoomHeader name={room?.name} description={room?.description} pictureUrl={room?.pictureUrl} />
      </div>
      <div className="content-area mt-0">
        <div className="rooms-details">
          {isEmpty ? (
            <NoDataFound
              description={"Project contains no questions. Please add new questions by clicking the button below."}
              actions={[<CreateQuestion roomId={roomId} />]}
            />
          ) : (
            <Row gutter={32}>
              {!(hideQuestions === "y") && (
                <Col span={24} lg={8}>
                  <QuestionList roomId={roomId} roomListing={roomListing} questions={questions} />
                </Col>
              )}
              {/* TODO this code worck in fine */}
              <Col
                span={!(hideQuestions === "y") ? 24 : 24}
                lg={!(hideQuestions === "y") ? 16 : 24}
                className="question-videos-list"
              >
                <div className="question-videos-header">
                  <div className="qvh-switch">
                    <h6 className="mr-3 mb-0">
                      <span className="mr-0"> {room?.isPublic ? "Published" : "Unpublished"} </span>
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={room?.isPublic}
                        onChange={changePublishedState}
                        loading={switchLoading}
                      />
                    </h6>
                    <Button
                      type="primary"
                      ghost
                      disabled={responsesList.length === 0}
                      onClick={viewSlidePage}
                      style={{ marginRight: "8px" }}
                    >
                      View Slide Page
                    </Button>
                    <Button className="landing-page-btn" onClick={viewLandingPage}>
                      View Landing Page
                    </Button>
                  </div>
                  <div className="qvh-title">
                    <h5> Question </h5>
                    {questionId && <h3>{selectedQuestion()}</h3>}
                  </div>
                </div>
                <QuestionResponseFilters questionId={questionId} />

                <>
                  {/* <p>Search on specific words mentioned in the video</p> */}
                  <Row gutter={[20, 20]}>
                    <Col xs={24} sm={24} md={12}>
                      <Select
                        showSearch
                        className=" mb-4 w-100"
                        placeholder={"Select Sentiment Analysis"}
                        mode="multiple"
                        showArrow
                        onChange={handleSentimentSelect}
                        value={sentimentSearchValue}
                        optionFilterProp="children"
                        // enterButton="Search"
                      >
                        {sentimentList.map((list) => (
                          <Option key={list.id} value={list.value}>
                            {list.key}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Search
                        className=" mb-4 w-100"
                        placeholder="Search on Words"
                        // allowClear
                        enterButton="Search"
                        onSearch={onWordSearch}
                        // onChange={onSearchChange}
                        // value={searchValue}
                      />
                    </Col>
                  </Row>
                </>

                <ResponseGrid
                  filterFlag={filterFlag}
                  searchLoading={searchLoading}
                  searchValue={searchValue}
                  responseVideoIds={videoResponseIds}
                  questionId={questionId}
                  roomId={roomId}
                  wordSearchValue={wordSearchValue}
                  sentimentSearchValue={sentimentSearchValue}
                />
                <Divider />
              </Col>
            </Row>
          )}
        </div>
      </div>
    </>
  );
}
