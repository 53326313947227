import { Button, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../components/common/loadingSpinner";
import QuestionArchiveFilters from "../../components/common/questionArchiveFilters";
import QuestionArchiveList from "../../components/common/questionArchiveList";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import QuestionArchive from "../../redux/models/questionArchive/questionArchive";
import Question from "../../redux/models/questions/questions";

export default function AddFromExistingQuestion({ buttonProps, roomId }) {
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const questions = useSelector((state) => getSelector(QuestionArchive)(state));
  const questionArchiveList = useSelector((state) => getSelector(QuestionArchive)(state));

  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    try {
      setIsLoading(true);
      await dispatch(QuestionArchive.getAll());

      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  }

  const addToQuestionsList = async () => {
    setLoading(true);
    try {
      await dispatch(
        Question.addExistingQuestions({
          questionIds: questions.filter((el) => el.isSelected).map((el) => el.id),
          roomId: roomId,
        })
      );
      setLoading(false);
      setVisibility(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <>
      <Button onClick={() => setVisibility(true)} type="primary" ghost {...buttonProps}>
        Add Existing Question
      </Button>
      <Modal
        className="questions-list"
        title={<h3 className="question-list-heading">Add from Existing Questions</h3>}
        visible={visibility}
        // onOk={() => addToQuestionsList()}
        onCancel={() => setVisibility(false)}
        footer={false}
        width={1000}
        centered
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <QuestionArchiveFilters model={QuestionArchive} />
            <QuestionArchiveList data={questionArchiveList} model={QuestionArchive} check={true} />
            <div className="questions-list-footer">
              <Button size="large" onClick={() => setVisibility(false)}>
                Close
              </Button>
              <Button
                loading={loading}
                type="primary"
                onClick={() => addToQuestionsList()}
                size="large"
                htmlType="submit"
              >
                Add
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}
