import NetworkCall from "../../../network/networkCall";
import Request from "../../../network/request";
import { resetListingState, setListingState } from "../../slice/listings";
import { deleteAllAndUpsertModels, deleteModel, upsertModel, upsertModels } from "../baseModel/baseActions";
import BaseModel from "../baseModel/baseModel";
import baseReducer from "../baseModel/baseReducer";

export default class Organization extends BaseModel {
  // API call using thunk.
  static getAll({ clear = false } = {}) {
    return async (dispatch, getState) => {
      if (clear) {
        dispatch(
          resetListingState({
            listing: this.listingSliceName,
            ignore: ["searchValue"],
          })
        );
      }
      let sliceState = getState().listings[this.listingSliceName];

      let newPageNumber = sliceState.pageNumber + 1;
      let organizationName = sliceState.searchValue;
      dispatch(
        setListingState({
          listing: this.listingSliceName,
          value: {
            loadingMore: true,
            pageNumber: newPageNumber,
          },
        })
      );
      try {
        const orgList = await NetworkCall.fetch(
          Request.organizationsList({
            organizationName,
            pageNumber: newPageNumber,
            recordsPerPage: sliceState.pageSize,
          })
        );
        dispatch(clear ? deleteAllAndUpsertModels(Organization, orgList) : upsertModels(Organization, orgList));
        dispatch(
          setListingState({
            listing: this.listingSliceName,
            value: {
              initialLoading: false,
              loadingMore: false,
              hasMore: orgList.length == sliceState.pageSize,
            },
          })
        );
        return true;
      } catch (e) {
        dispatch(
          setListingState({
            listing: this.listingSliceName,
            value: {
              initialLoading: false,
              loadingMore: false,
              hasMore: false,
            },
          })
        );
        return false;
      }
    };
  }
  /* get organiztion with id */
  static getOrganizationWithId(orgId) {
    return async (dispatch) => {
      const organization = await NetworkCall.fetch(Request.organizationWithId(orgId));
      return organization;
    };
  }
  /* get organization color */
  static async getOrganizationColor() {
    const res = await NetworkCall.fetch(Request.getOranizationColors());
    return res;
  }
  static async getOrganizationThankyouPageSettings() {
    const orgThankyouPageSettings = await NetworkCall.fetch(Request.getOrganizationThankyouPageSettings());
    return orgThankyouPageSettings;
  }
  static organizationSettings(settingValues) {
    return async (dispatch) => {
      const organizationSettings = await NetworkCall.fetch(Request.organizationSettings(settingValues));
      return organizationSettings;
    };
  }
  static getOrganizationSettings() {
    return async (dispatch) => {
      const organizationSettings = await NetworkCall.fetch(Request.getOrganizationSettings());
      return organizationSettings;
    };
  }
  /* Create organiztion */
  static createOrganization(organizationData) {
    return async (dispatch) => {
      const organization = await NetworkCall.fetch(Request.createOrganization(organizationData));
      dispatch(
        upsertModel(Organization, {
          ...organization,
          pullToTop: -1,
        })
      );
      return organization;
    };
  }
  /* Upload organization logo*/
  static uploadOrganizationLogo(file) {
    return async (dispatch) => {
      const imageUploadedResponse = await NetworkCall.fetch(Request.uploadOrganizationLogo(file));

      return imageUploadedResponse;
    };
  }

  static resizeOrganizationLogo(oldImage, resizeImageName) {
    return async () => {
      const resizedImageResponse = await NetworkCall.fetch(Request.resizeOrganizationLogo(oldImage, resizeImageName));
      return resizedImageResponse;
    };
  }

  static deleteResizeOrganizationLogo(imageName) {
    return async () => {
      const resizedImageResponse = await NetworkCall.fetch(Request.deleteResizeOrganizationLogo(imageName));
      return resizedImageResponse;
    };
  }

  static async uploadOrganizationTermNConditions(file) {
    const fileUploadedResponse = await NetworkCall.fetch(Request.uploadOrganizationTaC(file));
    return fileUploadedResponse;
  }
  /* Edit organiztion */
  static editOrganization(organizationData) {
    return async (dispatch) => {
      const organization = await NetworkCall.fetch(Request.editOrganization(organizationData));
      // dispatch(deleteModel(Organization, organization.id));
      dispatch(
        upsertModel(Organization, {
          ...organization,
          pullToTop: -1,
        })
      );
      return organization;
    };
  }
  /* Delete organiztion */
  static deleteOrganization(id) {
    return async (dispatch) => {
      const organization = await NetworkCall.fetch(Request.deleteOrganization(id)); // returns success message
      dispatch(deleteModel(Organization, id));
      return organization;
    };
  }

  // Reducer
  static reducer(action, Organization, session) {
    baseReducer(action, Organization, session);
  }
}

Organization.modelName = "Organization";
Organization.listingSliceName = "organizations";

// Organization.fields = {
//   // Attributes
//   id: attr(),
//   name: attr(),
//   domain_prefix: attr(),
//   custom_domain: attr(),
//   internal_description: attr(),
//   created_at: attr(),
//   updated_at: attr(),
// };
