import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../components/common/loadingSpinner";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import QuestionsAuthor from "../../redux/models/questionsAuthor/questionsAuthor";
import QuestionTags from "../../redux/models/questionTags/questionTags";
import QuestionTimeLength from "../../redux/models/questionTimeLength/questionTimeLength";
import RoomName from "../../redux/models/roomName/roomName";
import ListingSearch from "../common/listingSearch";
import ListingSelect from "../common/listingSelect";

const QuestionArchiveFilters = ({ model }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const questionsAuthor = useSelector((state) => getSelector(QuestionsAuthor)(state));
  const questionTags = useSelector((state) => getSelector(QuestionTags)(state));
  const roomName = useSelector((state) => getSelector(RoomName)(state));
  const questionTimeLength = useSelector((state) => getSelector(QuestionTimeLength)(state));

  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    try {
      setIsLoading(true);
      dispatch(QuestionsAuthor.getQuestionsAuthor());
      dispatch(QuestionTags.getAll());
      dispatch(RoomName.getRoomName());
      dispatch(QuestionTimeLength.getAll());
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  }
  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div>
      <Row gutter={(16, 16)}>
        <Col span={24} md={12} lg={8} xl={5} className="mb-3">
          <ListingSearch model={model} />
        </Col>
        <Col span={24} md={12} lg={8} xl={4} className="mb-3">
          <ListingSelect
            placeholder="Owner/Author"
            data={questionsAuthor}
            model={model}
            fieldName="authorName"
            selectValue="authorName"
            selectDisplay="authorName"
          />
        </Col>
        <Col span={24} md={12} lg={8} xl={4} className="mb-3">
          <ListingSelect
            placeholder="Project Name"
            data={roomName}
            model={model}
            fieldName="roomId"
            selectValue="id"
            selectDisplay="name"
          />
        </Col>
        <Col span={24} md={12} lg={8} xl={4} className="mb-3">
          <ListingSelect
            placeholder="Time Length"
            data={questionTimeLength}
            model={model}
            fieldName="time"
            selectValue="value"
            selectDisplay="name"
          />
        </Col>
        <Col span={24} md={19} lg={12} xl={5} className="mb-3">
          <ListingSelect
            placeholder="Tags"
            data={questionTags}
            model={model}
            fieldName="tags.id"
            mode="multiple"
            maxTagCount="responsive"
            selectValue="id"
            selectDisplay="name"
          />
        </Col>
        <Col span={24} md={5} lg={4} xl={2} className="mb-3">
          <Button
            className="w-100"
            type="primary"
            onClick={() => {
              dispatch(
                model.getAll({
                  clear: true,
                })
              );
            }}
          >
            Apply
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default QuestionArchiveFilters;
