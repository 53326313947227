import { PageHeader } from "antd";
import React from "react";
import { useHistory } from "react-router";

export default function CustomPageHeader({ title, actions = [], breakToNewLine }) {
  const history = useHistory();

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={title}
        ghost={false}
        extra={breakToNewLine ? [] : actions}
      />
      {breakToNewLine && <div className="buttonBreakToNewLine">{actions}</div>}
    </>
  );
}
