import React, { useEffect, useState } from "react";
import ImageNotFound from "../../assets/images/meggila-b.svg";

/**
 *
 * @param {string} src
 * @param {function} onClick
 * @returns react component
 */

const ImageView = ({ src, onClick, style = {} }) => {
  const [fallback, setFallback] = useState(false);

  useEffect(() => {
    setFallback(false);
  }, [src]);

  return (
    <img
      src={!fallback ? src : ImageNotFound}
      style={{
        ...style,
        backgroundColor: "#f0f2f5",
      }}
      onError={() => setFallback(true)}
      onClick={!fallback ? onClick : null}
    />
  );
};

export default ImageView;
