import Icon from "@ant-design/icons";
import React, { useState } from "react";
import { ReactComponent as template1icon } from "../../../assets/images/templateOne.svg";
import { ReactComponent as template2icon } from "../../../assets/images/templateTwo.svg";
import K from "../../../utilities/constants";
import SelectTemplatePopup from "./template";

const template1 = `${K.Network.URL.BaseUrl}/grid.jpg`;
const template2 = `${K.Network.URL.BaseUrl}/accordian.jpg`;

export default function SelectTemplate({ value, onChange }) {
  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({
    id: 1,
    template: template1,
  });

  return (
    <>
      <div
        className={value == 1 ? "select-templete active" : "select-templete"}
        onClick={
          () => {
            setIsTemplateModalVisible(true);
            setSelectedTemplate({ id: 1, template: template1 });
          } /* setTemplate(1) */
        }
      >
        {/* <LayoutOutlined className="layout-outlined-icon" /> */}
        <Icon component={template1icon} className="layout-outlined-icon" />
      </div>
      <div
        className={value == 2 ? "select-templete active" : "select-templete"}
        onClick={() => {
          setIsTemplateModalVisible(true);
          setSelectedTemplate({ id: 2, template: template2 });
          /*  setTemplate(2) */
        }}
      >
        <Icon component={template2icon} className="layout-outlined-icon" />
        {/* <LayoutOutlined className="layout-outlined-icon" /> */}
      </div>
      <SelectTemplatePopup
        isModalVisible={isTemplateModalVisible}
        onCancel={() => setIsTemplateModalVisible(false)}
        template={selectedTemplate}
        onOk={onChange}
      />
    </>
  );
}
