import { createSlice } from "@reduxjs/toolkit";

export const questionId = createSlice({
  name: "questionId",
  initialState: {
    qId: null,
  },
  reducers: {
    setSelectedQuestionId: (state, action) => {
      state.qId = action.payload;
    },
  },
});

export const { setSelectedQuestionId } = questionId.actions;

export default questionId.reducer;
