import { Col, Input, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListingView from "../../components/common/listingsView";
import CustomPageHeader from "../../components/common/pageHeader";
import VideoArchiveFilters from "../../components/videoArchive/videoArchiveFilters";
import VideoCard from "../../components/videoArchive/videoCard";
import VideoDeleteSelected from "../../components/videoArchive/videoDeleteSelected";
import VideoDownloadSelected from "../../components/videoArchive/videoDownloadSelected";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import VideoArchive from "../../redux/models/videoArchive/videoArchive";
import Request from "../../network/request";
import LoadingSpinner from "../../components/common/loadingSpinner";

var filterFlag = false;

export default function VideoArchiveComponent() {
  const [searchValue, setSearchValue] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [isFilterVideos, setIsFilterVideos] = useState(false);
  const [archiveVideosData, setArchiveVideosData] = useState([]);
  const [videoResponseIds, setVideoResponseIds] = useState([]);
  const dispatch = useDispatch();
  const { Search } = Input;
  const videoResponse = useSelector((state) => getSelector(VideoArchive)(state).map((el) => el.id));

  const getVideoArchiveRecords = useSelector((state) => getSelector(VideoArchive)(state) || {});

  const { hasMore, initialLoading, loadingMore } = useSelector(
    (state) => state.listings[VideoArchive.listingSliceName]
  );

  useEffect(() => {
    const filteredRecords = getVideoArchiveRecords?.map((el) => el.id);
    setArchiveVideosData(filteredRecords);
    // if (isFilterVideos) {
    //   const filteredRecords = getVideoArchiveRecords
    //     ?.filter((record) => videoResponseIds?.includes(record.response.videoUrl.split(".")[0]))
    //     ?.map((el) => el.id);
    //   setArchiveVideosData(filteredRecords);
    // } else {
    //   const filteredRecords = getVideoArchiveRecords?.map((el) => el.id);
    //   setArchiveVideosData(filteredRecords);
    // }
  }, [videoResponseIds?.length, getVideoArchiveRecords]);

  useEffect(() => {
    fetchInitial();

    return () => {
      filterFlag = false;
    };
  }, []);

  const fetchInitial = async () => {
    try {
      await dispatch(
        VideoArchive.getAll({
          clear: true,
        })
      );
    } catch (e) {
      console.error(e);
      message.error("Failed to load responses.");
    }
  };

  const fetchData = async () => {
    try {
      await dispatch(
        VideoArchive.getAll({
          clear: false,
        })
      );
    } catch (e) {
      console.error(e);
      message.error("Failed to load responses.");
    }
  };

  // const onSearchChange = ({ target }) => {
  //   if (!target.value) {
  //     filterFlag = false;
  //   }
  //   setSearchValue(target.value);
  // };

  // const onSearch = async () => {
  //   console.log(searchValue);
  //   if (!searchValue) {
  //     setIsFilterVideos(false);
  //     return;
  //   } else {
  //     setIsFilterVideos(true);
  //     setSearchLoading(true);

  //     const searchWords = searchValue.split(",");

  //     const simpleQuery = searchWords.reduce((prev, curr) => {
  //       prev = !prev
  //         ? `filecontent:'${curr}' (prefix  field=filecontent '${curr}')`
  //         : prev + `filecontent:'${curr}' (prefix  field=filecontent '${curr}')`;
  //       return prev;
  //     }, "");

  //     try {
  //       const response = await Request.getCloudSearchResponse({
  //         params: {
  //           q: `(or ${simpleQuery})`,
  //           "q.parser": "structured",
  //           return: "videourl",
  //           size: 100,
  //         },
  //       });
  //       console.log("resp----", response);
  //       setSearchLoading(false);
  //       filterFlag = true;
  //       const hits = response?.data?.hits?.hit?.map((el) => el?.fields?.videourl)?.filter(Boolean);
  //       console.log("hits----", hits);
  //       setVideoResponseIds(hits);
  //     } catch (error) {
  //       message.error("Api faild to fetch results from cloud search");
  //       setSearchLoading(false);
  //     }
  //   }
  // };

  return (
    <>
      <CustomPageHeader
        title={"Videos Archive"}
        actions={[<VideoDownloadSelected />, <VideoDeleteSelected refetch={fetchInitial} />]}
      />
      <div className="content-area video-acrhieve-wrap">
        <div className="va-filter-wrap">
          <VideoArchiveFilters model={VideoArchive} />
        </div>
        {/* {videoResponse?.length > 0 && (
          <>
            <p>Search on specific words mentioned in the video</p>
            <Row gutter={[20, 20]}>
              <Col xs={24} sm={24} md={12}>
                <Search
                  // {...props}
                  className=" mb-4 w-100"
                  placeholder="Search"
                  // allowClear
                  enterButton="Search"
                  onSearch={onSearch}
                  onChange={onSearchChange}
                  value={searchValue}
                />
              </Col>
            </Row>
          </>
        )} */}
        {searchLoading ? (
          <LoadingSpinner />
        ) : (
          <ListingView
            // dataSource={videoResponse}
            dataSource={archiveVideosData}
            responseVideoIds={videoResponseIds}
            isFilterVideos={isFilterVideos}
            isVideoArchiveComponent={true}
            GridCard={VideoCard}
            isGrid={true}
            loadMore={fetchData}
            hasMore={hasMore}
            initialLoading={initialLoading}
            loadingMore={loadingMore}
            noDataProps={{
              description: "No videos found",
            }}
          />
        )}
      </div>
    </>
  );
}
