import React from "react";
import { Redirect, useParams } from "react-router";
import { getBaseClientUrl } from "../../utilities/generalUtility";
import qs from "query-string";
import LoadingSpinner from "../../components/common/loadingSpinner";


const RedirectToResponse = () => {
    const { id, loop } = useParams();
    const shouldLoop = loop === "loop" ? true : false;
    const link = "/submit-response/" + id
    const newLink = qs.stringifyUrl({ url: link, query: {
        loop: shouldLoop
    } })
    return <Redirect to={newLink} />
}

export default RedirectToResponse