import React from "react";
import NoDataImage from "../../assets/images/rooms-empty.png";

const NoDataFound = ({ actions, description }) => {
  return (
    <div className="rooms-empty-card">
      <img src={NoDataImage} className="empty-rooms-img" alt="No data" />
      <p>{description}</p>
      <div>{actions}</div>
    </div>
  );
};

export default NoDataFound;
