import { Col, Row } from "antd";
import React from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import LoadingSpinner from "./loadingSpinner";
import NoDataFound from "./noDataFound";

const ListingView = ({
  loadMore = () => {},
  loadingMore = false,
  hasMore = false,
  initialLoading = false,
  dataSource = [],
  fixedHeight,
  RowCard,
  GridCard,
  isGrid,
  colSpan = {
    span: 24,
    sm: 12,
    lg: 8,
    xxl: 6,
  },
  noDataProps = {
    description: "No Data Found",
    actions: [],
  },
}) => {
  useBottomScrollListener(() => {
    if (!fixedHeight) {
      triggerLoadMore();
    }
  });

  const triggerLoadMore = () => {
    if (hasMore && !loadingMore && !initialLoading) {
      loadMore();
    }
  };

  const render = () =>
    dataSource?.length ? (
      isGrid ? (
        <Row gutter={[16, 16]}>
          {dataSource?.map((el) => (
            <Col key={el.id} {...colSpan}>
              <GridCard record={el} />
            </Col>
          ))}
        </Row>
      ) : (
        dataSource?.map((el) => (
          <div key={el.id}>
            <RowCard record={el} />
          </div>
        ))
      )
    ) : (
      <NoDataFound {...noDataProps} />
    );

  return initialLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      {fixedHeight ? (
        <PerfectScrollbar
          onYReachEnd={triggerLoadMore}
          className="perfect-scrollbar-height"
          style={{
            height: fixedHeight,
          }}
          options={{
            suppressScrollX: true,
          }}
        >
          {render()}
          {loadingMore && <LoadingSpinner size="small" />}
        </PerfectScrollbar>
      ) : (
        <>
          <div>{render()}</div>
          {loadingMore && <LoadingSpinner size="small" />}
        </>
      )}
    </>
  );
};
export default ListingView;
