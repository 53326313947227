import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MoreOutlined,
  ShareAltOutlined,
  UnorderedListOutlined,
  BellOutlined
} from "@ant-design/icons";
import { Button, Card, Dropdown, Menu, message, Modal, Switch, Badge, Tooltip } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Rooms from "../../redux/models/rooms/rooms";
import { getBaseClientUrl } from "../../utilities/generalUtility";
import EllipsisWrapper from "../common/ellipsesWrapper";
import { getActualPath } from "../common/formItems/CustomImageUpload";
import ImageView from "../common/imageView";
import ShareModal from "../common/shareModal";

const RoomCard = ({ record: room }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [switchLoading, setSwitchLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const { confirm } = Modal;

  const navigateToRoom = () => {
    history.push(`/rooms/n/${room.id}`);
  };
  const navigateToEditRoom = () => {
    history.push(`/rooms/edit/${room.id}`);
  };
  const navigateToRoomQuestionsList = () => {
    history.push(`/rooms/dashboard/${room.id}`);
  };
  const onShareClick = () => {
    setShowShareModal(true);
  };
  const removeRoom = async (roomId) => {
    setDeleteLoading(true);
    try {
      await dispatch(Rooms.deleteRoom(roomId));
    } catch (e) {
      alert(e.message);
    }
    setDeleteLoading(false);
    message.success("Room deleted successfully");
  };

  function deleteConfirmRoom(roomId, roomName) {
    confirm({
      title: `Do you want to delete ${roomName} room?`,
      icon: <ExclamationCircleOutlined />,
      content: "This action is permanent and you will not be able to undo this.",
      onOk: () => removeRoom(roomId),
      onCancel() {},
      centered: true,
      okButtonProps: {
        loading: deleteLoading,
      },
    });
  }

  const changePublishedState = () => {
    setSwitchLoading(true);
    dispatch(Rooms.changePublishedState(room.id, !room.isPublic));
    setSwitchLoading(false);
  };
  const topCardNavDrop = (
    <Menu>
      <Menu.Item onClick={() => deleteConfirmRoom(room.id, room.name)}>
        <DeleteOutlined /> Delete
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          dispatch(Rooms.cloneRoom(room.id));
        }}
      >
        <CopyOutlined /> Make Copy
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <ShareModal
        link={getBaseClientUrl() + "/landing-page/" + room.id}
        slideShowLink={getBaseClientUrl() + "/slide-page/" + room.id}
        display={showShareModal}
        onClose={() => setShowShareModal(false)}
        buttonProps={{
          icon: <ShareAltOutlined className="ant-icon" />,
          type: "link",
          hidden: true,
        }}
        buttonText=""
        text={
          <>
            Generate link for <span className="text-primary">Projects</span>
          </>
        }
      />
      <Card
        className="card-actions-wrap room-card"
        actions={[
          <div onClick={navigateToRoom}>
            <span className="d-flex justify-content-center">
              <EyeOutlined />{" "}
            </span>
            <span className="btn-text"> VIEW </span>{" "}
          </div>,
          <div onClick={navigateToRoomQuestionsList}>
            <span className="d-flex justify-content-center">
              <UnorderedListOutlined />
            </span>{" "}
            <span className="btn-text">QUESTIONS </span>{" "}
          </div>,
          <div onClick={navigateToEditRoom}>
            <span className="d-flex justify-content-center">
              <EditOutlined />{" "}
            </span>
            <span className="btn-text"> EDIT </span>{" "}
          </div>,

          <div onClick={onShareClick}>
            <span className="d-flex justify-content-center">
              <ShareAltOutlined />
            </span>
            <span className="btn-text"> SHARE </span>{" "}
          </div>,
        ]}
        cover={
          <div className="card-image">
            <ImageView src={room.pictureUrl ? getActualPath("/upload-images/") + room.pictureUrl : ""} />
          </div>
        }
      >
         {(+room?.newResponse > 0) &&
          (
            <Tooltip title={`${+room?.newResponse} new video${+room?.newResponse > 1 ? "s" : ""}`}> 
          <div className="room-card-new-video-icon" >
            {+room?.newResponse}
          </div>
        </Tooltip>
            )}
        <div
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            zIndex: 100,
          }}
        >
       
          <Dropdown overlay={topCardNavDrop} placement="bottomRight" arrow>
            <Button icon={<MoreOutlined />} shape="circle" type="primary" size="small" />
          </Dropdown>
        </div>
        <div className="card-title-icon">
          <div className="card-title">{room.name}</div>
        </div>
        <div className="rc-description">
          <EllipsisWrapper ellipsisProps={{ rows: 2, expandable: false }}>{room.description}</EllipsisWrapper>
        </div>

        <div className="switch-or-tage" dir="rtl">
          {room.isPublic ? <p className="success mb-0">Published</p> : <p className="mb-0">Unpublished</p>}
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={room.isPublic}
            onChange={changePublishedState}
            loading={switchLoading}
          />
        </div>
      </Card>
    </>
  );
};

export default RoomCard;
