import { InfoCircleOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Switch, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import md5 from "md5";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import CustomImageUpload from "../../components/common/formItems/CustomImageUpload";
import LoadingSpinner from "../../components/common/loadingSpinner";
import CustomPageHeader from "../../components/common/pageHeader";
import Organization from "../../redux/models/organization/organization";
import K from "../../utilities/constants";

export default function CreateNewOrganization({ isModalVisible, ...props }) {
  const history = useHistory();
  const [organizationData, setOrganizationData] = useState(null);
  const deletedUsers = [];
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  let isEdit = id ? true : false;
  /* fetch organization data in edit mode */

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      //getting client if id exists
      if (id) {
        setIsLoading(true);
        let organizationGet = await dispatch(Organization.getOrganizationWithId(id));
        let organizationToEdit = {
          pictureUrl: organizationGet?.pictureUrl,
          customDomain: organizationGet?.customDomain,
          organizationName: organizationGet?.organizationName,
          domainPrefix: organizationGet?.domainPrefix,
          internalDescription: organizationGet?.internalDescription,
          organizationId: organizationGet?.id,
          isMegillaBrandingEnabled: organizationGet?.isMegillaBrandingEnabled ?? false,
          user: organizationGet?.user ?? [],
        };
        setOrganizationData(organizationToEdit);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  /* Create/edit organization */
  const onCreateEditOrganization = async (values) => {
    setSubmitLoading(true);
    let user = values.user.map((user) => ({
      ...user,
      password: user.password ? md5(user.password) : "",
    }));

    try {
      let reqBody = {
        ...values,
        user,
        Deleteduser: deletedUsers,
      };
      if (isEdit) {
        reqBody.organizationId = organizationData.organizationId;
        reqBody.userId = organizationData.userId;
        await dispatch(Organization.editOrganization(reqBody));
      } else {
        await dispatch(Organization.createOrganization(reqBody));
      }
      setSubmitLoading(false);
      history.push("/organizations");
    } catch (error) {
      // setFieldErrorsFromServer(error, form, formValues);
      setSubmitLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {};
  const initialOrganizationData = isEdit
    ? organizationData
    : {
        ...organizationData,
        isMegillaBrandingEnabled: false,
        user: [{}],
      };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <CustomPageHeader title={isEdit ? organizationData?.organizationName : "Create an Organization"} />
      <div div className="form-container content-area organization">
        <div className="container-card">
          <Form
            hideRequiredMark={true}
            layout="vertical"
            initialValues={initialOrganizationData}
            // name="createRoom"
            onFinish={onCreateEditOrganization}
            onFinishFailed={onFinishFailed}
          >
            <h3 className="pb-3">{isEdit ? t("Edit Organization") : t("Create Organization")}</h3>
            <h5>{!isEdit && t("Please fill in the details to create an organization")}</h5>
            <Row gutter={[16, 0]}>
              <Col span={24} md={12}>
                <Form.Item
                  name="pictureUrl"
                  label="Upload File"
                  tooltip={{ title: "Tooltip with customize icon", icon: <InfoCircleOutlined /> }}
                >
                  <CustomImageUpload
                    onUpload={(image) => dispatch(Organization.uploadOrganizationLogo(image))}
                    path={"/organization/uploads/logo"}
                    aspectRatio={K.imageAspectRatios.organizationImage}
                  />
                </Form.Item>
              </Col>

              <Col span={24} md={12}>
                <Row gutter={[16, 0]}>
                  <Col span={24}>
                    <Form.Item
                      name="organizationName"
                      label={t("Organization Name")}
                      rules={[
                        {
                          required: true,
                          message: "Please add organization name!",
                        },
                      ]}
                    >
                      <Input placeholder="Organization Name" className="megilla-input" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="domainPrefix"
                      label={t("Organization Domain Prefix")}
                      rules={[
                        {
                          required: true,
                          message: "Please add organization Domain Prefix!",
                        },
                        {
                          pattern: new RegExp(/^[a-z0-9][a-z0-9.-]*[a-z0-9]$/),
                          message: "Prefix cannot contain anything other than small case alphabets.",
                        },
                      ]}
                    >
                      <Input placeholder="Sub Domain" className="megilla-input" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row Row gutter={[16, 0]}>
              <Col span={24} md={12}>
                <Form.Item
                  name="tagLine"
                  label={t("Tag Line")}
                  rules={[
                    {
                      message: "Please Enter Tagline!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Tagline" className="megilla-input" />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="homepageUrl"
                  label={t("URL field")}
                  rules={[
                    {
                      pattern: new RegExp(
                        "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
                      ),
                      message: "Invalid URL. Supported URL look like (www.megilla.com).",
                    },
                  ]}
                >
                  <Input placeholder="Enter Homepage URL" className="megilla-input" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item
                  name="customDomain"
                  label={t("Custom domain")}
                  rules={[
                    {
                      pattern: new RegExp(
                        "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
                      ),
                      message: "Invalid URL. Supported URL look like (www.megilla.com).",
                    },
                  ]}
                >
                  <Input type="text" placeholder="Enter custom domain" className="megilla-input" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="internalDescription"
                  label={t("Description")}
                  rules={[
                    {
                      required: true,
                      message: "Please add description !",
                    },
                  ]}
                >
                  <TextArea placeholder="Description" showCount maxLength={500} className="megilla-textarea" />
                </Form.Item>
              </Col>
              <Form.List name="user" className="w-100">
                {(fields, { add, remove }) => (
                  <Row gutter={(16, 16)}>
                    <Col span={24}>
                      <Form.Item className="add-form-custom-field">
                        <Button
                          className="custom-fields-btn mt-4"
                          size={"large"}
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add User
                        </Button>
                      </Form.Item>
                    </Col>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Col span={24} key={key} className="add-user-fields-wrapper">
                        {key !== 0 ? (
                          <div className="fields-delete-icon delete-user-fileds">
                            <Tooltip title="Delete User">
                              <DeleteOutlined
                                onClick={() => {
                                  organizationData?.user[key] && deletedUsers.push(organizationData.user[key].id);
                                  console.log("deletedUsers;", deletedUsers);

                                  remove(name);
                                }}
                              />
                            </Tooltip>
                          </div>
                        ) : null}
                        <Row gutter={(16, 16)}>
                          <Col span={24} md={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "firstName"]}
                              fieldKey={[fieldKey, "firstName"]}
                              label={t("First Name")}
                              rules={[
                                {
                                  required: true,

                                  message: "Please Enter First Name!",
                                },
                              ]}
                            >
                              <Input placeholder="First Name" className="megilla-input" />
                            </Form.Item>
                          </Col>
                          <Col span={24} md={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "lastName"]}
                              fieldKey={[fieldKey, "lastName"]}
                              label={t("Last Name")}
                              rules={[
                                {
                                  required: true,

                                  message: "Please Enter Last Name!",
                                },
                              ]}
                            >
                              <Input placeholder="Last Name" className="megilla-input" />
                            </Form.Item>
                          </Col>
                          <Col span={24} md={12}>
                            <Form.Item
                              {...restField}
                              // name="email"
                              name={[name, "email"]}
                              fieldKey={[fieldKey, "email"]}
                              label={t("Email")}
                              rules={[
                                {
                                  required: true,
                                  type: "email",
                                  message: "Please input valid Email!",
                                },
                              ]}
                            >
                              <Input type="email" placeholder="Email" className="megilla-input" />
                            </Form.Item>
                          </Col>
                          <Col span={24} md={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "password"]}
                              fieldKey={[fieldKey, "password"]}
                              label={t("Password")}
                              rules={[
                                { required: isEdit ? false : true, message: "Please input your password!" },
                                {
                                  min: 4,
                                  max: 100,
                                  message: "Password must be between 4 and 100 characters!",
                                },
                              ]}
                            >
                              <Input.Password placeholder="Password" className="megilla-input" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                )}
              </Form.List>
              <Col span={24} md={12}>
                <Form.Item
                  name="isMegillaBrandingEnabled"
                  valuePropName="checked"
                  className="public-switch"
                  label="Enable Megilla Branding"
                >
                  <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Form.Item>
              </Col>
              <Col span={24} md={{ span: 12, offset: 12 }}>
                <Form.Item>
                  <Row justify="center">
                    <Button
                      block
                      type="primary"
                      className="form-submit-btn"
                      size="large"
                      htmlType="submit"
                      loading={submitLoading}
                    >
                      Continue
                    </Button>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}
