import { attr } from "redux-orm";
import NetworkCall from "../../../network/networkCall";
import Request from "../../../network/request";
import { upsertModels } from "../baseModel/baseActions";
import BaseModel from "../baseModel/baseModel";
import baseReducer from "../baseModel/baseReducer";

export default class RoomName extends BaseModel {
  static getRoomName() {
    return async (dispatch) => {
      try {
        const roomName = await NetworkCall.fetch(Request.getRoomName());
        dispatch(upsertModels(RoomName, roomName));
        return roomName;
      } catch (e) {
        console.error(e);
        return false;
      }
    };
  }
  // Reducer
  static reducer(action, RoomName, session) {
    baseReducer(action, RoomName, session);
  }
}

RoomName.modelName = "RoomName";
