import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListingSelect from "../../components/common/listingSelect";
import { getSelector } from "../../redux/models/baseModel/baseModel";
// import Question from "../../redux/models/questions/questions";
import Rooms from "../../redux/models/rooms/rooms";
// import QuestionTags from "../../redux/models/questionTags/questionTags";
import RoomTags from "../../redux/models/roomTags/roomTags";

const RoomTagsSelector = () => {
  const dispatch = useDispatch();

  const roomTags = useSelector((state) => getSelector(RoomTags)(state));

  useEffect(() => {
    dispatch(RoomTags.getAll());
  }, []);

  return (
    <>
      <ListingSelect
        className="lf-search-bar"
        maxLength="2"
        placeholder="Tags"
        data={roomTags}
        model={Rooms}
        fieldName="tags.id"
        mode="multiple"
        maxTagCount="responsive"
        selectValue="id"
        selectDisplay="name"
      />
    </>
  );
};
export default RoomTagsSelector;
