import { Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setListingState } from "../../redux/slice/listings";

const { Search } = Input;

const ListingSearch = ({ model, ...props }) => {
  const dispatch = useDispatch();
  const searchValue = useSelector((state) => state.listings[model.listingSliceName]?.searchValue);

  const onChange = ({ target }) => {
    dispatch(
      setListingState({
        listing: model.listingSliceName,
        value: {
          searchValue: target.value,
        },
      })
    );
    if (!target) {
      onSearch();
    }
  };

  const onSearch = (value) => {
    dispatch(
      model.getAll({
        clear: true,
      })
    );
  };

  return (
    <Search
      {...props}
      className="lf-search-bar"
      allowClear={true}
      placeholder="Search"
      // allowClear
      // enterButton="Search"
      onSearch={onSearch}
      onChange={onChange}
      value={searchValue}
    />
  );
};

export default ListingSearch;
