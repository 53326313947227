import React from "react";
import { getActualPath, getActualPathPublic } from "../../components/common/formItems/CustomImageUpload";
// import { getActualPathPublic } from "../common/formItems/CustomImageUpload";
import { useLocation } from "react-router-dom";
import QuestionResponse from "../../redux/models/questionResponse/questionResponse";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import { useSelector } from "react-redux";
import qs from "query-string";
import ReactPlayer from "react-player";
import { getHostTenantUrl } from "../../utilities/generalUtility";

const StreamVideo = () => {
  let { search } = useLocation();
  console.log("Search", qs.parse(search));
  let videoSrc = getHostTenantUrl() + "/responses/get-video/" + qs.parse(search).file
  // let videoSrc = getActualPath("/responses/get-video/" + qs.parse(search).file);
  let videoUrl = qs.parse(search).file
  let videoSubtitle = getActualPathPublic(
    "/responses/get-video-metadata/" + videoUrl?.split(".")[0] + ".vtt?isSubtitle=true"
  );
  return (
    // <video
    //   src={videoSrc}
    //   controls
    //   style={{
    //     width: "100%",
    //     height: "100%",
    //   }}
    //   autoPlay
    // />
    <ReactPlayer className="react-player"
      url={videoSrc}
      controls
      playing
      width="100%"
      height="100vh"
      config={{
        file: {
          attributes: {
            crossOrigin: "true",
          },
          tracks: [
            {
              kind: "subtitles",
              src: videoSubtitle,
              srcLang: "en",
              default: true,
            },
          ],
        },
      }}
    />
  );
};

export default StreamVideo;
