import {
  DashOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  LinkOutlined,
  StarOutlined,
  BellOutlined,
  VideoCameraAddOutlined,
} from "@ant-design/icons";
import { Badge, Button, Dropdown, Menu, Popconfirm, Tooltip, Tag } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import CreateQuestion from "../../components/questions/createQuestions";
import Question from "../../redux/models/questions/questions";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import { useWindowSize } from "../../utilities/customHook";
import { getBaseClientUrl } from "../../utilities/generalUtility";
import EllipsisWrapper from "../common/ellipsesWrapper";
import ShareModal from "../common/shareModal";
import { HTMLPartToTextPart } from "../../utilities/generalUtility";

const QuestionRowCard = ({ record }) => {
  const [width, setWidth] = useWindowSize();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const isPublic = useSelector((state) => getSelector(Question)(state)[0]?.isPublic);

  const navigateToResponses = () => {
    history.push("/rooms/y" + "/" + id + "/" + record.id);
  };

  const onDelete = async () => {
    setDeleteLoading(true);
    await dispatch(Question.deleteQuestion(record.id));
    setDeleteLoading(false);
  };
  const ViewCheck = () =>
    isPublic ? (
      <Button
        type="link"
        icon={<EyeOutlined />}
        href={getBaseClientUrl() + "/submit-response/" + record.id}
        target="_blank"
      >
        View
      </Button>
    ) : (
      <Popconfirm
        title={<div style={{ maxWidth: 200 }}>Accepting Submissions is not checked. Response will not be viewed</div>}
        onCancel={() => {}}
        okText="Continue"
        okButtonProps={{
          href: getBaseClientUrl() + "/submit-response/" + record.id,
          target: "_blank",
        }}
        cancelText="No"
      >
        <Button
          type="link"
          icon={<EyeOutlined />}
          href={getBaseClientUrl() + "/submit-response/" + record.id}
          target="_blank"
        >
          View
        </Button>
      </Popconfirm>
    );

  const actions = [
    <Button icon={<VideoCameraAddOutlined />} type="link" onClick={navigateToResponses}>
      Responses
    </Button>,
    <CreateQuestion
      buttonProps={{
        style: {
          marginRight: 10,
        },
        type: "link",
        icon: <EditOutlined />,
      }}
      buttonText="Edit"
      questionId={record.id}
      roomId={id}
    />,
    <ViewCheck />,
    <ShareModal
      buttonProps={{
        type: "link",
        icon: <LinkOutlined />,
      }}
      buttonText="Share"
      text={
        <>
          Generate link for <span className="text-primary">Question</span>
        </>
      }
      link={getBaseClientUrl() + "/submit-response/" + record.id}
      slideShowLink={getBaseClientUrl() + "/question-slide-page/" + record.id}
      isPublic={isPublic}
    />,
    <Popconfirm
      placement="topRight"
      title="Are you sure to delete the question"
      onConfirm={() => onDelete()}
      okText="Yes"
      cancelText="No"
    >
      <Button type="link" icon={<DeleteOutlined />} danger loading={deleteLoading}>
        Delete
      </Button>
    </Popconfirm>,
  ];

  const menu = (
    <Menu>
      {actions.map((el) => (
        <Menu.Item>{el}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <div className="vl-list-item-wrap question-row-card">
        <div className="vl-list-item position-relative">
          {+record?.newResponse > 0 && (
            // (<BellOutlined className="question-card-new-video-icon"/>
            <Tooltip title={`${+record?.newResponse} new video${+record?.newResponse > 1 ? "s" : ""}`}>
              <div className="question-card-new-video-icon"> {+record?.newResponse}</div>
            </Tooltip>
          )}
          <div className="vli-question-wrap">
            {/* <div className="vli-star-icon"><StarOutlined /></div> */}
            <div className="vli-question ">
              <h3>
                <EllipsisWrapper ellipsisProps={{ row: 1 }}>{record.statement}</EllipsisWrapper>
              </h3>
              <p className="mb-0">
                {/* <EllipsisWrapper>{record.description}</EllipsisWrapper> */}
                <EllipsisWrapper>{HTMLPartToTextPart(record.description)}</EllipsisWrapper>
              </p>
              <Tag color="blue">
                <b>{record.clickedCount || 0}</b> Views
              </Tag>
              <Tag color="orange">
                <b>{+record.count || 0}</b> Responses
              </Tag>
            </div>
          </div>
          {width > 800 ? (
            <div className="li-action-links">{actions}</div>
          ) : (
            <Dropdown overlay={menu} placement="topLeft" className="list-dropdown-actions">
              <DashOutlined />
            </Dropdown>
          )}
        </div>
      </div>
    </>
  );
};

export default QuestionRowCard;
