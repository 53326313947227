import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { attr } from "redux-orm";
import NetworkCall from "../../../network/networkCall";
import Request from "../../../network/request";
import K from "../../../utilities/constants";
import { redirectToLogin } from "../../../utilities/generalUtility";
import { setListingState } from "../../slice/listings";
import { upsertModel } from "../baseModel/baseActions";
import BaseModel from "../baseModel/baseModel";
import baseReducer from "../baseModel/baseReducer";

export default class User extends BaseModel {
  // API call using thunk.
  static loginCall(email, password, remember) {
    return async (dispatch) => {
      const user = await NetworkCall.fetch(Request.loginUser(email, password));

      let encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(user), K.Cookie.Key.EncryptionKey);
      Cookies.set(K.Cookie.Key.User, encryptedUser, {
        path: "/",
        domain: "." + K.Network.URL.Client.BaseHost,
        expires: remember ? 365 : "",
      });

      dispatch(upsertModel(User, user));
      return user;
    };
  }

  static loginAsCall(orgId) {
    return async (dispatch) => {
      const user = await NetworkCall.fetch(Request.loginAsOrgUser(orgId));

      let encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(user), K.Cookie.Key.EncryptionKey);
      Cookies.set(K.Cookie.Key.User, encryptedUser, {
        path: "/",
        domain: "." + K.Network.URL.Client.BaseHost,
        expires: 1,
      });
      dispatch(upsertModel(User, user));
      return user;
    };
  }

  static logoutCall(error = "") {
    Cookies.remove(K.Cookie.Key.User, {
      path: "/",
      domain: "." + K.Network.URL.Client.BaseHost,
    });
    redirectToLogin(error);
  }

  //Forgot password
  static async forgotPassword(email) {
    const user = await NetworkCall.fetch(Request.forgotPassword(email));
    return user;
  }

  //Reset password
  static resetPassword(email, token, remember) {
    return async (dispatch) => {
      const user = await NetworkCall.fetch(Request.resetPassword(email, token));

      // ? Cookies.set(K.Cookie.Key.Token, token, {
      //     path: "/",
      //     domain: "." + K.Network.URL.Client.BaseHost,
      //     expires: remember ? 365 : "",
      //   })

      // Cookies.set(K.Cookie.Key.Tenant, user.organization.domainPrefix, {
      //   path: "/",
      //   domain: "." + K.Network.URL.Client.BaseHost,
      //   expires: remember ? 365 : "",
      // });

      dispatch(upsertModel(User, user));
      return user;
    };
  }

  // Selectors

  // Helpers
  static getUserObjectFromCookies() {
    let cookieUser = Cookies.get(K.Cookie.Key.User);

    let bytes = cookieUser ? CryptoJS.AES.decrypt(cookieUser, K.Cookie.Key.EncryptionKey) : "{}";

    try {
      let utfBytes = bytes.toString(CryptoJS.enc.Utf8);

      return JSON.parse(utfBytes);
    } catch (error) {
      console.error("error", error);
      // return this.logoutCall("User unauthorized");
    }
  }

  static isTokenAvailable() {
    return this.getUserObjectFromCookies().apiToken ? true : false;
    // return true;
  }

  static getTenant() {
    return this.getUserObjectFromCookies().organization?.domainPrefix ?? null;
  }

  static getOrganizationLogoUrl() {
    return this.getUserObjectFromCookies().organization?.pictureUrl ?? null;
  }
  static getProfilePicture() {
    return this.getUserObjectFromCookies().profilePicture ?? null;
  }

  static getToken() {
    return this.getUserObjectFromCookies().apiToken ?? "";
  }

  static getName() {
    return this.getUserObjectFromCookies().name ?? "";
  }

  static getEmail() {
    return this.getUserObjectFromCookies().email ?? "";
  }
  static getType() {
    return this.getUserObjectFromCookies().type ?? ""; // getting user type(role)
  }
  static roles() {
    return [K.Roles.User];
  }

  // Reducer
  static reducer(action, User, session) {
    baseReducer(action, User, session);
  }
}

User.modelName = "User";

User.fields = {
  // Attributes
  id: attr(),
  firstName: attr(),
  lastName: attr(),
  name: attr(),
  email: attr(),
  cellPhone: attr(),
  officePhone: attr(),
  employeeNumber: attr(),
  fullTimeAvailabilityStartDate: attr(),
  fullTimeAvailabilityEndDate: attr(),
  targetUtilization: attr(),
  billRate: attr(),
  isCustomBillRate: attr(),
  photoPath: attr(),
  roleId: attr(),
  locationId: attr(),
  subscriptionId: attr(),
  dob: attr(),
  joiningDate: attr(),
  prefix: attr(),
  type: attr(),
};
