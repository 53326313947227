import { message } from "antd";
import User from "../redux/models/user/user";
import K from "./constants";
import history from "./history";

export const handleError = (error, dispatch = null) => {
  console.error(error);
  message.error(error.message);
  return null;
};

export const toCamelCaseToSentence = (string) => {
  return string.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2");
};

export const snakeCaseToSentence = (string) => {
  return string
    ?.split("_")
    ?.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    ?.join(" ");
};

export const hasOnlyDigits = (string) => {
  return /^-{0,1}\d+$/.test(string);
};

export const getColor = (value) => {
  //value from 0 to 1
  var hue = ((1 - value) * 120).toString(10);
  return ["hsl(", hue, ",65%,70%)"].join("");
};

export const isNumberRegex = () => {
  return new RegExp("^[0-9]*$");
};

export const isDecimalRegex = () => {
  return new RegExp("^\\d+\\.?\\d*$");
};

export const isRolePresent = (roles, userRoles) => {
  let hasRole = true;
  if (roles && roles.length > 0) {
    let roleFound = false;
    for (const routeRole of roles ?? []) {
      if (userRoles.includes(routeRole)) {
        roleFound = true;
        break;
      }
    }
    hasRole = roleFound;
  }
  return hasRole;
};

export const redirectToLogin = (error = "") => {
  if (typeof window !== "undefined") {
    let newUrl =
      window.location.protocol + "//" + K.Network.URL.Client.BaseHost + ":" + K.Network.URL.Client.BasePort + "/login";
    if (error !== "") {
      newUrl += `?err=${error}`;
    }
    window.location = newUrl;
  }
};

export const redirectIfInvalidTenant = () => {
  const cookieDomainPrefix = User.getTenant();
  const hostArray = window.location.hostname.split(".");
  const urlDomainPrefix = hostArray.length > 0 ? hostArray[0] : "";
  const path = window.location.pathname;
  const search = window.location.search;
  if (
    !cookieDomainPrefix &&
    (urlDomainPrefix === "www" || urlDomainPrefix === "localhost" || urlDomainPrefix === K.Network.URL.DomainName)
  )
    return false;
  if (cookieDomainPrefix?.toLowerCase() !== urlDomainPrefix?.toLowerCase()) {
    redirectToUrl(cookieDomainPrefix, path + search);
  }
};

export const redirectToUrl = (domainPrefix, path) => {
  window.location =
    window.location.protocol +
    "//" +
    (domainPrefix ? domainPrefix + "." : "") +
    K.Network.URL.Client.BaseHost +
    ":" +
    K.Network.URL.Client.BasePort +
    path;
};

export const setFieldErrorsFromServer = (error, form, values = undefined) => {
  if (error.error === undefined) return;
  const errors = error.error.data.errors;
  if (typeof errors === "string" || errors instanceof String) {
    return;
  }
  let fieldErrors = [];
  // debugger;
  for (let key in errors) {
    if (errors.hasOwnProperty(key)) {
      // let fieldError = errors[key].map((error) => {
      //     return error;
      // });
      fieldErrors.push({
        name: key,
        errors: errors[key],
        value: values && values[key] ? values[key] : undefined,
      });
    }
  }
  form.setFields(fieldErrors);
};

export const snakeToCamel = (str) => {
  return str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""));
};

export const camelCaseKeys = (obj) =>
  Object.keys(obj).reduce(
    (ccObj, field) => ({
      ...ccObj,
      [snakeToCamel(field)]: obj[field],
    }),
    {}
  );

export const deleteQueryParam = (key) => {
  const queryParams = new URLSearchParams(window.location.search);

  queryParams.delete(key);
  history.push({
    search: queryParams.toString(),
  });
};

export const currentDate = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "/" + mm + "/" + dd;
  return today;
};

/**
 *
 * @param {array} array array to be sorted
 * @param {string} property property name
 * @returns {array} sorted array
 */
export const sortOnProperty = (array, property) =>
  array.sort((a, b) => a[property].toLowerCase().localeCompare(b[property].toLowerCase()));

/**
 * Image not found url
 */
export const notFoundImageUrl = `${window.location.origin.toString()}/images/not_found.svg`;

/**
 *
 * @param {string} value
 * @returns {boolean}
 */
export const hasSpecialCharacters = (value) => !value.match(/^[-_ ()a-zA-Z0-9]+$/);

/**
 *
 * @param {string} value
 * @returns {string}
 */
export const removeMultipleSpaces = (value) => value.replace(/ +(?= )/g, "");

/**
 *
 * @param {array} arr array including elements to be swapped
 * @param {number} from start index
 * @param {number} to end index
 * @returns {array} array with swapped elements
 */
export const swapPosition = (arr, from, to) => {
  if (Object.prototype.toString.call(arr) !== "[object Array]") {
    throw new Error("Please provide a valid array");
  }
  // Delete the item from it's current position
  var item = arr.splice(from, 1);
  // Make sure there's an item to move
  if (!item.length) {
    throw new Error("There is no item in the array at index " + from);
  }
  // Move the item to its new position
  arr.splice(to, 0, item[0]);
  return arr;
};

/**
 *
 * @param {*} data
 * @returns {string} string
 */
export const encodeData = (data) =>
  Object.keys(data)
    .map(function (key) {
      return [key, data[key]].map(encodeURIComponent).join("=");
    })
    .join("&");

export const getSubdomain = () => {
  const hostArray = window.location.hostname.split(".");
  const urlDomainPrefix = hostArray.length > 0 ? hostArray[0] : undefined;
  if (urlDomainPrefix !== "www") {
    return urlDomainPrefix;
  } else {
    return undefined;
  }
};

export const getBaseClientUrl = () =>
  "https://" +
  getSubdomain() +
  "." +
  K.Network.URL.Client.BaseHost +
  (process.env.REACT_APP_CLIENT_BASE_PORT != "443" ? ":" + K.Network.URL.Client.BasePort : "");

export const getHostTenantUrl = () => "https://" + getSubdomain() + process.env.REACT_APP_TENANT_PARTIAL_URL;

export const getPublicFileFromServerUrl = (fileName) => {
  var path = process.env.REACT_APP_TENANT_PARTIAL_URL;
  var split = path.split("/");
  var newPath = split.slice(0, split.length - 1).join("/") + "/";
  return "https://" + getSubdomain() + newPath + fileName;
};

export const setSassFileColors = (
  headerColor,
  headerFontColor,
  buttonColor,
  buttonFontColor,
  primaryFontColor,
  fontFamily
) => {
  document.documentElement.style.setProperty("--lpHeaderBgColor", headerColor || "#FFFFFF");
  document.documentElement.style.setProperty("--lpHeaderTextColor", headerFontColor || "#000000");
  document.documentElement.style.setProperty("--lpButtonBgColor", buttonColor || "#2CABE1");
  document.documentElement.style.setProperty("--lpButtonTextColor", buttonFontColor || "#FFFFFF");
  document.documentElement.style.setProperty("--lpTextPrimary", primaryFontColor || "2CABE1");
  document.documentElement.style.setProperty("--lpFontFamily", fontFamily || "sans-serif");
};

export const programaticDownload = (res) => {
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement("a");
  link.href = url;
  link.download = "";
  document.body.appendChild(link);
  link.click();
};

export const programaticDownloadFile = (res) => {
  const url = window.URL.createObjectURL(res.data);
  const link = document.createElement("a");
  link.href = url;
  link.download = "";
  document.body.appendChild(link);
  link.click();
};

export const HTMLPartToTextPart = (HTMLPart) =>
  HTMLPart.replace(/\n/gi, "")
    .replace(/<style[^>]*>[\s\S]*?<\/style[^>]*>/gi, "")
    .replace(/<head[^>]*>[\s\S]*?<\/head[^>]*>/gi, "")
    .replace(/<script[^>]*>[\s\S]*?<\/script[^>]*>/gi, "")
    .replace(/<\/\s*(?:p|div)>/gi, "\n")
    .replace(/<br[^>]*\/?>/gi, "\n")
    .replace(/<[^>]*>/gi, "")
    .replace("&nbsp;", " ")
    .replace(/[^\S\r\n][^\S\r\n]+/gi, " ");

export const getFacebookLoginStatus = () => {
  return new Promise((resolve, reject) => {
    window.FB.getLoginStatus((response) => {
      if (response?.error) {
        reject(response.error);
      }
      resolve(response);
    });
  });
};

export const fbLogin = () => {
  return new Promise((resolve, reject) => {
    window.FB.login(
      (response) => {
        if (response?.error) {
          reject(response.error);
        }
        resolve(response);
      },
      {
        scope:
          "instagram_basic,pages_show_list,business_management,pages_read_engagement,public_profile,instagram_content_publish",
      }
    );
  });
};
