import { message } from "antd";
import { attr } from "redux-orm";
import NetworkCall from "../../../network/networkCall";
import Request from "../../../network/request";
import { resetListingState, setListingState } from "../../slice/listings";
import {
  deleteAllAndUpsertModels,
  deleteModel,
  updateModelIfExists,
  upsertModel,
  upsertModels,
} from "../baseModel/baseActions";
import BaseModel from "../baseModel/baseModel";
import baseReducer from "../baseModel/baseReducer";
import RoomTags from "../roomTags/roomTags";
export default class Rooms extends BaseModel {
  // API call using thunk.

  static getAll({ clear = false } = {}) {
    return async (dispatch, getState) => {
      if (clear) {
        dispatch(
          resetListingState({
            listing: this.listingSliceName,
            ignore: ["searchValue", "tags"],
          })
        );
      }
      let sliceState = getState().listings[this.listingSliceName];
      let newPageNumber = sliceState.pageNumber + 1;
      dispatch(
        setListingState({
          listing: this.listingSliceName,
          value: {
            loadingMore: true,
            pageNumber: newPageNumber,
          },
        })
      );
      try {
        let list = await NetworkCall.fetch(
          Request.roomsList({
            pageNumber: newPageNumber,
            ...(sliceState.searchValue && { name: sliceState.searchValue }),
            ...(sliceState.isPublic !== undefined && { isPublic: sliceState.isPublic }),
            recordsPerPage: sliceState.pageSize,
            ...(sliceState["tags.id"] &&
              sliceState["tags.id"].length && { "tags.id": JSON.stringify(sliceState["tags.id"]) }),
          })
        );
        list = list.map((el) => ({
          authorName: el.authorname,
          isPublic: el.ispublic,
          deletedAt: el.deletedat,
          organizationId: el.organizationid,
          pictureUrl: el.pictureurl,
          projectNotes: el.projectnotes,
          templateId: el.templateid,
          description: el.description,
          id: el.id,
          name: el.name,
          unreadResponseCount: el.unreadResponseCount,
          newResponse: el.newResponse,
        }));

        dispatch(clear ? deleteAllAndUpsertModels(Rooms, list) : upsertModels(Rooms, list));
        dispatch(
          setListingState({
            listing: this.listingSliceName,
            value: {
              initialLoading: false,
              loadingMore: false,
              hasMore: list.length == sliceState.pageSize,
            },
          })
        );
        return true;
      } catch (e) {
        dispatch(
          setListingState({
            listing: this.listingSliceName,
            value: {
              initialLoading: false,
              loadingMore: false,
              hasMore: false,
            },
          })
        );
        return false;
      }
    };
  }

  static getRoomWithId(roomId) {
    return async (dispatch) => {
      try {
        const room = await NetworkCall.fetch(Request.roomWithId(roomId));
        dispatch(upsertModels(RoomTags, room.tags));
        return room;
      } catch (e) {
        throw e;
      }
    };
  }
  static createRoom(roomData) {
    return async (dispatch) => {
      try {
        const room = await NetworkCall.fetch(Request.createRoom(roomData));
        dispatch(
          Rooms.getAll({
            clear: true,
          })
        );
        dispatch(RoomTags.getAll());
        return room;
      } catch (e) {
        throw e;
      }
    };
  }

  static editRoom(roomData) {
    return async (dispatch) => {
      try {
        const room = await NetworkCall.fetch(Request.editRoom(roomData));
        dispatch(updateModelIfExists(Rooms, room.id, room));
        dispatch(RoomTags.getAll());
        return room;
      } catch (e) {
        throw e;
      }
    };
  }

  static changePublishedState(id, state, update = true) {
    return async (dispatch) => {
      try {
        const room = await NetworkCall.fetch(Request.changePublishedRoomState(id, state));
        if (update) {
          dispatch(upsertModel(Rooms, room));
        }
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    };
  }
  static deleteRoom(id) {
    return async (dispatch) => {
      const room = await NetworkCall.fetch(Request.deleteRoom(id));

      dispatch(deleteModel(Rooms, id));

      return room;
    };
  }
  static uploadImageLogo(file) {
    return async (dispatch) => {
      const imageUploadedResponse = await NetworkCall.fetch(Request.uploadRoomLogo(file));
      return imageUploadedResponse;
    };
  }
  static getImageLogo(url) {
    return async (dispatch) => {
      const imageResponse = await NetworkCall.fetch(Request.getRoomLogo(url));
      return imageResponse;
    };
  }
  static getRoomTags() {
    return async (dispatch) => {
      const tags = await NetworkCall.fetch(Request.getRoomTags());
      return tags;
    };
  }

  static cloneRoom(id) {
    return async (dispatch) => {
      try {
        await NetworkCall.fetch(Request.cloneRoom(id));
        dispatch(
          this.getAll({
            clear: true,
          })
        );
      } catch (e) {
        message.error("Failed to copy room");
      }
    };
  }
  static uploadCustomOrganizationLogo(file) {
    return async (dispatch) => {
      const imageUploadedResponse = await NetworkCall.fetch(Request.uploadOrganizationLogo(file));

      return imageUploadedResponse;
    };
  }
  // Reducer
  static reducer(action, Rooms, session) {
    baseReducer(action, Rooms, session);
  }
}

Rooms.modelName = "Rooms";
Rooms.listingSliceName = "rooms";

Rooms.fields = {
  // Attributes
  id: attr(),
  name: attr(),
  picture_url: attr(),
  description: attr(),
  organization_id: attr(),
  project_notes: attr(),
  template_id: attr(),
  is_public: attr(),
  user_id: attr(),
};
