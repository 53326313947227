import { StarOutlined} from "@ant-design/icons";
import { Checkbox } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { setAttribute } from "../../redux/models/baseModel/baseActions";
import QuestionArchive from "../../redux/models/questionArchive/questionArchive";
import EllipsisWrapper from "../common/ellipsesWrapper";
import QuestionPreviewPopup from "./questionPreviewPopup";

const SelectableQuestionRowCard = ({ record, check }) => {
  const dispatch = useDispatch();

  const onSelect = (e) => {
    dispatch(setAttribute(QuestionArchive, record.id, "isSelected", e.target.checked));
  };

  return (
    <>
      <div className="vl-list-item-wrap">
        <div className="vl-list-item">
          <div className="vli-chackbox-wrap">
            <div className="vli-checkbox">
              {check && <Checkbox onChange={onSelect} defaultChecked={record?.isSelected} />}
            </div>
            <div className="vli-star-icon">
              <StarOutlined />
            </div>
          </div>

          <div className="vli-content">
            <h3 className="vli-question-id">Question {record.id}</h3>
            <div className="vli-question-description">
              <EllipsisWrapper>{record.statement}</EllipsisWrapper>{" "}
            </div>
          </div>
          <div className="vli-link">
            <QuestionPreviewPopup questionId={record.id} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectableQuestionRowCard;
