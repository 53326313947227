import { LockOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, message } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import User from "../../redux/models/user/user";
var md5 = require("md5");

export default function SetPassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let token = location.search.substring(7);

  const onFinish = async (values) => {
    if (values.password !== values.confirmPassword) {
      message.error("Password does not match!");
      return;
    }

    let encryptedPass = md5(values.password);

    try {
      let user = await dispatch(User.resetPassword(encryptedPass, token, values.remember));

      user.message === "success" ? history.replace("/login") : alert(user.message);
      //  const { from } = location.state || { from: { path: "/" } };

      // redirectToUrl(user.tenant.domainPrefix, from.path);
    } catch (error) {}
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <React.Fragment>
      <div className="guest-layout">
        <div className="gl-banner">
          <img src="images/logo-black.png" alt="" className="gl-logo" />
        </div>
        <div className="gl-form">
          <h2 className="mb-5">Set Password</h2>

          <Form
            requiredMark={false}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  min: 8,
                  message: "Password must be atleast 8 characters long!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon text-primary" />}
                placeholder="Password"
                size="large"
                className="megilla-input"
              />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your confirm password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon text-primary" />}
                placeholder="Confirm Password"
                size="large"
                className="megilla-input"
              />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item className="mb-0">
              <Button block size="large" type="primary" htmlType="submit">
                {" "}
                Done{" "}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}
