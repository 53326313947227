import { Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setListingState } from "../../redux/slice/listings";

const ListingInput = ({ model, fieldName, ...props }) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => state.listings[model.listingSliceName]?.[fieldName]);

  const onChange = ({ target }) => {
    dispatch(
      setListingState({
        listing: model.listingSliceName,
        value: {
          [fieldName]: target.value,
        },
      })
    );
  };

  return (
    <Input
      // placeholder="Search"
      // // allowClear
      // enterButton="Search"
      {...props}
      onChange={onChange}
      value={value}
    />
  );
};

export default ListingInput;
