import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "../common/noDataFound";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import QuestionResponse from "../../redux/models/questionResponse/questionResponse";
import { usePromiseTracker } from "react-promise-tracker";
import LoadingSpinner from "../common/loadingSpinner";
import SlidesView from "../common/SlidesView";

export default function QuestionSlideLayout({questionId}) {
  const dispatch = useDispatch();
  const { promiseInProgress } = usePromiseTracker();

  const questionsResponsesListingState = useSelector((state) =>
    Object.keys(state.listings[QuestionResponse.listingSliceName])
  );

  const questionResponses = useSelector((state) =>
    getSelector(QuestionResponse)(state)
      .filter((el) => el.questionId == questionId)
      .map((el) => el.id)
  );

  useEffect(() => {
    if (questionId && !questionsResponsesListingState.includes(questionId)) {
      dispatch(QuestionResponse.createSliceState(questionId));
      dispatch(QuestionResponse.loadMore(questionId, true));
    }
  }, [questionId]);

  if (promiseInProgress || !questionResponses.length ) {
    return <LoadingSpinner />
  }

  return (
    <div>
        {!questionResponses.length ? (
          <NoDataFound description={"No responses yet."} />
        ) : (
          <SlidesView questionId={questionId}/>
        )}
    </div>
  );
}
