import { Button, Radio, Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import NoDataImage from "../../assets/images/rooms-empty.png";
import ListingSearch from "../../components/common/listingSearch";
import ListingView from "../../components/common/listingsView";
import CustomPageHeader from "../../components/common/pageHeader";
import RoomCard from "../../components/rooms/roomCard";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import Rooms from "../../redux/models/rooms/rooms";
import { setListingState } from "../../redux/slice/listings";
import QuestionTagsSelector from "../../components/roomDashboard/questionTagsSelector";
import RoomTagsSelector from "../../components/roomDashboard/roomTagsSelector";
const RoomsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  /* Get organization data from selector */
  const roomsData = useSelector((state) => getSelector(Rooms)(state));
  /* Slice values for virtual list */

  const roomListing = useSelector((state) => state.listings.rooms);

  const fetchData = async () => {
    await dispatch(Rooms.getAll());
  };
  const handleNavigation = () => {
    history.push("/rooms/create");
  };

  const setPublishedState = ({ target }) => {
    dispatch(
      setListingState({
        listing: Rooms.listingSliceName,
        value: {
          isPublic: target.value,
        },
      })
    );
    dispatch(
      Rooms.getAll({
        clear: true,
      })
    );
  };
  useEffect(() => {
    // if (roomListing.initialLoading) {
    fetchData();
    // }
  }, []);

  return (
    <>
      <CustomPageHeader
        title="Projects"
        actions={[
          <Button onClick={handleNavigation} size={"large"} type="primary">
            Create New Project
          </Button>,
        ]}
      />
      <div className="content-area">
        <div className="rooms-card-heading">
          <h3>Projects</h3>
          <p>
            Megilla is the premier tool for your organization to collect, store, and distribute video content for your
            community.
          </p>
        </div>
        <div className="search-invoices-main">
          <div className="search-invoices">
            <Row gutter={8}>
              <Col span={24} md={11} lg={11}>
                <ListingSearch model={Rooms} />
              </Col>
              <Col span={24} md={9} lg={9} className="room-selector">
                <RoomTagsSelector />
              </Col>
              <Col span={24} md={4} lg={4}>
                <Button
                  className="apply-filter-btn"
                  type="primary"
                  onClick={() => {
                    dispatch(
                      Rooms.getAll({
                        clear: true,
                      })
                    );
                  }}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </div>
          <div className="search-invoices-btn qvf-fields">
            <Radio.Group onChange={setPublishedState} value={roomListing.isPublic}>
              <Radio.Button value={undefined}>All</Radio.Button>
              <Radio.Button value={true}>Published</Radio.Button>
              <Radio.Button value={false}>Unpublished</Radio.Button>
            </Radio.Group>
          </div>
        </div>
        {!roomsData?.length && !roomListing?.initialLoading ? (
          <div className="rooms-empty-card">
            <img src={NoDataImage} className="empty-rooms-img" />
            <p>You have no project, Please press Create button to create a new project.</p>
            <Button onClick={handleNavigation} size={"large"} type="primary" className="mt-3">
              Create New Project
            </Button>
          </div>
        ) : (
          <>
            <ListingView
              dataSource={roomsData}
              GridCard={RoomCard}
              isGrid={true}
              loadMore={fetchData}
              initialLoading={roomListing?.initialLoading}
              loadingMore={roomListing?.loadingMore}
              hasMore={roomListing?.hasMore}
            />
          </>
        )}
      </div>
    </>
  );
};

export default RoomsPage;
