import { attr } from "redux-orm";
import NetworkCall from "../../../network/networkCall";
import Request from "../../../network/request";
import { deleteAllAndUpsertModels } from "../baseModel/baseActions";
import BaseModel from "../baseModel/baseModel";
import baseReducer from "../baseModel/baseReducer";

export default class QuestionTags extends BaseModel {
  static getAll() {
    return async (dispatch) => {
      try {
        const tags = await NetworkCall.fetch(Request.getQuestionTags());
        dispatch(
          deleteAllAndUpsertModels(
            QuestionTags,
            tags.map((el) => ({
              id: el.id,
              name: el.name,
            }))
          )
        );
      } catch (e) {
        console.error(e);
      }
    };
  }
  // Reducer
  static reducer(action, QuestionTags, session) {
    baseReducer(action, QuestionTags, session);
  }
}

QuestionTags.modelName = "QuestionTags";

QuestionTags.fields = {
  // Attributes
  id: attr(),
  name: attr(),
};
