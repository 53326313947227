import { Button, Modal } from "antd";
import React from "react";

function SelectTemplatePopup({ isModalVisible, template, onCancel, onOk, ...props }) {
  return (
    <div>
      <Modal
        visible={isModalVisible}
        centered
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              onOk(template.id);
              onCancel();
            }}
          >
            Use
          </Button>,
          <Button key="cancel" onClick={() => onCancel()}>
            Cancel
          </Button>,
        ]}
        closable={false}
      >
        <img src={template.template} />
      </Modal>
    </div>
  );
}

export default SelectTemplatePopup;
