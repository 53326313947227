import { createSlice } from "@reduxjs/toolkit";

export const roomSlice = createSlice({
  name: "room",
  initialState: {
    page: 0,
    hasMore: true,
    loadingMore: false,
    initialLoading: true,
  },
  reducers: {
    pageNo: (state, action) => {
      state.page += action.payload;
    },
    hasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    loadingMore: (state, action) => {
      state.loadingMore = action.payload;
    },
    initialLoading: (state, action) => {
      state.initialLoading = action.payload;
    },
  },
});

export const { pageNo, hasMore, loadingMore, initialLoading } = roomSlice.actions;
export const getPageNO = (state) => state.room.value;

export default roomSlice.reducer;
