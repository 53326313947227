import { CommentOutlined, PlaySquareOutlined, SettingOutlined, WalletOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import React from "react";
import { useHistory } from "react-router";
export default function Dashboard() {
  const history = useHistory();
  return (
    <div className="content-area">
      <div className="dashboard-header">
        <h3>
          <span className="text-primary"> Engage Your Community </span> Through Video Storytelling
        </h3>
        <p>Megilla is the premier tool to collect, store, spotlight and distribute video content for your community.</p>
      </div>

      <Row gutter={[20, 20]}>
        <Col span={24} sm={12} xl={6}>
          <Card onClick={() => history.push("/rooms")} className="dashboard-card">
            <div className="dc-icon rooms-bg">
              <CommentOutlined />
            </div>
            <h6>Projects</h6>
          </Card>
        </Col>
        <Col span={24} sm={12} xl={6}>
          <Card onClick={() => history.push("/video-archive")} className="dashboard-card">
            <div className="dc-icon video-archive-bg">
              <PlaySquareOutlined />
            </div>
            <h6>Video Archive</h6>
          </Card>
        </Col>
        <Col span={24} sm={12} xl={6}>
          <Card onClick={() => history.push("/settings")} className="dashboard-card">
            <div className="dc-icon settings-bg">
              <SettingOutlined />
            </div>
            <h6>Settings</h6>
          </Card>
        </Col>
        <Col span={24} sm={12} xl={6}>
          <Card onClick={() => history.push("/account")} className="dashboard-card">
            <div className="dc-icon account-bg">
              <WalletOutlined />
            </div>
            <h6>Account</h6>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
