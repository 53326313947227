import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomImageUpload from "../../components/common/formItems/CustomImageUpload";
import LoadingSpinner from "../../components/common/loadingSpinner";
import CustomPageHeader from "../../components/common/pageHeader";
import User from "../../redux/models/user/user";
import UserProfile from "../../redux/models/userProfile/userProfile";
import K from "../../utilities/constants";

const { Option } = Select;

export default function Accounts() {
  const [dataLoading, setDataLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const dispatch = useDispatch();
  const [accountData, setAccountData] = useState(null);
  const fetchData = async () => {
    setDataLoading(true);
    const res = await UserProfile.getProfile();
    setAccountData(res);
    setDataLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const onFinish = async (values) => {
    setSaveLoading(true);
    try {
      const res = await UserProfile.updateProfile(values);
      console.log(res);
      if (res) {
        let cookiesData = User.getUserObjectFromCookies();
        cookiesData = { ...cookiesData, profilePicture: values.profilePicture };
        let encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(cookiesData), K.Cookie.Key.EncryptionKey);
        Cookies.set(K.Cookie.Key.User, encryptedUser, {
          path: "/",
          domain: "." + K.Network.URL.Client.BaseHost,
          expires: 365,
        });
        message.success("Profile Update Successfully");
      }
      setSaveLoading(false);
      window.location.reload();
    } catch (e) {
      console.error(e);
      setSaveLoading(false);
    }
  };
  const onFinishFailed = (errorInfo) => {};

  return dataLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <CustomPageHeader title="Account" />

      <div className="account-setting content-area">
        <h3>Accounts</h3>
        <Form
          layout="vertical"
          hideRequiredMark={true}
          name="createRoom"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={accountData}
        >
          <Row gutter={(16, 16)}>
            <Col span={24} order={2} md={{ span: 12, order: 1 }} lg={8}>
              <Form.Item
                name="email"
                label="Email address"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Email Address!",
                  },
                ]}
              >
                <Input placeholder="Enter email address" className="megilla-input" />
              </Form.Item>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter First Name!",
                  },
                ]}
              >
                <Input placeholder="Enter first name" className="megilla-input" />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Last Name!",
                  },
                ]}
              >
                <Input placeholder="Enter last name" className="megilla-input" />
              </Form.Item>
              <Form.Item
                name="bio"
                label="Bio"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Bio!",
                  },
                ]}
              >
                <TextArea placeholder="Enter Bio" showCount maxLength={500} className="megilla-textarea" />
              </Form.Item>

              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Address!",
                  },
                ]}
              >
                <TextArea placeholder="Enter Address" showCount maxLength={255} className="megilla-textarea" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" size={"large"} loading={saveLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Col>
            <Col span={24} order={1} md={{ span: 12, order: 2 }} lg={8} xl={6}>
              <div className="accounts-image-upload">
                <Form.Item
                  name="profilePicture"
                  label="Upload Profile Picture"
                  className="cr-image-banner"
                  tooltip={{ title: "Tooltip with customize icon", icon: <InfoCircleOutlined /> }}
                >
                  <CustomImageUpload
                    onUpload={(image) => dispatch(UserProfile.uploadImageLogo(image))}
                    path="/upload-images"
                    aspectRatio={K.imageAspectRatios.profileImage}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
