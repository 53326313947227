import { createSlice } from "@reduxjs/toolkit";

export const responseDetailPopup = createSlice({
  name: "responseDetailPopup",
  initialState: {
    visible: false,
    record: null,
  },
  reducers: {
    setResponseDetails: (state, action) => {
      state.record = action.payload;
      state.visible = true;
    },
    closeResponseDetails: (state, action) => {
      state.visible = false;
      // state.record = null;
    },
  },
});

export const { setResponseDetails, closeResponseDetails } = responseDetailPopup.actions;

export default responseDetailPopup.reducer;
