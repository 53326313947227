import CreateNewRoom from "../components/rooms/createNewRoom";
import GuestPageLayout from "../layout/guestPageLayout";
import LoggedInPageLayout from "../layout/loggedInPageLayout";
import PublicPageLayout from "../layout/publicPageLayout";
import Accounts from "../pages/accounts/accounts";
import Dashboard from "../pages/dashboard/dashboard";
import ForgotPassword from "../pages/forgotPassword/forgotPassword";
import LandingPage from "../pages/landingPage/landingPage";
import Login from "../pages/login/login";
import NotFound from "../pages/notFound/notFound";
import CreateNewOrganization from "../pages/organizations/createOrganization";
import OrganizationList from "../pages/organizations/organizationList";
import QuestionsArchive from "../pages/questions/questionArchive";
import RedirectToResponse from "../pages/response/responseRedirect";
import SubmitResponse from "../pages/response/submitResponse";
import RoomPage from "../pages/rooms/room";
import RoomDashboard from "../pages/rooms/roomDashboard";
import RoomsPage from "../pages/rooms/rooms";
import SetPassword from "../pages/setPassword/setPassword";
import Settings from "../pages/settings/settings";
import QuestionSlideShowPage from "../pages/slideshow/questionSlideShow";
import SlidePage from "../pages/slideshow/slidePage";
import StreamVideo from "../pages/streamVideo/StreamVideo";
import Unauthorized from "../pages/unauthorized/unauthorized";
import VideoArchive from "../pages/videoArchive/videoArchive";

// Template for a route
// {
//   path: '/login',
//   name: "Login",
//   component: Login,
//   authenticated: false,
//   roles: [],
//   children: [],
//   exact: true,
//   layout: LoggedInPageLayout
// },
const defaultCrudChildren = [
  { path: "/details/:id", name: "Details" },
  { path: "/store/:id", name: "Edit" },
];

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: GuestPageLayout,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    layout: GuestPageLayout,
  },
  {
    path: "/set-password",
    name: "SetPassword",
    component: SetPassword,
    layout: GuestPageLayout,
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
    authenticated: true,
    roles: [],
    layout: GuestPageLayout,
  },
  {
    path: "/",
    name: "Dashboard",
    exact: true,
    component: Dashboard,
    exact: true,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "/video",
    name: "Video",
    component: StreamVideo,
    authenticated: false,
    layout: GuestPageLayout,
  },
  {
    path: "/rooms",
    name: "Rooms",
    layout: LoggedInPageLayout,
    authenticated: true,
    children: [
      {
        path: "/",
        name: "Index",
        component: RoomsPage,
        exact: true,
        authenticated: true,
      },
      {
        path: "/create",
        name: "Create Rooms",
        component: CreateNewRoom,
        exact: true,
      },
      {
        path: "/edit/:id",
        name: "Create Rooms",
        component: CreateNewRoom,
        exact: true,
      },
      {
        path: "/dashboard/:id",
        name: "Room dashboard",
        component: RoomDashboard,
        exact: true,
      },
      {
        path: "/:hideQuestions/:roomId/:questionId?",
        name: "RoomPage",
        component: RoomPage,
        exact: true,
      },
    ],
  },

  {
    path: "/questions-archive",
    name: "Question Archive",
    component: QuestionsArchive,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "/submit-response/:loop/:id",
    name: "Submit Response",
    component: RedirectToResponse,
    authenticated: false,
    layout: PublicPageLayout,
  },
  {
    path: "/submit-response/:id",
    name: "Submit Response",
    component: SubmitResponse,
    authenticated: false,
    layout: PublicPageLayout,
  },
  {
    path: "/video-archive",
    name: "Video Archive",
    component: VideoArchive,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "/account",
    name: "Account",
    component: Accounts,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "/landing-page",
    name: "Landing Page",
    layout: PublicPageLayout,
    children: [
      {
        path: "/:roomId/:questionId?",
        name: "Landing Page",
        component: LandingPage,
        exact: true,
        authenticated: false,
      },
    ],
  },
  {
    path: "/slide-page",
    name: "Slide Page",
    layout: PublicPageLayout,
    children: [
      {
        path: "/:roomId/:questionId?",
        name: "Slide Page",
        component: SlidePage,
        exact: true,
        authenticated: false,
      },
    ],
  },
  {
    path: "/question-slide-page/:questionId",
    name: "Question Slide Page",
    layout: PublicPageLayout,
    component: QuestionSlideShowPage,
  },
  {
    path: "/organizations",
    name: "Organizations",
    // component: OraganizationsList,
    authenticated: true,
    layout: LoggedInPageLayout,
    children: [
      {
        path: "/",
        name: "Index",
        component: OrganizationList,
        exact: true,
      },
      {
        path: "/create",
        name: "Create Organization",
        component: CreateNewOrganization,
        exact: true,
      },
      {
        path: "/edit/:id",
        name: "Edit Organization",
        component: CreateNewOrganization,
        exact: true,
      },
    ],
  },
  // {
  //   path: "/organizations/:id",
  //   name: "Create Organization",
  //   component: CreateNewOrganization,
  //   layout: LoggedInPageLayout,
  //   exact: true,
  // },
  {
    path: "*",
    name: "Not Found",
    component: NotFound,
    layout: GuestPageLayout,
  },
];

export default routes;
