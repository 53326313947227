import _ from "lodash";
import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import User from "../redux/models/user/user";
import { redirectIfInvalidTenant } from "../utilities/generalUtility";
// import ReactGA from "react-ga"
import K from "../utilities/constants";

// ReactGA.initialize(K.Google.GA_TRACKING_ID)

const AuthCheck = ({ authenticated, path, roles, children }) => {
  const location = useLocation();
  if ((path === "/login" || path === "/forgot-password" || path === "/set-password") && User.isTokenAvailable()) {
    redirectIfInvalidTenant();

    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: location },
        }}
      />
    );
  }
  if (!authenticated) {
    return children;
  } else if (authenticated && User.isTokenAvailable()) {
    // Check domain prefix
    redirectIfInvalidTenant();

    return children;
  } else {
    redirectIfInvalidTenant();

    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }
};

const CustomRoute = ({ children, ...props }) => {
  let { path, exact, component } = props;


  const location = useLocation();

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search)
  // }, [location])

  if (children && children.length) {
    component = (
      <Route path={path}>
        <Switch>
          {children.map((route, i) => (
            <CustomRoute key={i} {..._.omit(props, ["layout"])} {...route} path={path + route.path} />
          ))}
        </Switch>
      </Route>
    );
  } else {
    let Component = component;
    component = (
      <AuthCheck {...props}>
        <Route path={path} exact={exact ?? false}>
          <Component />
        </Route>
      </AuthCheck>
    );
  }
  return props.layout ? <props.layout>{component}</props.layout> : component;
};

export default CustomRoute;
