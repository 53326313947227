import { createSlice } from "@reduxjs/toolkit";

export const videoPopup = createSlice({
  name: "videoPopup",
  initialState: {
    visible: false,
    record: null,
  },
  reducers: {
    setVideoDetails: (state, action) => {
      state.record = action.payload;
      state.visible = true;
    },
    closeVideoDetails: (state, action) => {
      state.record = null;
      state.visible = false;
    },
  },
});

export const { setVideoDetails, closeVideoDetails } = videoPopup.actions;

export default videoPopup.reducer;
