import { attr } from "redux-orm";
import NetworkCall from "../../../network/networkCall";
import Request from "../../../network/request";
import BaseModel from "../baseModel/baseModel";
import baseReducer from "../baseModel/baseReducer";

export default class UserProfile extends BaseModel {
  static async getProfile() {
    try {
      return await NetworkCall.fetch(Request.getUserProfile());
    } catch (e) {
      console.error(e);
    }
  }
  // Reducer
  static reducer(action, UserProfile, session) {
    baseReducer(action, UserProfile, session);
  }
  static uploadImageLogo(file) {
    return async (dispatch) => {
      const imageUploadedResponse = await NetworkCall.fetch(Request.uploadImage(file));
      return imageUploadedResponse;
    };
  }

  static async updateProfile(profileData) {
    return NetworkCall.fetch(Request.updateProfile(profileData));
  }
}

UserProfile.modelName = "UserProfile";

UserProfile.fields = {
  // Attributes
  id: attr(),
  name: attr(),
};
