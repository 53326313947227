import React, { useRef, useState } from "react";
import { Button } from "antd";
import ReactPlayer from "react-player";
import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";

function ReactVideoPlayer({record, videoSrc, videoSubtitle}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef(null);

  const Play = () =>{
    setIsPlaying(true); 
  }

  const Pause = () =>{
    setIsPlaying(false);
  }

  return (
    <>
    <div key={record.id} className="react-player-wrapper react-video-carousal">
      <ReactPlayer
        ref={playerRef}
        url={videoSrc}
        controls={true}
        playing={isPlaying}
        className="react-player"
        width="100%"
        height="100%"
        pip={true}
        onPlay={() => {Play()}}
        onPause={() => {Pause()}}
        config={{
          file: {
            attributes: {
              crossOrigin: "true",
              controlsList: 'nodownload',
            },
            tracks: [
              {
                kind: "subtitles",
                src: videoSubtitle,
                srcLang: "en",
                default: true,
              },
            ],
          },
        }}
      />
       <Button size="large" className="play-pause-button">
      {isPlaying ? (
        <PauseCircleOutlined onClick={()=>{Pause()}}/>
      ) : (
        <PlayCircleOutlined onClick={()=>{Play()}}/>
      )}
    </Button>
    </div>
   
    <div className="carousal-video-text">
      <h3> {record.question?.statement}</h3>
      <p> {record.respondents?.name}</p>
    </div>
  </>
  );
}

export default ReactVideoPlayer;
