import moment from "moment";

export const defaultFormat = "MM-DD-YYYY";
/**
 * expects utc date in form of string and converts and returns local date in moment object.
 * @param {string} date
 * @returns {Object}
 */
export const parsedDate = (date) => moment.utc(date, "DD-MM-YYYY").local();

export const parseSecond = (time, useColon = false) => {
  const findPlural = (time) => (time > 1 ? "s " : " ");
  var hrs = ~~(time / 3600);
  var mins = ~~((time % 3600) / 60);
  var secs = ~~time % 60;
  let ret = "";
  if (useColon) {
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }
  if (hrs) {
    ret = ret + hrs + " Hour" + findPlural(hrs);
  }
  if (mins) {
    ret = ret + mins + " Minute" + findPlural(mins);
  }
  if (secs) {
    ret = ret + secs + " Second" + findPlural(secs);
  }
  return ret;
};
