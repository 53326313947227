import { attr } from "redux-orm";
import NetworkCall from "../../../network/networkCall";
import Request from "../../../network/request";
import { resetListingState, setListingState } from "../../slice/listings";
import { deleteAllAndUpsertModels, upsertModels } from "../baseModel/baseActions";
import BaseModel from "../baseModel/baseModel";
import baseReducer from "../baseModel/baseReducer";

export default class QuestionArchive extends BaseModel {
  static getAll({ clear = false } = {}) {
    return async (dispatch, getState) => {
      if (clear) {
        dispatch(
          resetListingState({
            listing: this.listingSliceName,
            ignore: ["searchValue", "tags.id", "owner", "roomId", "authorName", "roomName"],
          })
        );
      }
      let sliceState = getState().listings[this.listingSliceName];
      let newPageNumber = sliceState.pageNumber + 1;
      dispatch(
        setListingState({
          listing: this.listingSliceName,
          value: {
            loadingMore: true,
            pageNumber: newPageNumber,
          },
        })
      );
      try {
        const orgList = await NetworkCall.fetch(
          Request.getAllQuestions({
            pageNumber: newPageNumber,
            recordsPerPage: sliceState.pageSize,
            ...(sliceState.searchValue && { statement: sliceState.searchValue }),
            ...(sliceState.selectedRoom && { roomId: sliceState.selectedRoom }),
            ...(sliceState.authorName && { authorName: sliceState.authorName }),
            ...(sliceState["tags.id"] &&
              sliceState["tags.id"].length && { "tags.id": JSON.stringify(sliceState["tags.id"]) }),
            ...(sliceState.roomId && { roomId: sliceState.roomId }),
            ...(sliceState.time && { time: sliceState.time }),
          })
        );
        dispatch(clear ? deleteAllAndUpsertModels(QuestionArchive, orgList) : upsertModels(QuestionArchive, orgList));
        dispatch(
          setListingState({
            listing: this.listingSliceName,
            value: {
              initialLoading: false,
              loadingMore: false,
              hasMore: orgList.length == sliceState.pageSize,
            },
          })
        );
        return true;
      } catch (e) {
        dispatch(
          setListingState({
            listing: this.listingSliceName,
            value: {
              initialLoading: false,
              loadingMore: false,
              hasMore: false,
            },
          })
        );
        return false;
      }
    };
  }

  // Reducer
  static reducer(action, QuestionArchive, session) {
    baseReducer(action, QuestionArchive, session);
  }
}

QuestionArchive.modelName = "QuestionArchive";
QuestionArchive.listingSliceName = "questionArchive";

QuestionArchive.fields = {
  // Attributes
  id: attr(),
  name: attr(),
  picture_url: attr(),
  description: attr(),
  organization_id: attr(),
  project_notes: attr(),
  template_id: attr(),
  is_public: attr(),
  user_id: attr(),

  //relation

  // room_ids: fk({
  //   to: "RoomList",
  //   as: "roomlist",
  //   relatedName: "questions",
  // }),
};
