import K from "../../utilities/constants";
import { getSubdomain } from "../../utilities/generalUtility";
import User from "../models/user/user";

export const fetchBaseQueryObject = (isTenant = true) => {
  const domainPrefix = User.getTenant() || getSubdomain();
  return {
    baseUrl: isTenant ? K.Network.URL.TenantURL(domainPrefix) : K.Network.URL.BaseAPI,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = User.getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  };
};
