import { Button, message, Modal, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Question from "../../redux/models/questions/questions";
import LoadingSpinner from "../common/loadingSpinner";
import parse from 'html-react-parser'

const QuestionPreviewPopup = ({ questionId }) => {
  const [visible, setVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questionData, setQuestionData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (questionId && visible) {
      fetchData();
    }
  }, [visible]);

  const fetchData = async () => {
    setLoading(true);
    try {
      var question = await dispatch(Question.getQuestionByID(questionId));
      setQuestionData(question);
    } catch (e) {
      message.error("Failed to load question.");
      setVisibility(false);
    }
    setLoading(false);
  };

  return (
    <>
      <Button type="link" onClick={() => setVisibility(true)}>
        Preview
      </Button>
      <Modal
        visible={visible}
        width={800}
        className="question-preview-popup"
        onCancel={() => setVisibility(false)}
        footer={
          <Button size={"large"} className="modal-close-btn" onClick={() => setVisibility(false)}>
            Close
          </Button>
        }
        title={"Question " + questionId}
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          questionData && (
            <>
              <div className="question-wrap">
                <h2 className="qp-heading">Question</h2>
                <h3 className="question">{questionData.statement}</h3>
              </div>
              <div className="description-wrap">
                <h2 className="qp-heading">Description</h2>
                <h4 className="description">{parse(questionData.description)}</h4>
              </div>
              <div className="tags-wrap">
                <h2 className="qp-heading">Tags</h2>
                <div className="qp-tags">
                  {questionData.tags?.map((el) => (
                    <Tag>{el.name}</Tag>
                  ))}
                </div>
              </div>
              <div className="custom-fields-wrap">
                <h2 className="qp-heading">Custom Question Fields</h2>
                {questionData.customFields?.map((el) => (
                  <div className="cf-text">
                    {el.statement} <br /> {el.isRequired && "(Required)"}
                  </div>
                ))}
              </div>
            </>
          )
        )}
      </Modal>
    </>
  );
};

export default QuestionPreviewPopup;
