import { createSelector, Model } from "redux-orm";
import orm from "../orm";

export default class BaseModel extends Model {
  static capitalModelName() {
    return this.modelName
      .split(/(?=[A-Z])/)
      .join("_")
      .toUpperCase();
  }

  // Selectors
  static getSelector(modelClass) {
    return createSelector(orm[modelClass.modelName]);
  }
}

export const getSelector = (modelClass) => {
  return createSelector(orm[modelClass.modelName]);
};

export const pullToTopSelector = (model, state) => {
  return getSelector(model)(state).sort((a, b) => (a.pullToTop || 0) - (b.pullToTop || 0));
};
