import { Col, Row, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import LoadingSpinner from "../../components/common/loadingSpinner";
import NoDataFound from "../../components/common/noDataFound";
import QuestionList from "../../components/room/questionList";
import ResponseGrid from "../../components/room/responsesGrid";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import Question from "../../redux/models/questions/questions";
import { setListingState } from "../../redux/slice/listings";
import { setSelectedQuestionId } from "../../redux/slice/questionId";

export default function TwoColumnLayout(props) {
  const { roomId, questionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const roomListing = useSelector((state) => state.listings[Question.listingSliceName]);
  const questions = useSelector((state) => getSelector(Question)(state));
  const [isEmpty, setIsEmpty] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const selectedQuestionId = useSelector((state)=>state.selectedQuestionId.qId)

  useEffect(()=>{
    if(questionId)
      dispatch(setSelectedQuestionId(questionId))
  },[questionId]);

  const handleClick = event => {
    setIsActive(current => !current);
  };

  const addBodyStyle = ()=> {
    document.querySelector('body').style.overflowY = 'hidden';
  }

  const removBodyStyle = () => {
    document.querySelector('body').style.overflowY = 'auto';
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    dispatch(
      setListingState({
        listing: "roomQuestions",
        value: {
          selectedRoom: roomId,
        },
      })
    );
    setIsLoading(true);
    try {
      let questionsList = await dispatch(
        Question.getAll({
          clear: true,
          isLandingPage: true,
        })
      );
      if (questionsList.length == 0) {
        setIsEmpty(true);
      } else {
        if (!questionId) {
          history.push("/landing-page/" + roomId + "/" + questionsList[0].id);
        }
      }
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  }
  const selectedQuestion = () => {
    const question = questions?.filter((question) => question.id == selectedQuestionId);
    return question[0]?.statement;
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <>
        <div className="rooms-details">
          {isEmpty ? (
            <NoDataFound description={"Project contains no questions."} />
          ) : (
            <Row gutter={32}>
              <Col span={24} lg={8} className={`r-question-list ${isActive ? 'open-question' : null}`}>
                <QuestionList roomId={roomId} roomListing={roomListing} questions={questions} isLandingPage />
                <div className="responsive-question-list" onClick={handleClick}> {selectedQuestionId && <span className="question">{selectedQuestion()}</span>} 
                <Button type="primary" onClick={isActive ? removBodyStyle : addBodyStyle} size="small" ghost> {isActive ? 'Close' : 'Select Question'}</Button> </div>
              </Col>
              <Col span={24} lg={16} className="question-videos-list">
                <div className="question-videos-header">
                  <div className="qvh-title mb-3 mt-0">
                    <h5> Question </h5>
                    {selectedQuestionId && <h3>{selectedQuestion()}</h3>}
                  </div>
                </div>
                <ResponseGrid questionId={selectedQuestionId} roomId={roomId} isLandingPage />
              </Col>
            </Row>
          )}
        </div>
      </>
    </>
  );
}
