import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListingSelect from "../../components/common/listingSelect";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import Question from "../../redux/models/questions/questions";
import QuestionTags from "../../redux/models/questionTags/questionTags";

const QuestionTagsSelector = () => {
  const dispatch = useDispatch();

  const questionTags = useSelector((state) => getSelector(QuestionTags)(state));

  useEffect(() => {
    dispatch(QuestionTags.getAll());
  }, []);

  return (
    <>
      <ListingSelect
        className="lf-search-bar"
        maxLength="2"
        placeholder="Tags"
        data={questionTags}
        model={Question}
        fieldName="tags.id"
        mode="multiple"
        maxTagCount="responsive"
        selectValue="id"
        selectDisplay="name"
      />
    </>
  );
};
export default QuestionTagsSelector;
