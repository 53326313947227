import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import LoadingSpinner from "../../components/common/loadingSpinner";
import QuestionSlideLayout from "../../components/slideshowPage/QuestionSlideLayout";
import Organization from "../../redux/models/organization/organization";
import { setSassFileColors } from "../../utilities/generalUtility";

export default function QuestionSlideShowPage() {
  const { questionId} = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoading(true);
    try {
      let colorsRes = await Organization.getOrganizationColor();
      const { headerColor, headerFontColor, buttonColor, buttonFontColor, primaryFontColor, fontFamily } = colorsRes;
      setSassFileColors(headerColor, headerFontColor, buttonColor, buttonFontColor, primaryFontColor, fontFamily);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <div className="view-landing-page custom-fontFamily">
      { loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="content-area mt-lg-0">
            <QuestionSlideLayout questionId={questionId}/>
          </div>
        </>
      ) }
    </div>
  );
}
