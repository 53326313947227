import {
  AppstoreOutlined,
  DashboardOutlined,
  MessageOutlined,
  PlaySquareOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";
import User from "../../redux/models/user/user";
import K from "../../utilities/constants";

// Template a navigation item
// {
//     name: 'User',
//     path: '/user/list',
//     icon: <ProjectOutlined />,
//     roles: [],
//     children: [], // If item has children, then the path field will be ignored.
// }

const defaultChildren = (basePath) => [
  { path: basePath, name: "List" },
  { path: `${basePath}/store/create`, name: "Create", roles: [K.Roles.Admin] },
];
const type = User.getType();
let navigations = [];
if (type === "SuperAdmin") {
  navigations = [
    {
      name: "Organizations",
      path: "/organizations",
      icon: <DashboardOutlined />,
    },
  ];
} else {
  navigations = [
    {
      name: "Dashboard",
      path: "/",
      icon: <AppstoreOutlined />,
    },
    {
      name: "Projects",
      path: "/rooms",
      icon: <MessageOutlined />,
    },
    {
      name: "Video Archive",
      path: "/video-archive",
      icon: <PlaySquareOutlined />,
    },
    {
      name: "Questions Archive",
      path: "/questions-archive",
      icon: <DashboardOutlined />,
    },
    {
      name: "Settings",
      path: "/settings",
      icon: <SettingOutlined />,
    },
    {
      name: "Account",
      path: "/account",
      icon: <UserOutlined />,
    },
  ];
}

export default navigations;
