import React from "react";

const RecordCountDown = () => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="box">
          <div className="circle circle1"></div>
          <div className="circle circle2"></div>
          <div className="niddle"></div>
          <div className="number">
            <div>3</div>
            <div>2</div>
            <div>1</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordCountDown;
