import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import ResponseDetailPopup from "../components/room/responseDetailsPopup";
import VideoPopup from "../components/videoArchive/videoPopup";
import CustomRoute from "../routes/customRoute";
import routes from "../routes/routes";
import "./App.less";

function App() {
  useEffect(() => {
    if (!document.getElementById("fb-root")) {
      // create div required for fb
      const fbDiv = document.createElement("div");
      fbDiv.id = "fb-root";
      document.body.appendChild(fbDiv);
      // Run any script after sdk is loaded
      window.fbAsyncInit = () => {
        //
      };
      // inject sdk.js
      (function (d, script) {
        script = d.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = `https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v15.0&appId=${process.env.REACT_APP_FACEBOOK_APP_ID}&autoLogAppEvents=1`;
        d.getElementsByTagName("head")[0].appendChild(script);
      })(document);
    }
    // loadFb();
  }, []);
  return (
    <div className="App">
      <Switch>
        {routes.map((route, i) => (
          <CustomRoute key={i} {...route} exact={route.exact ?? false} />
        ))}
      </Switch>
      <ResponseDetailPopup />
      <VideoPopup />
    </div>
  );
}

export default App;
