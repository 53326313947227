import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectableQuestionRowCard from "../../components/questions/selectableQuestionRowCard";
import ListingView from "./listingsView";
function QuestionArchiveList({ data, model, check }) {
  const dispatch = useDispatch();
  const questionList = useSelector((state) => state.listings[model.listingSliceName]);

  return (
    <>
      <div className="questions-list">
        <div className="add-existing-question">
          <ListingView
            dataSource={data}
            RowCard={({ record }) => <SelectableQuestionRowCard record={record} check={check} />}
            initialLoading={questionList.initialLoading}
            hasMore={questionList.hasMore}
            loadingMore={questionList.loadingMore}
            loadMore={() => dispatch(model.getAll())}
            fixedHeight={check && 400}
            noDataProps={{
              description: "No questions found",
            }}
          />
        </div>
      </div>
    </>
  );
}

export default QuestionArchiveList;
