import {
  CalendarOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleFilled,
  CloseCircleOutlined,
  DownloadOutlined,
  PlayCircleOutlined,
  ShareAltOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { Button, Card, Checkbox, Tag } from "antd";
import moment from "moment";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShareModal from "../../components/common/shareModal";
import { setAttribute } from "../../redux/models/baseModel/baseActions";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import QuestionResponse from "../../redux/models/questionResponse/questionResponse";
import { setResponseDetails } from "../../redux/slice/responseDetailPopup";
import { parseSecond } from "../../utilities/dateUtility";
import { getBaseClientUrl, getHostTenantUrl } from "../../utilities/generalUtility";
import { getActualPath, getActualPathPublic } from "../common/formItems/CustomImageUpload";
import _ from "lodash";

const ResponseCard = ({ id, isLandingPage = false }) => {
  const record = useSelector((state) => getSelector(QuestionResponse)(state).find((el) => el.id == id) || {});

  const moderationLabels = record?.response?.moderationLabels;

  const [videoThumbnail, setVideoThumbnail] = useState("");
  const [videoSubtitle, setVideoSubtitle] = useState("");
  const [videoDownloadLink, setVideoDownloadLink] = useState("");
  const [respondantName, setRespondantName] = useState("");
  const [createdAt, setCreatedAt] = useState(moment().format("MM-DD-YYYY"));
  const [isAccepted, setIsAccepted] = useState(false);
  const [videoLength, setVideoLength] = useState(0);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const dispatch = useDispatch();

  function getColorBasedOnSentiment(sentiment) {
    switch (sentiment) {
      case "POSITIVE":
        return "green";
      case "NEGATIVE":
        return "red";
      case "NEUTRAL":
        return "default";
      // Add more cases as needed
      default:
        return "orange"; // Default color or handle unknown sentiment
    }
  }

  // const labelColorMapping = {
  //   'Explicit Nudity': 'red',
  //   'Suggestive': 'orange',
  //   'Violence': 'volcano',
  //   'Visually Disturbing': 'red',
  //   'Rude Gestures': 'orange',
  //   'Drugs': 'volcano',
  //   'Tobacco': 'red',
  //   'Alcohol': 'orange',
  //   'Gambling': 'orange',
  //   'Hate Symbols': 'orange',
  // };

  const openDetails = () => {
    dispatch(
      setResponseDetails({
        ...record,
        isLandingPage,
        videoSubtitle,
      })
    );
  };

  const onCheck = (e) => {
    dispatch(setAttribute(QuestionResponse, id, "isSelected", e.target.checked));
  };

  let videoCreateTimeInDays = false;

  if (createdAt) {
    videoCreateTimeInDays = moment().diff(moment(record?.response?.createdAt), "days");
  }

  const onAccept = async () => {
    setAcceptLoading(true);
    await dispatch(QuestionResponse.acceptResponse(record.responseId));
    setAcceptLoading(false);
  };

  const onReject = async () => {
    setRejectLoading(true);
    await dispatch(QuestionResponse.rejectResponse(record.responseId));
    setRejectLoading(false);
  };

  const [ellipsis, setEllipsis] = React.useState(true);
  useEffect(() => {
    if (!_.isEmpty(record)) {
      setVideoThumbnail(getActualPathPublic("/responses/get-video-metadata/" + record?.response?.videoUrl));
      setVideoSubtitle(
        getActualPathPublic(
          "/responses/get-video-metadata/" + record?.response?.videoUrl.split(".")[0] + ".vtt?isSubtitle=true"
        )
      );
      setVideoDownloadLink(getActualPath("/responses/single-video?fileName=" + record?.response?.videoUrl));
      setRespondantName(record.respondents?.name);
      setCreatedAt(record?.response?.createdAt ? moment(record?.response?.createdAt).format("MM-DD-YYYY") : null);
      setVideoLength(record?.response?.videoLength ? parseSecond(+record?.response?.videoLength, true) : null);
      setIsAccepted(record.response?.isAccepted);
    }
  }, [record]);
  return (
    <>
      <Card
        className="video-card"
        cover={
          <>
            <div className="thumbnail-image">
              <img alt="" src={videoThumbnail?.replace(/(mp4)(?!.*mp4)/, "png")} />
            </div>

            {!isLandingPage && <Checkbox className="checkbox-icon" checked={record.isSelected} onChange={onCheck} />}
            <PlayCircleOutlined className="play-icon" onClick={openDetails} />
            <div className="video-time-length">{videoLength === null ? "0:00" : videoLength}</div>

            {!isLandingPage && record?.response?.isAccepted == null && videoCreateTimeInDays < 1 && (
              <div className="video-card-tag-icon"> New</div>
            )}
          </>
        }
        actions={
          isLandingPage
            ? undefined
            : [
                <Button
                  icon={
                    isAccepted === true ? (
                      <CheckCircleFilled className="check-circle-icon" />
                    ) : (
                      <CheckCircleOutlined className="check-circle-icon" />
                    )
                  }
                  type="link"
                  loading={acceptLoading}
                  onClick={onAccept}
                  size="large"
                />,
                <Button
                  icon={
                    isAccepted === false ? (
                      <CloseCircleFilled className="close-circle-icon" />
                    ) : (
                      <CloseCircleOutlined className="close-circle-icon" />
                    )
                  }
                  type="link"
                  loading={rejectLoading}
                  onClick={onReject}
                  size="large"
                />,
              ]
        }
      >
        {!isLandingPage && (
          <div
            style={{
              position: "absolute",
              top: -14,
              right: 8,
            }}
          >
            <ShareModal
              link={getHostTenantUrl() + "/responses/get-video/" + record?.response?.videoUrl}
              // link={qs.stringifyUrl({
              //   url: getBaseClientUrl() + "/video",
              //   query: {
              //     file: videoThumbnail,
              //   },
              // })}
              shareModalName="videoModal"
              record={record}
              isDisplaySlideShowLinks={false}
              buttonProps={{
                icon: (
                  <ShareAltOutlined
                    style={{
                      color: "#2cabe1",
                    }}
                  />
                ),
                shape: "circle",
                size: "small",
                style: {
                  marginRight: 5,
                },
              }}
              buttonText=""
              text={
                <>
                  Generate link for <span className="text-primary">Video</span>
                </>
              }
            />

            <Button
              icon={
                <DownloadOutlined
                  style={{
                    color: "#2cabe1",
                  }}
                />
              }
              // type="link"
              shape="circle"
              size="small"
              href={videoDownloadLink}
            />
          </div>
        )}
        <div className="card-heading-wrap">
          <h3 ellipsis={ellipsis ? { rows: 1, expandable: true, symbol: "more" } : false}>{respondantName}</h3>
          {record?.response?.sentimentAnalysis && (
            <Tag color={getColorBasedOnSentiment(record?.response?.sentimentAnalysis)}>
              {record?.response?.sentimentAnalysis}
            </Tag>
          )}
        </div>
        <h4 style={{ minHeight: "23px", marginTop: "10px" }}>
          {moderationLabels?.map((label, index) => (
            // <Tag key={index} color={labelColorMapping[label]}>
            <Tag key={index} color="red">
              {label}
            </Tag>
          ))}
        </h4>
        {!isLandingPage && (
          <h5>
            <CalendarOutlined className="mr-2 clander-icon" /> {createdAt}
          </h5>
        )}
      </Card>
    </>
  );
};

export default ResponseCard;
