import { attr } from "redux-orm";
import NetworkCall from "../../../network/networkCall";
import Request from "../../../network/request";
import { deleteAllAndUpsertModels } from "../baseModel/baseActions";
import BaseModel from "../baseModel/baseModel";
import baseReducer from "../baseModel/baseReducer";

export default class RoomTags extends BaseModel {
  static getAll() {
    return async (dispatch) => {
      try {
        const tags = await NetworkCall.fetch(Request.getRoomTags());
        dispatch(deleteAllAndUpsertModels(RoomTags, tags));
      } catch (e) {
        console.error(e);
      }
    };
  }
  // Reducer
  static reducer(action, RoomTags, session) {
    baseReducer(action, RoomTags, session);
  }
}

RoomTags.modelName = "RoomTags";

RoomTags.fields = {
  // Attributes
  id: attr(),
  name: attr(),
};
