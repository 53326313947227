import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { createReducer } from "redux-orm";
import Organization from "./models/organization/organization";
import orm from "./models/orm";
import QuestionArchive from "./models/questionArchive/questionArchive";
import QuestionResponse from "./models/questionResponse/questionResponse";
import Question from "./models/questions/questions";
import QuestionsAuthor from "./models/questionsAuthor/questionsAuthor";
import QuestionTags from "./models/questionTags/questionTags";
import QuestionTimeLength from "./models/questionTimeLength/questionTimeLength";
import RoomName from "./models/roomName/roomName";
import Rooms from "./models/rooms/rooms";
import RoomTags from "./models/roomTags/roomTags";
import User from "./models/user/user";
import VideoArchive from "./models/videoArchive/videoArchive";
import { roomApi } from "./services/roomApi";
import listingSlice from "./slice/listings";
import responseDetailPopupSlice from "./slice/responseDetailPopup";
import roomSlice from "./slice/room";
import videoHandlingSlice from "./slice/video";
import recordingCountDown from "./slice/recordingCountDown";
import videoPopupSlice from "./slice/videoPopup";
import questionSlice from './slice/questionId'

orm.register(
  User,
  Organization,
  Question,
  Rooms,
  RoomTags,
  QuestionTags,
  QuestionsAuthor,
  QuestionArchive,
  QuestionResponse,
  RoomName,
  QuestionTimeLength,
  VideoArchive
);

const store = configureStore({
  reducer: {
    orm: createReducer(orm),
    recordedVideos: videoHandlingSlice,
    room: roomSlice,
    listings: listingSlice,
    responseDetailPopup: responseDetailPopupSlice,
    videoPopup: videoPopupSlice,
    recordingCountDown: recordingCountDown,
    [roomApi.reducerPath]: roomApi.reducer,
    selectedQuestionId: questionSlice,
  },
  // Added serializableCheck to remove the warnings for storing moment object in redux
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(logger)
      .concat(roomApi.middleware),
});

export default store;
