import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, message, Typography, Upload } from "antd";
import React, { useState } from "react";
import K from "../../../utilities/constants";
import { getActualPath } from "./CustomImageUpload";

const { Link, Text } = Typography;

const CustomFileUpload = ({ onChange, value, path, onUpload, sizeLimit = 10, uploadClipText, ...carriedOnUploadProps }) => {
  const [loading, setLoading] = useState(false);
  console.log( value)

  const props = {
    name: "file",
    multiple: false,
    customRequest: async ({ onSuccess, onError, file }) => {
      try {
        if (file.size > sizeLimit * 1024 * 1024) {
          message.error("File size limit is " + sizeLimit + "mb");
          return;
        }
        setLoading(true);
        let { pictureUrl } = await onUpload(file);
        // onSuccess(pictureUrl);
        onChange(pictureUrl);
        setLoading(false);
      } catch (e) {
        console.error(e);
        message.error("Failed to upload file.");
        setLoading(false)
        onError("Invalid")
      }
    },
    accept: K.FormAcceptTypes.termsAndConditions,
  };

  const onDelete = () => {
    onChange(null);
  };

  return value ? (
    <>
      <Link href={`${getActualPath(path)}/${value}`} target="_blank">
        <PaperClipOutlined /> {uploadClipText}
      </Link>
      <Button icon={<DeleteOutlined />} type="link" danger onClick={onDelete} />
    </>
  ) : (
    <>
      <Upload {...props} {...carriedOnUploadProps}>
        <Button icon={<UploadOutlined />} size={"large"} className="image-upload-btn" loading={loading} type="link">
          Click to Upload
        </Button>
      </Upload>
      <Text type="secondary">File size limit is {sizeLimit} mb.</Text>
    </>
  );
};

export default CustomFileUpload;
