import Axios from "axios";
import User from "../redux/models/user/user";
import K from "../utilities/constants";
import { encodeData, getSubdomain } from "../utilities/generalUtility";

export default class Request {
  constructor(
    relativeURL,
    method = K.Network.Method.GET,
    body = null,
    defaultHeaderType = K.Network.Header.Type.Json,
    headers = {},
    isTenant = false,
    responseType = ""
  ) {
    const token = User.getToken();

    const domainPrefix = User.getTenant() || getSubdomain();
    isTenant = domainPrefix ? true : false;
    headers = {
      ...(defaultHeaderType === K.Network.Header.Type.Json
        ? K.Network.Header.Default(token)
        : K.Network.Header.Authorization(token)),
      ...headers,
    };
    this.url = isTenant ? K.Network.URL.TenantURL(domainPrefix) + relativeURL : K.Network.URL.BaseAPI + relativeURL;
    this.method = method;
    this.body = body;
    this.headers = headers;
    this.responseType = responseType;
  }
  // Tenant calls.
  static getTenant() {
    return new Request(K.Network.URL.GetTenant, K.Network.Method.GET);
  }

  // User calls.
  static loginUser(email, password) {
    const body = {
      email,
      password,
    };
    return new Request(K.Network.URL.LoginUser, K.Network.Method.POST, body, K.Network.Header.Type.Json, {}, false);
  }

  static loginAsOrgUser(id) {
    return new Request(
      `${K.Network.URL.LoginAsOrgUser}/${id}`,
      K.Network.Method.POST,
      K.Network.Header.Type.Json,
      {},
      true
    );
  }

  static forgotPassword(email) {
    const body = {
      email,
    };
    return new Request(
      K.Network.URL.ForgotPassword,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static resetPassword(password, token) {
    const body = {
      password,
      token,
    };
    return new Request(
      K.Network.URL.ResetPassword + "/" + token,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  // Questions Call

  static createQuestion(reqBody) {
    return new Request(K.Network.URL.CreateQuestion, K.Network.Method.POST, reqBody, K.Network.Header.Type.Json, {});
  }

  static editQuestion(reqBody, questionId) {
    return new Request(
      `${K.Network.URL.EditQuestion}/${questionId}`,
      K.Network.Method.PUT,
      reqBody,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static addExistingQuestions(reqBody) {
    return new Request(
      K.Network.URL.AddExistingQuestion,
      K.Network.Method.POST,
      reqBody,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static deleteQuestion(questionId) {
    return new Request(
      `${K.Network.URL.DeleteQuestion}/${questionId}`,
      K.Network.Method.DELETE,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static getAllQuestions(params, isLandingPage) {
    return new Request(
      `${isLandingPage ? K.Network.URL.publicQuestions : K.Network.URL.question}?${encodeData(params)}`,
      K.Network.Method.GET,
      K.Network.Header.Type.Json,
      {},
      true
    );
  }

  static getAllPublicQuestions(params) {
    return new Request(
      `${K.Network.URL.publicQuestions}?${encodeData(params)}`,
      K.Network.Method.GET,
      K.Network.Header.Type.Json,
      {},
      true
    );
  }

  static questionsAuthor() {
    return new Request(`${K.Network.URL.QuestionsAuthor}`, K.Network.Method.Get, K.Network.Header.Type.Json, {}, true);
  }
  static getQuestionTimeLength() {
    return new Request(
      `${K.Network.URL.GetQuestionTimeLength}?type=time`,
      K.Network.Method.Get,
      K.Network.Header.Type.Json,
      {},
      true
    );
  }

  static getRoomQuestions(pageNumber, pageSize, id) {
    return new Request(
      `${K.Network.URL.question}?pageNumber=${0}&recordsPerPage=${5}/${id}`,
      K.Network.Method.GET,
      K.Network.Header.Type.Json,
      {},
      true
    );
  }

  static getQuestionsResponse(params, isLandingPage) {
    return new Request(
      // `${K.Network.URL.QuestionResponses}?roomId=${roomId}&questionId=${questionId}`,
      params
        ? `${isLandingPage ? K.Network.URL.PubicQuestionResponses : K.Network.URL.QuestionResponses}?${encodeData(
            params
          )}`
        : K.Network.URL.QuestionResponses,
      K.Network.Method.GET,
      K.Network.Header.Type.Json,
      {},
      true
    );
  }

  static getQuestionById(id) {
    return new Request(
      `${K.Network.URL.QuestionById}/${id}`,
      K.Network.Method.GET,
      K.Network.Header.Type.Json,
      {},
      true
    );
  }

  /* Organization calls */

  /* organization list */
  static organizationsList(params) {
    return new Request(
      params ? `${K.Network.URL.organization}?${encodeData(params)}` : K.Network.URL.organization,
      K.Network.Method.GET,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  /* organization list */
  static organizationWithId(id) {
    return new Request(
      `${K.Network.URL.organization}/${id}`,
      K.Network.Method.GET,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static organizationSettings(settingValues) {
    return new Request(
      K.Network.URL.OrganizationSettings,
      K.Network.Method.PUT,
      settingValues,
      K.Network.Header.Type.Json,
      {},
      true
    );
  }
  static getOranizationColors() {
    return new Request(K.Network.URL.OrganizationTheme, K.Network.Method.GET, K.Network.Header.Type.Json, {}, false);
  }
  static getOrganizationThankyouPageSettings() {
    return new Request(
      K.Network.URL.OrganizationThankyouPageSettings,
      K.Network.Method.GET,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static getOrganizationSettings() {
    return new Request(K.Network.URL.OrganizationSettings, K.Network.Method.GET, K.Network.Header.Type.Json, {}, true);
  }
  /* create organization */
  static createOrganization(reqBody) {
    return new Request(
      K.Network.URL.organization,
      K.Network.Method.POST,
      reqBody,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  /* upload logo */
  static uploadOrganizationLogo(file) {
    const formData = new FormData();
    formData.append("file", file);
    const body = formData;
    return new Request(K.Network.URL.UploadOrganizationImage, K.Network.Method.POST, body, K.Network.Header.Type.File);
  }

  static resizeOrganizationLogo(oldImage, resizeImageName) {
    return new Request(
      `${K.Network.URL.ResizeOrganizationImage}/${oldImage}/${resizeImageName}`,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static deleteResizeOrganizationLogo(imageName) {
    const body = {
      imageName,
    };
    return new Request(
      `${K.Network.URL.DeleteResizeOrganizationLogo}`,
      K.Network.Method.DELETE,
      body,
      K.Network.Header.Type.Json,
      {},
      true
    );
  }

  /* Upload Photo*/
  static uploadImage(file) {
    const formData = new FormData();
    formData.append("file", file);
    const body = formData;
    return new Request(K.Network.URL.UploadRoomImage, K.Network.Method.POST, body, K.Network.Header.Type.File);
  }
  /* upload file */
  static uploadOrganizationTaC(file) {
    const formData = new FormData();
    formData.append("file", file);
    const body = formData;
    return new Request(K.Network.URL.UploadTaCFile, K.Network.Method.POST, body, K.Network.Header.Type.File);
  }
  /* edit organization */
  static editOrganization(reqBody) {
    return new Request(
      `${K.Network.URL.organization}`,
      K.Network.Method.PUT,
      reqBody,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  /* Delete organization */
  static deleteOrganization(id) {
    return new Request(
      `${K.Network.URL.organization}/${id}`,
      K.Network.Method.DELETE,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  /* Rooms calls */ static uploadRoomLogo(file) {
    const formData = new FormData();
    formData.append("file", file);
    const body = formData;
    return new Request(K.Network.URL.UploadRoomImage, K.Network.Method.POST, body, K.Network.Header.Type.File);
  }
  static getRoomLogo(url) {
    return new Request(`${K.Network.URL.GetRoomImage}/${url}`, K.Network.Method.GET, K.Network.Header.Type.File, true);
  }
  static deleteRoom(id) {
    return new Request(
      `${K.Network.URL.room}/${id}`,
      K.Network.Method.DELETE,
      {},
      K.Network.Header.Type.Json,
      {},
      true
    );
  }

  static createRoom(reqBody) {
    return new Request(K.Network.URL.CreateRoom, K.Network.Method.POST, reqBody, K.Network.Header.Type.Json, {});
  }

  static roomWithId(id) {
    return new Request(`${K.Network.URL.room}/${id}`, K.Network.Method.GET, K.Network.Header.Type.Json, {}, false);
  }
  static getRoomName(id) {
    return new Request(K.Network.URL.GetRoomName, K.Network.Method.GET, K.Network.Header.Type.Json, {}, true);
  }
  static editRoom(reqBody) {
    const { id } = reqBody;

    return new Request(
      `${K.Network.URL.room}/${id}`,
      K.Network.Method.PUT,
      reqBody,
      K.Network.Header.Type.Json,
      {},
      true
    );
  }

  static getRoomTags() {
    return new Request(K.Network.URL.GetRoomTags, K.Network.Method.GET, K.Network.Header.Type.Json, {});
  }
  static getQuestionTags() {
    return new Request(K.Network.URL.GetQuestionTags, K.Network.Method.GET, K.Network.Header.Type.Json, {});
  }
  static getUserProfile() {
    return new Request(K.Network.URL.getProfileData, K.Network.Method.GET, K.Network.Header.Type.Json, true);
  }

  static roomsList(params) {
    return new Request(
      `${K.Network.URL.room}?${encodeData(params)}`,
      K.Network.Method.GET,
      K.Network.Header.Type.Json,
      {},
      true
    );
  }

  static changePublishedRoomState(id, isPublic) {
    const body = {
      roomId: id,
      isPublic: isPublic,
    };
    return new Request(`${K.Network.URL.roomStatus}`, K.Network.Method.PUT, body, K.Network.Header.Type.Json, {}, true);
  }

  static getPublicQuestion(id) {
    return new Request(
      `${K.Network.URL.getPublicQuestion}` + id,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      true
    );
  }

  static downloadCSV(id) {
    return new Request(
      `${K.Network.URL.downloadCSV}` + id,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      true,
      "arraybuffer"
    );
  }

  static addResponse(id, body, e) {
    // return new Request(
    //   `${K.Network.URL.addResponse}` + id,
    //   K.Network.Method.POST,
    //   body,
    //   K.Network.Header.Type.Json,
    //   {
    //     [K.Network.Header.ContentType]: "multipart/form-data",
    //   },
    //   true
    // );
    return this.axiosInstance().post(
      K.Network.URL.TenantURL(getSubdomain()) + `${K.Network.URL.addResponse}` + id,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: e,
      }
    );
  }

  static videoArchiveList(params) {
    {
      return new Request(
        params ? `${K.Network.URL.GetResponse}?${encodeData(params)}` : K.Network.URL.organization,
        K.Network.Method.GET,
        K.Network.Header.Type.Json,
        {},
        false
      );
    }
  }
  /* Delete videos */
  static deleteVideos(videos) {
    return new Request(
      `${K.Network.URL.deleteVideos}`,
      K.Network.Method.DELETE,
      { fileNames: videos },
      K.Network.Header.Type.Json,
      {},
      true
    );
  }

  static updateProfile(profileData) {
    const body = profileData;
    return new Request(K.Network.URL.updateProfile, K.Network.Method.POST, body, K.Network.Header.Type.Json, {}, true);
  }

  static changeStatus(id, isAccepted = false) {
    const body = {
      isAccepted,
    };
    return new Request(
      `${K.Network.URL.ChangeStatus}` + id,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      true
    );
  }

  static cloneRoom(id) {
    return new Request(K.Network.URL.cloneRoom + id, K.Network.Method.GET, {}, K.Network.Header.Type.Json, {}, true);
  }

  static getCloudSearchResponse(queryParamters) {
    return this.axiosInstance().get(K.Network.URL.CloudSearchUrl, queryParamters, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  static axiosInstance() {
    const token = User.getToken();
    return Axios.create({
      headers: K.Network.Header.Authorization(token),
    });
  }
}
