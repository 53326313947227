import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../components/common/loadingSpinner";
import CustomPageHeader from "../../components/common/pageHeader";
import QuestionArchiveFilters from "../../components/common/questionArchiveFilters";
import QuestionArchiveList from "../../components/common/questionArchiveList";
import { getSelector } from "../../redux/models/baseModel/baseModel";
import QuestionArchive from "../../redux/models/questionArchive/questionArchive";

export default function QuestionsArchiveComponent() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const questionArchiveList = useSelector((state) => getSelector(QuestionArchive)(state));

  useEffect(() => {
    if (!questionArchiveList.length) {
      fetchData();
    }
  }, []);
  async function fetchData() {
    try {
      setIsLoading(true);
      await dispatch(QuestionArchive.getAll());

      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <CustomPageHeader title={"Question Archive"} />
      <div className="content-area">
        {/* <div className="form-container mt-3">
        <div className="container-card pb-5">
          <Form hideRequiredMark={true} layout="vertical" name="createRoom">
            <div className="heading-or-btn"></div>
          </Form>
        </div>
      </div> */}

        <div className="question-list-container p-0">
          <div className="border-0 questions-list">
            <h3 className="question-list-heading mb-3">Questions List </h3>
            <QuestionArchiveFilters model={QuestionArchive} />
            <QuestionArchiveList data={questionArchiveList} model={QuestionArchive} check={false} />
          </div>
        </div>
      </div>
    </>
  );
}
