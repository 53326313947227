import { List } from "antd";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import EllipsisWrapper from "../common/ellipsesWrapper";

function SlideQuestionStatement({ record, roomId }) {
  const history = useHistory();
  const { questionId } = useParams();
  const onClick = (questionId) => {
    history.push("/slide-page/" + roomId + "/" + questionId);
  };
  return (
    <div className="rooms-questions-sidebar">
      <List itemLayout="horizontal">
        <List.Item onClick={() => onClick(record.id)} className={questionId == record.id ? "active mb-3" : "mb-3"}>
          <div className="sidebar-question-list-ellipsis">
            <EllipsisWrapper ellipsisProps={{ rows: 2, expandable: false }}>{record.statement}</EllipsisWrapper>
          </div>
        </List.Item>
      </List>
    </div>
  );
}

export default SlideQuestionStatement;
